import MembersTable from "../../Components/Members/MembersTable";
import {Alert, Card, Col, Row} from "react-bootstrap";
import MembersSearch from "../../Components/Members/MembersSearch";
import './../../assets/css/Members/Members.css'
import React, {useContext, useEffect, useState} from "react";
import {retrieveMembersList} from "../../Api/Members/MembersFetch";
import {AiOutlineUserAdd} from "react-icons/ai";
import Loading from "../../Components/Layout/Elements/Loading";
import MemberContact from "../../Components/Modals/Members/MemberContact";
import MembersFilters from "../../Components/Members/MembersFilters";
import authContext from "../../Contexts/authContext";
import {Link} from "react-router-dom";


const MembersList = () => {
    const [membersList, setMembersList] = useState([])
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/profile')
    const {profile} = useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState();
    const [show, setShow] = useState(false)
    const [memberContact, setMemberContact] = useState([])
    const [filterType, setFilterType] = useState('filter_type=0');
    const [message, setMessage] = useState()
    const [updateMembersList, setUpdateMembersList] = useState(false)
    const [updateRetrieve, setUpdateRetrieve] = useState(false)
    const [filterSort, setFilterSort] = useState('')
    const [count, setCount] = useState([])

    useEffect(() => {

        retrieveMembersList({
            setMembersList,
            setCount,
            setPagination,
            pageUrl,
            perPage,
            setIsLoading,
            setErrors,
            search,
            filterType, filterSort
        })
    }, [pageUrl, perPage, search, filterType, updateMembersList, updateRetrieve, profile, filterSort])
    useEffect(() => {

    },[count])

    return (

        <>
            <Card className={'px-2 py-4 border-0 shadow mb-2'}>
                <MembersSearch setSearch={setSearch}/>
            </Card>
            <Card className={'px-2 py-4 border-0 shadow'}>
                <Row>
                    <Col md={8}>
                        <MembersFilters setFilterType={setFilterType} setPageUrl={setPageUrl} count={count}/>
                    </Col>
                    <Col md={4} className={'text-end'}>
                        <Link to={'/soci/aggiungi-socio'} className={'btn bkg-second text-white'}>
                            <AiOutlineUserAdd className={'fs-4'}/> Aggiungi socio
                        </Link></Col>
                </Row>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}

                <MembersTable membersList={membersList} pagination={pagination} setPageUrl={setPageUrl}
                              setPerPage={setPerPage} setShow={setShow} setMembersContact={setMemberContact}
                              profile={profile} perPage={perPage}
                              setMessage={setMessage} setErrors={setErrors}
                              setUpdateMembersList={setUpdateMembersList} setUpdateRetrieve={setUpdateRetrieve} errors={errors}  setFilterSort={setFilterSort}
                              filterSort={filterSort}/>
            </Card>
            {isLoading && <Loading/>}


            <MemberContact show={show} setShow={setShow} memberContact={memberContact}></MemberContact>

        </>
    )
}

export default MembersList