import CreateAssociationForm from "../../Components/Admin/CreateAssociationForm";
import {Alert, Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import Loading from "../../Components/Layout/Elements/Loading";
import {editAssociationFetch, showAssociation} from "../../Api/Admin/AdminFetch";
import {handleErrors} from "../../Components/Errors";
import {useNavigate, useParams} from "react-router-dom";

const EditAssociation = () => {
    const [editAssociation, setEditAssociation] = useState();
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState();
    const [generalError, setGeneralError] = useState()
    const [message, setMessage] = useState()
    const navigate = useNavigate()
    const [associationDetail, setAssociationDetail]= useState()
    const {id} = useParams()

    useEffect(()=>{
        showAssociation({setAssociationDetail, setErrors, setIsLoading, setGeneralError, navigate, id})
    }, [id])

    useEffect(() => {
     if(editAssociation) {
         console.log(editAssociation)
          editAssociationFetch({editAssociation, setMessage, setErrors, setIsLoading, setGeneralError, navigate, id})
     }
    }, [editAssociation])

    useEffect(() => {
        console.log(errors)
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    return (
        <Card className={'shadow p-5 border-0'}>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
            {generalError && typeof generalError === 'string' && <Alert variant={'danger'} onClick={() => {setGeneralError(null) }} dismissible> {generalError} </Alert>}
            <CreateAssociationForm setNewAssociation={setEditAssociation} associationDetail={associationDetail} />
            {isLoading && <Loading/>}
        </Card>
    )
}

export default EditAssociation