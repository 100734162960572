import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    addInstallment,
    createPlan,
    deletePlan,
    removeInstallment,
    retrievePaymentsList,
    showPaymentPlan
} from "../../Api/Payments/PaymentsFetch";
import ModalAddInstallment from "../../Components/Modals/Payments/ModalAddInstallment";
import Loading from "../../Components/Layout/Elements/Loading";
import {ConfirmationModal} from "../../Components/Modals/ConfirmationModal";
import ModalAddPlan from "../../Components/Modals/Payments/ModalAddPlan";
import PlanDetail from "../../Components/Payments/PlanDetail";
import PaymentsTable from "../../Components/Payments/PaymentsTable";
import ModalShowProfiles from "../../Components/Modals/Payments/ModalShowProfiles";
import SeasonsSelect from "../../Components/Payments/Elements/SeasonsSelect";

const PaymentList = () => {
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [paymentsList, setPaymentsList] = useState([])
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/payments/plan?')
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [show, setShow] = useState()
    const searchParams = new URLSearchParams(window.location.search);
    console.log(searchParams.get('payment_id')); // price_descending


    const [selectedPayment, setSelectedPayment] = useState()
    const [paymentDetail, setPaymentDetail] = useState([])

    const [showInstallmentModal, setShowInstallmentModal] = useState(false)  /*Trigger for opening modal to add installments */
    const [newInstallment, setNewInstallment] = useState()  /*state to add a new installment to a plan*/

    const [showConfirm, setShowConfirm] = useState(false)  /*Trigger Confirmation modal  */
    const [selectedPlan, setSelectedPlan] = useState()
    const [selectedPlanProfiles, setSelectedPlanProfiles] = useState()
    const [selectedInstallment, setSelectedInstallment] = useState()

    const [showPlanModal, setShowPlanModal] = useState(false)
    const [newPlan, setNewPlan] = useState()

    const [showProfiles, setShowProfiles] = useState(false)
    const [planMembers, setPlanMembers] = useState()

    const [update, setUpdate] = useState(false)

    const [selectedSeason, setSelectedSeason] = useState()

    useEffect(() => {
        retrievePaymentsList({setIsLoading, setPerPage, setPageUrl, pageUrl, setPaymentsList, selectedSeason})
    }, [update, selectedSeason])

    useEffect(() => {
        if (searchParams.has('payment_id')) {
            setSelectedPayment({['name']: searchParams.get('name'), ['id']: searchParams.get('payment_id')})

            setShow(true)
        }
    }, [])
    useEffect(() => {
        /*Get Plan detail*/
        if (selectedPayment) {
            showPaymentPlan({
                selectedPayment,
                setPaymentDetail,
                setIsLoading,
                setPerPage,
                setPageUrl,
                pageUrl,
                setPaymentsList
            })
        }
        console.log(selectedPayment)
    }, [selectedPayment, update])

    /*Add installment*/
    useEffect(() => {
        if (newInstallment) {
            addInstallment({
                newInstallment,
                setIsLoading,
                setPerPage,
                setPageUrl,
                pageUrl,
                selectedPayment,
                setShowInstallmentModal,
                setUpdate,
                update
            })
        }
    }, [newInstallment])

    /*Add plan*/
    useEffect(() => {
        if (newPlan) {
            createPlan({
                newPlan,
                setIsLoading,
                setNewPlan,
                setMessage,
                setPerPage,
                setPageUrl,
                pageUrl,
                setShowPlanModal,
                setUpdate,
                update
            })
        }
    }, [newPlan])
    const handleAddInstallment = () => {
        const formData = {};
        for (let i of document.querySelectorAll('#addInstallment input')) {
            const name = i.getAttribute('name');
            const value = i.value;
            formData[name] = value;
            console.log(i.value)
        }
        setNewInstallment(formData)

    }
    const handleAddPlan = () => {
        const formData = {};
        for (let i of document.querySelectorAll('#addPlan input, #addPlan textarea, #addPlan select')) {
            console.log(i)
            const name = i.getAttribute('name');
            const value = i.value;
            formData[name] = value;
            console.log(i.value)
        }
        console.log(formData)
        setNewPlan(formData)

    }


    const handleConfirmAction = () => {
        if (selectedInstallment && selectedPlan) {
            removeInstallment({
                selectedPlan, selectedInstallment, setIsLoading,
                setMessage,
                setShowConfirm,
                setSelectedPlan, setSelectedInstallment,
                setUpdate,

                update
            })
        }
        if (selectedPlan) {
            deletePlan({
                selectedPlan, selectedInstallment, setIsLoading,
                setMessage,
                setShowConfirm,
                setSelectedPlan, setSelectedInstallment,
                setUpdate,
                update
            })
        }
    }

    useEffect(() => {
        if (selectedPlanProfiles) {
            setIsLoading(true)
            showPaymentPlan({
                selectedPlan: selectedPlanProfiles,
                setIsLoading,
                setPerPage,
                setPageUrl,
                pageUrl,
                setPaymentDetail: setPlanMembers,
                setShowProfiles
            })
        }
    }, [selectedPlanProfiles])

    return (
        <Row className={'card shadow shadow sm p-2 py-3 border-0 mx-0'}>
            <Col md={12}>
                <Row>
                    <Col className={''}>
                        {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                            setMessage(null)
                        }} dismissible> {message} </Alert>}
                        {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                            setErrors(null)
                        }} dismissible> {errors} </Alert>}
                    </Col>
                </Row>
                <Row className={'text-end'}>
                    <Col>
                        <Button
                            className={'btn btn-sm bkg-primary text-white border-0 me-2'} onClick={() => {
                            setShowPlanModal(true)
                        }}>
                            Aggiungi abbonamento
                        </Button>
                    </Col>
                </Row>
                <Row className={'mx-0 text-start'}>

                    <Col className={'my-2'}> <SeasonsSelect setErrors={setErrors} setIsLoading={setIsLoading}
                                         setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} showAll={true}/></Col>
                    <PaymentsTable setShowProfile={setShowProfiles} setShowConfirm={setShowConfirm}
                                   setSelectedPlan={setSelectedPlan} setSelectedPlanProfiles={setSelectedPlanProfiles}
                                   setShow={setShow} paymentsList={paymentsList}
                                   setSelectedPayment={setSelectedPayment} setIsLoading={setIsLoading}/>
                </Row>


                <PlanDetail show={show} setMessage={setMessage} setShow={setShow} setErrors={setErrors}
                            message={message} errors={errors} setShowConfirm={setShowConfirm}
                            paymentDetail={paymentDetail} selectedPayment={selectedPayment}
                            setSelectedInstallment={setSelectedInstallment} setSelectedPlan={setSelectedPlan}
                            setShowInstallmentModal={setShowInstallmentModal} setIsLoading={setIsLoading}
                            update={update}
                            setUpdate={setUpdate}/>

                <ConfirmationModal show={showConfirm} handleConfirm={handleConfirmAction}
                                   actionMessage={'Sei sicuro di voler procedere con l\'eliminazione?'}
                                   handleClose={() => {
                                       setShowConfirm(false)
                                   }}/>
                <ModalAddInstallment show={showInstallmentModal} action={handleAddInstallment}
                                     setShow={setShowInstallmentModal}/>
                <ModalAddPlan show={showPlanModal} action={handleAddPlan}
                              setShow={setShowPlanModal}/>
                <ModalShowProfiles show={showProfiles} setShow={setShowProfiles} planMembers={planMembers?.profiles}
                                   setPlanMembers={setPlanMembers} setSelectedPlan={setSelectedPlan}
                                   setSelectedPlanProfiles={setSelectedPlanProfiles}/>
                {isLoading && <Loading/>}
            </Col>
        </Row>
    )
}
export default PaymentList