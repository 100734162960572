import {Alert, Card, Col, Row} from "react-bootstrap";
import Loading from "../../Components/Layout/Elements/Loading";
import CommunicationsTable from "../../Components/Communications/CommunicationsTable";
import React, {useContext, useEffect, useState} from "react";
import {retrieveCommunications} from "../../Api/Communications/CommunicationsFetch";
import CommunicationsFilters from "../../Components/Communications/CommunicationsFilters";
import authContext from "../../Contexts/authContext";

const CommunicationsList = () => {
    const [communicationsList, setCommunicationsList] = useState();
    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/communication?')
    const {profile} = useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [errors, setErrors] = useState()
    const [message, setMessage] = useState()
    const [updateComm, setUpdateComm] = useState(false)

    useEffect(() => {
        retrieveCommunications({
            setCommunicationsList,
            setIsLoading,
            pageUrl,
            setPageUrl,
            perPage,
            setPagination,
            search
        })
    }, [search, perPage, pageUrl, profile, updateComm])
    return (

        <Row>
            <Col md={12} className={''}>
                <Card className={'border-0 shadow mb-4 py-2'}><CommunicationsFilters setSearch={setSearch}/></Card>

            </Col>

            {message && <Alert variant={'success'} onClick={() => {
                setMessage(null)
            }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                setErrors(null)
            }} dismissible> {errors} </Alert>}

            <CommunicationsTable setIsLoading={setIsLoading} communicationsList={communicationsList}
                                 pageUrl={pageUrl} pagination={pagination} setPageUrl={setPageUrl}
                                 setPerPage={setPerPage} setMessage={setMessage} setErrors={setErrors}
                                 setUpdateComm={setUpdateComm} updateComm={updateComm}/>
            {isLoading && <Loading/>}
        </Row>


    )
}

export default CommunicationsList