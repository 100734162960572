export const emptyError = () => {
    /*Rimuovo lo span di errore se c'è */
    for (let i of document.querySelectorAll('span.error_empty_field')) {
        i.remove()
    }
    for (let i of document.querySelectorAll('span.errorBox')) {
        i.remove()
    }

    for (let i of document.querySelectorAll('input:not([type=radio])[required], select[required], textarea[required]')) {
        const newSpan = document.createElement("span");
        newSpan.classList.add('error_empty_field');
             if (i.value.length === 0) {
            // document.querySelectorAll('.form-label')
            i.before(newSpan)
            console.log('no')
            newSpan.innerHTML = 'Il campo non può essere vuoto';
        }
    }

    for (let i of document.querySelectorAll('input[type=radio][required]')) {
        let name = i.getAttribute('name');
        let checked = false;
        document.querySelectorAll('input[type=radio][name=' + name + '][required]').forEach((element) => {
            if(!checked) checked = element.checked;
        });

        const newSpan = document.createElement("span");
        newSpan.classList.add('error_empty_field');

        if (!checked) {
            // document.querySelectorAll('.form-label')
            i.before(newSpan)

            newSpan.innerHTML = 'Il campo non può essere vuoto';
        }
    }

    /*Errori per checkbox selezione*/
    if (document.querySelectorAll('input[name^=allToselect]').length > 0 && document.querySelectorAll('input[name^=allToselect]:checked').length === 0) {
        const errorBox = document.createElement("span");
        errorBox.classList.add('errorBox');
        errorBox.classList.add('alert');
        errorBox.classList.add('alert-danger');
        document.querySelector('table').before(errorBox)
        errorBox.innerHTML = 'Seleziona almeno un elemento dalla lista';
    }
}

export const handleErrors = (errors)=> {
    console.log(errors)
    const spanFields =  document.querySelectorAll('span.error_field')

    spanFields.forEach(i => i.remove())

    console.log(errors)
    Object.entries(errors).map(([key, value]) => {
        const inputs = document.querySelectorAll(`input[name^="${key}"], select[name^="${key}"], textarea[name^="${key}"], input[data-error^="${key}"], div[data-error^="${key}"]`);
        // Pretty straightforward - use key for the key and value for the value.
        // Just to clarify: unlike object destructuring, the parameter names don't matter here.
        // ^= is a match string with start string error
        // Costruisci il messaggio di errore
        let fullMessage = value;
        if (Array.isArray(value)) {
            fullMessage = value.join(' ');
        }


        // Limite di caratteri per il messaggio di errore visualizzato
        const maxChars = 38;
        const truncatedValue = fullMessage.length > maxChars ? fullMessage.substring(0, maxChars) + '...' : fullMessage;

        console.log(truncatedValue, value.length )
        inputs.forEach(input => {
            const newSpan = document.createElement("span");
            newSpan.classList.add('error_field');
            newSpan.setAttribute('title', value); // Tooltip con l'intero messaggio di errore
            newSpan.setAttribute('data-bs-toggle', 'tooltip');
            newSpan.setAttribute('data-bs-placement', 'right');

            newSpan.innerHTML = truncatedValue; // Messaggio troncato

            input.before(newSpan); // Inserire lo span prima dell'input
        });


    })
}