import {createBrowserRouter, createRoutesFromElements, Route} from "react-router-dom";
import App from "../App";
import MembersList from "../Pages/Members/MembersList";
import MemberDetail from "../Pages/Members/MemberDetail";
import TeamsList from "../Pages/Teams/TeamsList";
import TeamDetails from "../Pages/Teams/TeamDetails";
import Communications from "../Pages/Communications/Communications";
import CommunicationsList from "../Pages/Communications/CommunicationsList";
import NewCommunication from "../Pages/Communications/NewCommunication";
import Members from "../Pages/Members/Members";
import NewTeam from "../Pages/Teams/NewTeam";
import Teams from "../Pages/Teams/Teams";
import TeamEdit from "../Pages/Teams/TeamEdit";
import NewTeamGame from "../Pages/Teams/Games/NewTeamGame";
import EditTeamGame from "../Pages/Teams/Games/EditTeamGame";
import CommunicationDetail from "../Pages/Communications/CommunicationDetail";
import GameDetail from "../Pages/Teams/Games/GameDetail";
import Login from "../Pages/Auth/Login";
import AllTrainings from "../Pages/Trainings/TrainingsCalendar";
import Profiles from "../Pages/Profiles/Profiles";
import AddMember from "../Pages/Members/AddMember";
import NewTrainingPlan from "../Pages/Trainings/NewTrainingPlan";
import AssociationSettings from "../Pages/Settings/AssociationSettings";
import ResetPassword from "../Pages/Auth/ResetPassword";
import Admin from "../Pages/Admin/Admin";
import CreateAssociation from "../Pages/Admin/CreateAssociation";
import AssociationsList from "../Pages/Admin/AssociationsList";
import EditAssociation from "../Pages/Admin/EditAssociation";
import Coach from "../Pages/Coach";
import TrainingDetailPage from "../Pages/Trainings/TrainingDetailPage";
import Trainings from "../Pages/Trainings/Trainings";
import GamesList from "../Pages/Games/Games";
import AddTeamPlayers from "../Pages/Teams/Players/AddTeamPlayers";
import Payments from "../Pages/Payments/Payments";
import PaymentList from "../Pages/Payments/List";
import AddPayment from "../Pages/Payments/Add";
import Home from "../Pages/Home/Home";
import PaymentsReports from "../Pages/Payments/PaymentsReports";
import PaymentsOverview from "../Pages/Payments/PaymentsOverview";
import StaffList from "../Pages/Members/Staff/StaffList";
import AddStaff from "../Pages/Members/Staff/AddStaff";
import EditMember from "../Pages/Members/EditMember";


export const router = createBrowserRouter(
    createRoutesFromElements(
        <Route path={'/'} element={<App/>}>
            <Route path={'/home'} element={<Home />}> </Route>
            <Route path={'/soci'} element={<Members/>}>
                <Route path={'lista-soci'} element={<MembersList/>}> </Route>
                <Route path={'dettaglio-socio/:id'} element={<MemberDetail/>}></Route>
                <Route path={'aggiungi-socio'} element={<AddMember/>}></Route>
                <Route path={'modifica-socio/:id'} element={<EditMember/>}></Route>
            </Route>

            <Route path={'/teams'} element={<Teams/>}>
                <Route path={'lista-teams'} element={<TeamsList/>}> </Route>
                <Route path={'dettaglio-team/:id'} element={<TeamDetails/>}></Route>
                <Route path={'nuovo-team'} element={<NewTeam/>}></Route>
                <Route path={'modifica-team/:id'} element={<TeamEdit/>}></Route>
                <Route path={'team/:id/nuova-partita/'} element={<NewTeamGame/>}></Route>
                <Route path={'team/:id/modifica-partita/:gameId'} exact element={<EditTeamGame/>}></Route>
                <Route path={'team/:id/dettaglio-partita/:gameId'} exact element={<GameDetail/>}></Route>
                <Route path={'team/:id/nuovo-piano-allenamento'} exact element={<NewTrainingPlan/>}></Route>
                <Route path={'dettaglio-team/:id/aggiungi-giocatori'} exact element={<AddTeamPlayers/>}></Route>
                <Route path={'lista-staff'} element={<StaffList />}> </Route>
                <Route path={'aggiungi-staff'} element={<AddStaff/>}></Route>

            </Route>
            <Route path={'/allenamenti'} element={<Trainings/>}>
                <Route path={'calendario-allenamenti'} element={<AllTrainings/>}></Route>
                <Route path={'dettaglio-allenamento/:id'} element={<TrainingDetailPage/>}></Route>
            </Route>


            <Route path={'/admin'} element={<Admin/>}>
                <Route path={'lista-associazioni'} element={<AssociationsList />}> </Route>
                <Route path={'nuova-associazione'} element={<CreateAssociation />}> </Route>
                <Route path={'modifica-associazione/:id'} element={<EditAssociation />}> </Route>

            </Route>

            <Route path={'/login'} exact element={<Login/>}></Route>

            <Route path={'/comunicazioni'} element={<CommunicationsList/>}></Route>
            <Route path={'/comunicazioni'} element={<Communications/>}>
                <Route path={'lista-comunicazioni'} element={<CommunicationsList/>}></Route>
                <Route path={'nuova-comunicazione'} element={<NewCommunication/>}></Route>
                <Route path={'nuova-comunicazione/:id'} element={<NewCommunication/>}></Route>
                <Route path={'dettaglio-comunicazione/:id'} element={<CommunicationDetail/>}></Route>
            </Route>
            <Route path={'/gare'} element={<GamesList />}></Route>
            <Route path={'/nuova-comunicazione'} element={<Communications/>}></Route>
            <Route path={'/profili'} element={<Profiles/>}></Route>
            <Route path={'/impostazioni'} element={<AssociationSettings/>}>
            </Route>

            <Route path={'/pagamenti'} element={<PaymentList />}>  </Route>
            <Route path={'/pagamenti'} element={<Payments />}>
                <Route path={'lista-pagamenti'} element={<PaymentList />}>  </Route>
                <Route path={'aggiungi-pagamenti'} element={<AddPayment />}>  </Route>
                <Route path={'report'} element={<PaymentsReports />}>  </Route>
                <Route path={'overview'} element={<PaymentsOverview />}>  </Route>

            </Route>
            <Route path={'/coach'} element={<Coach/>} />
            <Route path={'/reset-password'} element={<ResetPassword/>} />
            </Route>
            )
            )