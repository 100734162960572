import {Badge, Card, Col, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import ImgPlaceholder from '../../../assets/img/placeholder.png'
import {Link} from "react-router-dom";
import {BsSendFill} from "react-icons/bs";
import {CiEdit} from "react-icons/ci";
import useScreenSize from "../../../Hooks/useScreenSize";
import MemberAddTeam from "../../Modals/Members/MemberAddTeam";
import {retrieveAvailableTeams} from "../../../Api/Members/MembersFetch";

const GeneralInfos = (props) => {
    const size = useScreenSize()
    const [show, setShow] = useState(false)
    const [availableTeams, setAvailableTeams] = useState()
    useEffect(() => {
        retrieveAvailableTeams({id: props.id, showModal: setShow, setAvailableTeams})
    }, [])

    useEffect(() => {
        console.log(availableTeams)
    }, [availableTeams])
    return (

        <>
            <Col md={12}>
                <Card className={'px-4  d-flex py-3 mb-3 shadow border-0 '}>
                    <Col className={'my-3 col-md-4 align-self-end text-white'}>
                        <Link to={'#'} onClick={()=> {setShow(true)}}
                              className={'btn w-100 text-white bkg-second border-0 btn-sm'}>
                       Aggiungi a team</Link></Col>
                    <Row className={'justify-items-center align-items-center'}>

                        <Col sm={12} md={4}  className={'ms-2 mb-2'} style={
                            {
                                width: size.width > 1200 ? '100px' : '50px',
                                height: size.width > 1200 ? '100px' : '50px',

                                borderRadius: '100%',
                                backgroundImage: `url(${props.memberDetail.image || ImgPlaceholder})`,
                                backgroundSize: 'cover',
                                backgroundPosition: 'center',
                                backgroundRepeat: 'no-repeat'
                            }}>
                            {/*<img src={props.memberDetail.image || ImgPlaceholder} className={'img-fluid w-100 rounded-5'}/>*/}

                        </Col>
                        <Col md={size.width > 1200 ? 8 : 12} className={'text-start'}>
                            <h3 className={'text-start text-second fw-bold'}> {props.memberDetail.first_name} {props.memberDetail.last_name}</h3>
                            <span className={'fw-bold text-second'}>Socio:</span> SI <br/>
                            <span
                                className={'fw-bold text-second'}>Indirizzo:</span> {props.memberDetail.address_line1} - {props.memberDetail.address_line2}<br/>
                            <span
                                className={'fw-bold text-second'}>Telefono:</span> {props.memberDetail.telephone_number}
                            <br/>
                            <span className={'fw-bold text-second'}>Email:</span> <Link
                            to={'mailto:' + props.memberDetail.email}
                            className={'fw-bold'}> {props.memberDetail.email} </Link>


                        </Col>
                        <Col md={12} className={'text-start my-3'}>
                            {props.memberDetail && props.memberDetail.roles && props.memberDetail.roles.length > 0 && props.memberDetail.roles.map(i => {

                                return <Badge key={i.id} bg={'info'}
                                              className={'col d-inline text-end text-white me-1 mt-'}>{i.name}</Badge>

                            })}
                        </Col>
                        <Col >  <Link  className={'btn w-100 text-white bkg-primary border-0 btn-sm'} to={'/soci/modifica-socio/' + props.id}><CiEdit
                            size={20} className={'text-decoration-none'}/>Modifica socio</Link></Col>
                        <Col className={'my-3 text-white'}>
                            <Link to={'/comunicazioni/nuova-comunicazione/' + props.id}
                                  className={'btn w-100 text-white bkg-second border-0 btn-sm'}>
                                <BsSendFill/> Invia
                                comunicazione</Link></Col>


                        {/*  <Col md={12} className={'text-start'}>


                        </Col>*/}
                    </Row>
                </Card>
            </Col>

            <MemberAddTeam show={show} setShow={setShow} availableTeams={availableTeams} id={props.id} update={props.update} setupdate={props.setUpdate} />

        </>
    )
}

export default GeneralInfos