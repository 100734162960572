import {Link} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import {Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getAssociationContacts} from "../../Api/Settings/SettingsFetch";

const ContactsTable = (props) => {


    const [associationContacts, setAssociationContacts] = useState()

    useEffect(() => {
        getAssociationContacts({
            setAssociationContacts,
            setMessage: props.setMessage,
            setErrors: props.setErrors,
            setIsLoading: props.setIsLoading
        })
    }, [props.update])

    return (<Table>
            <thead>
            <tr>
                <th>
                    Tipo
                </th>
                <th>
                    Url
                </th>

                <th></th>
            </tr>
            </thead>
            <tbody>
            {associationContacts && associationContacts.length > 0 ? associationContacts.map(i => {
                return <tr key={i.id}
                           className={`border-bottom border-black pb-4 mb-4 mx-0 align-items-center justify-content-between ${'row-' + i.id}`}>
                    <td className={'text-start'} md={4}>
                        <span className={'badge bkg-second text-white'}>{i.contact_type_name}</span>


                    </td>

                    <td className={'text-start'} md={4}>
                        <Link to={i.url} title={i.contact_type_name} target={'_blank'}>  {i.url} </Link>

                    </td>

                    <td md={4} className={'d-flex justify-content-end'}>
                        {/* <Button type={"submit"}
                                        className={'mt-3 btn btn-sm me-2 d-inline-block bkg-second no-border border-0'}
                                        onClick={handleEdit} data-id={i.id}>Modifica</Button>*/}
                        <RiDeleteBinLine size={20} className={'text-danger'}
                                         onClick={(e) => {
                                             e.preventDefault();
                                             props.setShowConfirm(true)
                                             props.setContactId(i.id)
                                         }}/>


                    </td>

                </tr>
            }) : <h3>
                Nessun contatto per l'associazione
            </h3>
            }
            </tbody>
        </Table>
    )
}
export default ContactsTable