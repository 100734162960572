import {Modal, Table, Button} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";

const ModalCommunicationMembers = (props) => {
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

    // Calcola i membri attuali da visualizzare
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentMembers = props.communicationMembers?.recipients ? props.communicationMembers?.recipients.slice(indexOfFirstItem, indexOfLastItem) : [];
    console.log(currentMembers)
    // Funzioni di paginazione
    const totalPages = Math.ceil((props.communicationMembers?.recipients?.length || 0) / itemsPerPage);
    const handleNextPage = () => setCurrentPage(prev => Math.min(prev + 1, totalPages));
    const handlePrevPage = () => setCurrentPage(prev => Math.max(prev - 1, 1));

    const handleClose = () => {
        props.setShowMembers(false);
        /*       props.setCommunicationMember();*/

    };

    useEffect(() => {
        console.log(props.communicationMembers?.recipients);
        setCurrentPage(1); // Reset della pagina corrente quando si aprono nuovi dati
    }, [props.communicationMembers]);

    return (
        <Modal show={props.show} onHide={handleClose}
               className={`modal-lg`}
               centered={true}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body className={'d-flex flex-column'}>
                <Table className={'table-responsive'}>
                    <thead>
                    <tr className={'shadow-none'}>
                        <th className={'bkg-primary text-white text-start'}>Nome</th>
                        <th className={'bkg-primary text-white text-start'}></th>
                    </tr>
                    </thead>
                    <tbody>
                    {currentMembers && currentMembers.length > 0 ? currentMembers.map((member, index) => {
                        return (
                            <tr key={index} className={'me-2'}>
                                <td>

                                    <Link className={'text-decoration-none fw-bold'}
                                          to={`${member.profile_id ? '/soci/dettaglio-socio/' + member.profile_id : member.team_id ? '/teams/dettaglio-team/' + member.team_id : ''}`}>
                                        <span>{member.name} </span>
                                    </Link>
                                </td>
                                <td className={'text-end'}><span className={'badge bkg-primary'}>{member.type}</span>
                                </td>


                            </tr>
                        );
                    }) : (
                        <tr>
                            <td colSpan="3">Nessun associato</td>
                        </tr>
                    )}
                    </tbody>
                </Table>
                {/* Controlli di paginazione */}
                {props.communicationMembers && props.communicationMembers.length > itemsPerPage && (
                    <div className="d-flex justify-content-between align-items-center mt-3">
                        <Button className={'bkg-second border-0'} onClick={handlePrevPage} disabled={currentPage === 1}>
                            Pagina precedente
                        </Button>
                        <span>Pagina {currentPage} di {totalPages}</span>
                        <Button className={'bkg-second border-0'} onClick={handleNextPage}
                                disabled={currentPage === totalPages}>
                            Pagina successiva
                        </Button>
                    </div>
                )}
            </Modal.Body>
        </Modal>
    );
};

export default ModalCommunicationMembers;
