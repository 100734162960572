import {Button, Form, Modal} from "react-bootstrap";
import {MdEmail} from "react-icons/md";
import {Link} from "react-router-dom";
import {BsFillTelephoneFill} from "react-icons/bs";
import {FaAddressCard} from "react-icons/fa";
import {useRef} from "react";
import {addMemberToTeam} from "../../../Api/Members/MembersFetch";

const MemberAddTeam = (props) => {
    const formRef = useRef()
    console.log(props.availableTeams)
    const handleClose = () => {
        props.setShow(false)
    }
    const handleAddTeam = () => {
        const teamId = formRef.current.querySelector('select').value;
       addMemberToTeam({teamId, id: props.id, setShow:props.setShow, update: props.update, setUpdate: props.setupdate})
    }
    return <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <Form ref={formRef}>

                <select className={'form-select form-select-sm'}>
                    <option value={''}>Seleziona team</option>
                    {props.availableTeams && props.availableTeams.length > 0 && props.availableTeams.map(i => {
                        return <option key={i.id} value={i.id}>{i.name}</option>
                    })}
                </select>
                <Button className={'btn bkg-second my-2 btn-sm text-end border-0'} onClick={handleAddTeam}>Aggiungi
                    socio a team</Button>
            </Form>
        </Modal.Body>
    </Modal>
}
export default MemberAddTeam