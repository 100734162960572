import {Table} from "react-bootstrap";

import React, {useState} from "react";

import {Link} from "react-router-dom";


import TablePagination from "../../Layout/Elements/TablePagination";
import {AiOutlineSortAscending, AiOutlineSortDescending} from "react-icons/ai";


const ReportsOverviewTable = (props) => {
    const [sort, setSort] = useState('desc');
    const handleSort = (e) => {

        /*creo una variabile per evitare problemi nell'aggiornamento dello stato */
        const singleSort = sort
        const key = e.currentTarget.getAttribute('data-name');

        if (sort === 'asc') {
            setSort('desc')
        } else {
            setSort('asc')
        }
        console.log(sort)
        props.setFilterSort({[key]: singleSort})

    }

    return (
        <>

            <Table className={'table-responsive '}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[last_name]'}>
                        Nome {
                        props.filterSort && props.filterSort['sorts[last_name]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[unpaid]'}>
                        Da pagare {
                        props.filterSort && props.filterSort['sorts[unpaid]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'  onClick={handleSort} data-name={'sorts[unpaid]'}>
                        Scaduto {
                        props.filterSort && props.filterSort['sorts[expired]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[paid]'}>
                        Pagato {
                        props.filterSort && props.filterSort['sorts[paid]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[total]'} >
                        Totale {
                        props.filterSort && props.filterSort['sorts[total]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>


                </tr>
                </thead>
                <tbody className={'text-start'}>
                {props.payments && props.payments.length > 0 ? props.payments.map((i, index) => {
                    // let date = new Date(i.birthday).toISOString()
                    return (
                        <tr className={'border-bottom-1'} key={index}>
                            <td>
                               <Link to={`${'/soci/dettaglio-socio/'+i.profile_id}`} className={'text-decoration-none fw-bold'}>
                                   <img className={'img-fluid rounded-5 me-3'} style={{width: '30px'}}
                                        src={`${i?.profile_picture}`}/>
                                   {i.full_name}
                               </Link>
                            </td>
                            <td><span className={'bg-warning rounded-5 px-2 py-1 text-white'}>{i?.unpaid}</span></td>
                            <td><span
                                className={`${i.expired !== 0 && 'bg-danger'} rounded-5 px-2 py-1 text-white`}>{i?.expired}</span>
                            </td>
                            <td><span
                                className={`${i.paid !== 0 && 'bg-success text-white'} rounded-5 px-2 py-1 `}>{i?.paid}</span>
                            </td>
                            <td>{i?.total}</td>
                            {/*    <td><Badge bg={`${i?.paid === 1 ? 'success' : 'danger'}`}>{i?.paid === 1 ? 'Si' : 'No'}</Badge></td>*/}

                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessun socio trovato</h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl}
                             perPage={props.perPage}></TablePagination>


        </>
    )

}

export default ReportsOverviewTable