import {Alert, Col, Row} from "react-bootstrap";
import Loading from "../../Components/Layout/Elements/Loading";
import React, {useContext, useState} from "react";
import authContext from "../../Contexts/authContext";
import GamesListGrid from "../../Components/Games/GamesListGrid";

const GamesList = () => {

    const [isLoading, setIsLoading] = useState(false);
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/communication?')
    const {profile} = useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [errors, setErrors] = useState()
    const [message, setMessage] = useState()
    const [updateComm, setUpdateComm] = useState(false)
    return (
        <>
            <Row className={'mx-0 mt-4'}>


                <Col md={12} className={'mx-0'}>
                    {message && <Alert variant={'success'} onClick={() => {
                        setMessage(null)
                    }} dismissible> {message} </Alert>}
                    {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                        setErrors(null)
                    }} dismissible> {errors} </Alert>}

                        <GamesListGrid errors={errors} setErrors={setErrors} message={message} setMessage={setMessage} setIsLoading={setIsLoading}/>


                </Col>

            </Row>
            {isLoading && <Loading/>}

        </>
    )
}
export default GamesList