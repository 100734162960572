import {Badge, Table} from "react-bootstrap";

import React, {useState} from "react";

import {Link} from "react-router-dom";


import TablePagination from "../../Layout/Elements/TablePagination";
import {formatDataIt} from "../../../Utils/DateFormat";
import {AiOutlineSortAscending, AiOutlineSortDescending} from "react-icons/ai";


const ReportsTable = (props) => {
    const [sort, setSort] = useState('desc');
    const handleSort = (e) => {

        /*creo una variabile per evitare problemi nell'aggiornamento dello stato */
        const singleSort = sort
        const key = e.currentTarget.getAttribute('data-name');

        if (sort === 'asc') {
            setSort('desc')
        } else {
            setSort('asc')
        }
        console.log(sort)
        props.setFilterSort({[key]: singleSort})

    }

    return (
        <>

            <Table className={'table-responsive '}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[last_name]'}>
                        Nome {
                        props.filterSort && props.filterSort['sorts[last_name]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[causal]'}>
                        Causale {
                        props.filterSort && props.filterSort['sorts[causal]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[payment_date]'}>
                        Data pagamento {
                        props.filterSort && props.filterSort['sorts[payment_date]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[collection_date]'}>
                        Data Incasso {
                        props.filterSort && props.filterSort['sorts[collection_date]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[paid]'}>
                        Pagato {
                        props.filterSort && props.filterSort['sorts[paid]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>

                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[notes]'}>
                        Note {
                        props.filterSort && props.filterSort['sorts[notes]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort} data-name={'sorts[type]'}>
                        Tipo {
                        props.filterSort && props.filterSort['sorts[type]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'  onClick={handleSort} data-name={'sorts[plan_name]'}>
                        Piano abbonamento {
                        props.filterSort && props.filterSort['sorts[plan_name]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>

                </tr>
                </thead>
                <tbody className={'text-start'} >
                {props.payments && props.payments.length > 0 ? props.payments.map((i, index) => {

                    return (
                        <tr className={'border-bottom-1'} key={index}>
                            <td>
                                <Link to={`${'/soci/dettaglio-socio/'+i.profile_id}`} className={'text-decoration-none fw-bold'}>    {i.first_name} {i.last_name} </Link>
                            </td>
                            <td>{i?.causal}</td>
                            <td>{formatDataIt(new Date(i.payment_date))}</td>
                            <td>{i.collection_date ? formatDataIt(new Date(i.collection_date)) : 'N/A'}</td>
                            <td><Badge bg={`${i?.paid === 1 ? 'success' : 'danger'}`}>{i?.paid === 1 ? 'Si' : 'No'}</Badge></td>
                            <td>{i?.notes}
                            < /td>
                            <td>{i?.type === 'installment' ? 'Pagamento' : 'Abbonamento'  }</td>
                            <td>{i?.plan_name}</td>
                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessun socio trovato</h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl} records={props.records}
                             perPage={props.perPage}></TablePagination>


        </>
    )

}

export default ReportsTable