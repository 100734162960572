import React from "react";
import '../../assets/css/Header.css'
import {Row} from "react-bootstrap";
import logo from '../../assets/img/logo.png';
import {CiMenuBurger} from "react-icons/ci";

/*
import authContext from "../../Contexts/authContext";

import {loginAutocomplete} from "../../Api/Geo";
import {logoutFetch} from "../../Api/Auth/LogoutFetch";
import {getAuthTokenTicket} from "../../Api/Tickets/TicketFetch";
*/


const Header = ({show, setShow}) => {
    const handleOpenSidebar = () => {
        if (show === 'closed') {
            setShow('open')
        } else {
            setShow('closed')
        }
    }

    return (
        <>
            <header className={'position-absolute d-flex  d-md-none left-0 right-0 d-flex flex-row py-2 px-2 flex-wrap mx-0 w-100 d-block justify-content-between'}>
                <Row className={'logoContainer'} style={{width: '100px'}}> <img src={logo}
                                                                                className={'img-fluid'}/></Row>

                <div className="col-md-1 col-sm-1 me-auto ">


                </div>
                <div className="col-2 ml-auto  justify-content-end d-flex text-end px-3 ">
                    <CiMenuBurger size={30} className={'text-white'} onClick={handleOpenSidebar}/>

                </div>

            </header>

        </>

    )
}


export default Header