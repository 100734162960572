import {CiEdit} from "react-icons/ci";
import {Link} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import {Badge, Table} from "react-bootstrap";
import React from "react";

const PaymentsTable = ({
                           paymentsList,
                           setShow,
                           setSelectedPayment,
                           setSelectedPlan,
                           setShowConfirm,
                           setSelectedPlanProfiles
                       }) => {
    return (<Table className={'table-responsive'}>
        <thead>
        <tr>
            <th className={'bkg-primary text-white text-start'}>Nome</th>
            <th className={'bkg-primary text-white'}>Descrizione</th>
            <th className={'bkg-primary text-white'}>Rate</th>
            <th className={'bkg-primary text-white'}>Profili</th>
            <th className={'bkg-primary text-white'}>Stagione</th>
            <th className={'bkg-primary text-white'}>Totale</th>
            <th className={'bkg-primary text-white'}>Azioni</th>

        </tr>
        </thead>
        <tbody>
        {paymentsList && paymentsList.length > 0 ? paymentsList.map(payment => {
            return (
                <tr key={payment.id}>
                    <td className={'text-start'}>{payment.plan_name}</td>
                    <td>{payment.plan_description}</td>
                    <td>{payment.total_installments}</td>
                    <td><p className={'mb-0'} onClick={(e) => {
                        console.log(payment.total_profiles)
                        if (payment.total_profiles > 0) {

                            setSelectedPlanProfiles(payment.id);
                        }
                    }}><Badge bg={'info'}
                              className={`col d-inline text-end me-1 ${payment.total_profiles > 0 ? 'cursor-pointer' : 'cursor-none'} mb-0`}>{payment.total_profiles} </Badge>
                    </p></td>
                    <td>{payment?.season_name || ''}</td>
                    <td>{payment.total_amount}</td>
                    <td><CiEdit className={'fs-5 mx-2'} title={'Modifica piano'} onClick={() => {
                        setShow(true)
                        setSelectedPayment({['name']: payment.plan_name, ['id']: payment.id})
                    }}
                                id={payment.id}/>
                        <Link to={''} data-id={payment.id} onClick={(e) => {
                            setShowConfirm(true)
                            setSelectedPlan(payment.id)
                        }}><RiDeleteBinLine className={'fs-5 mx-2 text-danger'}/></Link>
                    </td>
                </tr>

            )
        }) : (
            <tr>
                <td colSpan={7}><h4 className={'text-center'}>Nessun piano di pagamento trovato</h4></td>
            </tr>
        )}
        </tbody>
    </Table>)
}
export default PaymentsTable