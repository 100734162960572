import {
    addGameConvocations,
    addOrEditNote,
    deleteConvocation,
    downloadConvocations,
    retrieveGameConvocablePlayers,
    retrieveGameDetail
} from "../../../Api/Teams/Games/TeamGamesFetch";
import React, {useEffect, useState} from "react";
import {Link, useParams} from "react-router-dom";
import {Alert, Badge, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {RiDeleteBinLine} from "react-icons/ri";
import {GoDownload} from "react-icons/go";
import {CiStickyNote} from "react-icons/ci";
import ConvocationsNoteModal from "../../../Components/Modals/Games/ConvocationsNoteModal";
import Loading from "../../../Components/Layout/Elements/Loading";

const GameDetail = () => {
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [isLoading, setIsLoading] = useState()
    const [gameDetail, setGameDetail] = useState()
    const [convocables, setConvocables] = useState()
    const [showConvocables, setShowConvocables] = useState(true)
    const [addConvocations, setAddConvocations] = useState([])
    const [convocations, setConvocations] = useState()
    const [update, setUpdate] = useState(false)
    const {gameId} = useParams()
    const [show, setShow] = useState()
    const [notes, setNotes] = useState([])
    const [convocationId, setConvocationId] = useState()
    const [selectAll, setSelectAll] = useState(false); // Stato per il testo del link seleziona deseleziona
    useEffect(() => {
        retrieveGameDetail({gameId, setGameDetail, setIsLoading, setErrors, setMessage});
        retrieveGameConvocablePlayers({gameId, setConvocables, setIsLoading, setErrors, setMessage})
    }, [update])
    // Estrae la data e l'ora locali dalla risposta
    const localDateTime = gameDetail && gameDetail.local_time;
    var formattedDate = ''
    console.log(localDateTime)
    if (localDateTime) {
        const [datePart, timePart] = localDateTime.split(' ');
        const [hrs, min] = timePart.split(':')

        // Dividi la parte della data in anno, mese e giorno
        const [year, month, day] = datePart.split('-');

        // Formatta la data nel formato desiderato
        formattedDate = `${day}/${month}/${year} ${hrs}:${min}`;
    }

    useEffect(() => {
        console.log(addConvocations)
        if (addConvocations && addConvocations.length > 0) {
            addGameConvocations({
                gameId,
                addConvocations,
                setIsLoading,
                setErrors,
                setMessage,
                update,
                setUpdate,
                setAddConvocations
            })
        }
    }, [addConvocations])
    const handleDownload = () => {
        downloadConvocations(gameId, {setErrors, setIsLoading, setMessage})
    }
    const handelAddConvocations = () => {
        const input = document.querySelectorAll('form.convocables input:checked')
        console.log(document.querySelectorAll('form.convocables input:checked'), document.querySelectorAll('form.convocables input'))

        input.forEach((i) => {
            setAddConvocations(prevState => {
                return [...prevState, {profile_id: i.value}]
            })

            console.log(i.value)
            return i.value
        })
    }
    const handleOpenModal = (e) => {
        e.preventDefault()
        setShow(true)
        setNotes(e.currentTarget.getAttribute('data-note'))
        console.log(notes)
        setConvocationId(e.currentTarget.getAttribute('data-id'))
        console.log(convocationId)

    }
    const handleNotes = () => {
        console.log(notes)
        if (notes.length > 0) {
            console.log(notes)
            addOrEditNote({convocationId, notes, setIsLoading, setErrors, setMessage, update, setUpdate, setShow})
        }
    }
    const handleDeleteConvocations = (e) => {
        const convId = e.currentTarget.getAttribute('data-id')
        console.log(convId)
        deleteConvocation({convId, setIsLoading, setErrors, setMessage, update, setUpdate})
    }


    const handleSelectAll = () => {
        const inputs = document.querySelectorAll('form.convocables input[type="checkbox"]');
        const newSelectAllState = !selectAll;
        inputs.forEach(input => {
            input.checked = newSelectAllState;
        });
        setSelectAll(newSelectAllState);
    };

    return <>
        <Row>
            <Col>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}

                <Card className={'shadow border-0 py-3 px-3 text-start'}>
                    <Row className={'mx-0'}>
                        <Col>
                            <h2 className={'mb-3 text-start'}>{gameDetail && gameDetail.team_name}</h2>
                            <p><b>Data gara</b>: {gameDetail && formattedDate}</p>
                            <p><b>Squadra avversaria</b>: {gameDetail && gameDetail.opponent}</p>
                            <p><b>Luogo</b>: {gameDetail && gameDetail.location}</p>
                            <Button
                                className={'btn bkg-second btn-sm border-0'} onClick={handleDownload}> <GoDownload
                                className={'fs-'}/> Scarica lista
                                convocati</Button>
                        </Col>

                        <Col>
                            <Row>
                                {/*<Col md={12} className={'text-end'}>
                                    <Button
                                        className={'btn bkg-second btn-sm border-0'} onClick={() => {
                                        setShowConvocables(true)
                                    }}> <PiUsersThreeLight className={'fs-5'}/> Aggiungi convocazioni</Button>
                                </Col>*/}
                            </Row>

                            <Table>
                                <thead>
                                <tr>
                                    <th className={'bkg-primary text-white'}>Nome</th>
                                    <th className={'bkg-primary text-white'}>Ruolo</th>
                                    <th className={'bkg-primary text-white'}>Partecipazione</th>
                                    <th className={'bkg-primary text-white'}></th>
                                </tr>
                                </thead>
                                <tbody>
                                {gameDetail && gameDetail.convocations.length > 0 ? gameDetail.convocations.map(conv => {
                                    return <tr key={conv.id}>
                                        <td>{conv.profile_name}</td>
                                        <td>{conv.sport_role_name}</td>
                                        <td><Badge
                                            bg={conv.has_accepted === null ? 'dark' : (conv.has_accepted === 0 ? 'danger' : 'success')}>{conv.has_accepted === null ? 'Non confermato' : (conv.has_accepted === 0 ? 'No' : 'Si')}</Badge>
                                        </td>
                                        <td>
                                            <Link className={'text-decoration-none'} onClick={handleOpenModal}
                                                  title={'Aggiungi o modifica Nota'} data-note={conv.notes}
                                                  data-id={conv.id}>
                                                <CiStickyNote className={'fs-5'}/>
                                            </Link>
                                            <Link data-id={conv.id} className={'text-decoration-none'}
                                                  title={'Elimina Convocazione'}
                                                  onClick={handleDeleteConvocations}> <RiDeleteBinLine
                                                className={'fs-5 mx-2 text-danger'}/></Link>

                                        </td>
                                    </tr>
                                }) : <tr>
                                    <td colSpan={7}>
                                        <h5 className={'text-center fst-italic'}>Nessuna convocazione</h5>
                                    </td>
                                </tr>}
                                </tbody>
                            </Table>

                        </Col>
                    </Row>
                </Card>
            </Col>
            {showConvocables && <Col md={12} className={'mt-3'}>
                <Card className={'shadow border-0 py-3 px-3 text-start'}>
                    <Row className={'mx-0 align-items-center'}>
                        <Col>
                            <h5 className={''}>Lista Convocabili</h5>
                        </Col>
                        <Col md={4} className={'text-end'}>
                            {/*   <Link to={''} onClick={()=>{setShowConvocables(false)}}>Nascondi</Link>*/}
                        </Col>

                        <Form className={'convocables mt-3'}>
                            {convocables && convocables.length > 0 &&
                                <>      <Link to={''} onClick={handleSelectAll} className={'mb-4 d-inline-block rounded-2 smaller '}>
                                    {selectAll ? 'Deseleziona tutti' : 'Seleziona tutti'}</Link>
                                    {
                                        convocables.map(i => {
                                            return (
                                                <Form.Check key={i.id} label={i.first_name + ' ' + i.last_name}
                                                            value={i.id}/>
                                            )
                                        })
                                    }
                                </>
                            }
                        </Form>
                    </Row>
                    <Row className={'mx-0 justify-content-center mt-3'}>
                        <Col>
                            <Button
                                className={'btn bkg-second btn-sm border-0'} onClick={handelAddConvocations}>Invia
                                convocazioni</Button>
                        </Col>
                    </Row>
                </Card>
            </Col>}

        </Row>
        <ConvocationsNoteModal show={show} setShow={setShow} actionModal={handleNotes} notes={notes}
                               setNotes={setNotes}/>
        {isLoading && <Loading/>}
    </>
}

export default GameDetail