import { useEffect, useState } from "react";
import { retrieveSeasonsList } from "../../../Api/Payments/Sesons";
import { Form } from "react-bootstrap";
import {retrieveSeasons} from "../../../Api/Teams/TeamsFetch";

const SeasonsSelect = ({  selectedSeason, setSelectedSeason, showAll, action, hideLabel }) => {
    const [seasonsList, setSeasonsList] = useState([]);
    // const [selectedSeason, setSelectedSeason] = useState("");

    useEffect(() => {
        retrieveSeasons({setSeasons: setSeasonsList})
    }, []);

    useEffect(() => {
        if (seasonsList && seasonsList.length > 0) {
            const currentSeason = seasonsList.find(i => i.is_current === 1);
            setSelectedSeason(currentSeason?.id || ""); // Preseleziona la stagione corrente
        }
    }, [seasonsList]);

    const handleSelectChange = (e) => {
        setSelectedSeason(e.target.value);
        action && action()
    };

    return (
        <>
            {!hideLabel &&    <Form.Label className={'small mb-0 text-start'}>Stagione</Form.Label>}
            <Form.Select
                className="form-select-sm"
                name={'season_id'}
                value={selectedSeason}
                onChange={handleSelectChange}
            >
                {showAll &&   <option value={'all'}> Tutte </option> }
                {seasonsList.map(i => (
                    <option value={i.id} key={i.id}>
                        {i.name}
                    </option>
                ))}
            </Form.Select>
        </>
    );
};

export default SeasonsSelect;
