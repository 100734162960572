import {Col, Modal, Row} from "react-bootstrap";

const ModalTrainingPlanDetail = (props) => {
    const handleClose = () => {
        props.setShow(false)
    }
    console.log('index', props.indexElement)
    return (
        <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
            <Modal.Header closeButton></Modal.Header>
            <Modal.Body>

                {props.indexElement && props.indexElement.plan.map((i, index) => {


                            return <Col md={12} key={i.id}>
                                <Row>
                                    <Col> {props.weekDays && props.weekDays.map(day => {
                                        console.log(day)
                                        return day.id == i.day && day.value
                                    })}</Col>
                                    <Col>{i.location}</Col>
                                    <Col>{i.start_time} - {i.end_time}</Col>
                                </Row>
                            </Col>



                })}

            </Modal.Body>
        </Modal>
    )
}

export default ModalTrainingPlanDetail