import {Button, Col, Form, Row} from "react-bootstrap";

import React, {useEffect, useState} from "react";
import DatePicker from "react-datepicker";
import {it} from 'date-fns/locale/it'
import {formatDataTime} from "../../../Utils/DateFormat";
import {emptyError, handleErrors} from "../../Errors";

const GameddForm = (props) => {
    const [startDate, setStartDate] = useState(null);
    const [oldGame, setOldGames] = useState()
    const today = new Date()

/*    useState(() => {
        setOldGames(startDate < new Date())
        console.log(startDate, today)
        console.log(startDate < new Date())
        if (startDate < new Date()) {
            console.log('La data è nel passato');

        }
    }, [])*/

    useEffect(() => {
        console.log(props.errors)
        if (props.errors) {
            handleErrors(props.errors)
        }
        console.log(props.errors, props.generalError)
    }, [props.errors])

    useEffect(() => {
        if (props.gameDetail && props.gameDetail.date_time) {
            setStartDate(new Date(props.gameDetail.date_time));
            setOldGames(new Date(props.gameDetail.date_time)< new Date())
            console.log(oldGame)
        } else {
            // Nessuna data fornita, imposto la data corrente
            setStartDate(new Date());
            setOldGames(false)
            console.log(oldGame)
        }
    }, [props.gameDetail]);

    const teamMembers = props.teamDetail && props.teamDetail.members ? props.teamDetail.members.map((i, index) => {
            return {
                label: i.full_name,
                value: i.id,
                index: index
            }
        }
    ) : []

    useEffect(() => {
        console.log('startDate', startDate)

    }, [startDate, props.gameDetail])



    const handleUpdateGame = (e) => {
        e.preventDefault()
        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')
        const inputs = document.querySelectorAll('.gameForm input:not(.datePicker), .gameForm select');
        const newDataObject = {};
        inputs.forEach(input => {
            const value = input.value;
            const name = input.name;

            // Only add to the object if both value and name are present
            if (value && name) {
                newDataObject[name] = value;
            }
        });
        // Add the 'convocations' key with the array value
        newDataObject.convocations = props.convocations || [];
        newDataObject.convocations_to_remove = props.convocationsToRemove
        let date = formatDataTime(new Date(startDate))
        newDataObject.date_time = date + ':00'
        console.log(newDataObject.date_time)
        // Update the state with the new object
        if (!emptyfields) {
            props.setEditedGame(newDataObject);
        }

    };
    const handleAddNewGame = (e) => {
        e.preventDefault()
        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')
        const inputs = document.querySelectorAll('.gameForm input:not(.datePicker), .gameForm select');
        const newDataObject = {};
        inputs.forEach(input => {
            const value = input.value;
            const name = input.name;
            // Only add to the object if both value and name are present
            newDataObject[name] = value;
        });

        // Add the 'convocations' key with the array value
        newDataObject.convocations = props.convocations || [];
        newDataObject.team_id = props.id || [];
        let date = formatDataTime(new Date(startDate))
        newDataObject.date_time = date + ':00'
        console.log(newDataObject.date_time)
        console.log(newDataObject)
        // Update the state with the new object
       if (!emptyfields) {
           props.setAddNewGame(newDataObject);
       }

    };

    return (
        <>
            <Form className={'gameForm'}>

                <Row className={'text-start'}>
                    <Col md={4} className={'position-relative'}>
                        <Form.Label className={'small mb-1'}>Location</Form.Label>
                        <Form.Control
                            defaultValue={props.gameDetail && props.gameDetail.location ? props.gameDetail.location : ''}
                            className={'mb-2'} name={'location'} type={'text'} required={true}
                            placeholder={'Location'} disabled={oldGame}></Form.Control>
                    </Col>
                    <Col md={4} className={'position-relative errorField_up'}>
                        <Form.Label className={'small mb-1'}>Indirizzo</Form.Label>
                        <Form.Control
                            defaultValue={props.gameDetail && props.gameDetail.location_address ? props.gameDetail.location_address : ''}
                            className={'mb-2'} name={'location_address'} type={'text'} required={true}
                            placeholder={'indirizzo'} disabled={oldGame}></Form.Control>
                    </Col>
                    <Col md={4} className={'position-relative'}>
                        <Form.Label className={'small mb-1'}>Squadra avversaria</Form.Label>
                        <Form.Control defaultValue={props.gameDetail?.opponent ?? ''} className={'mb-2'}
                                      name={'opponent'} type={'text'}
                                      placeholder={'Squadra avversaria'} disabled={oldGame}></Form.Control>
                    </Col>
                    <Col md={4} className={'position-relative'}>
                        <Form.Label className={'small mb-1'}>Nome evento</Form.Label>
                        <Form.Control defaultValue={props.gameDetail?.event_title ?? ''} className={'mb-2'}
                                      name={'event_title'} type={'text'}
                                      placeholder={'Squadra avversaria'} disabled={oldGame}></Form.Control>
                    </Col>
                    <Col md={4} className={'position-relative'}>
                        {/*<input type={'datetime-local'} name={'test'}/>*/}
                        <Form.Label className={'small mb-1 d-block'}>Data</Form.Label>
                        <DatePicker
                            wrapperClassName={'w-100'}
                            className={' form-control d-block datePicker'}
                            showTimeSelect
                            dateFormat="d/MM/yyyy HH:mm"
                            name={'date_time'}
                            locale={it}
                            selected={startDate}
                            onChange={(date) => setStartDate(date)}
                            disabled={oldGame}
                        />
                    </Col>


                    <Col md={4} className={'position-relative'}>
                        <Form.Label className={'small mb-1'}>Tipo Gara</Form.Label>
                        <Form.Select name={'game_type'} className={'mb-2'} disabled={oldGame}>
                            <option value={''}>Seleziona</option>
                            {props.gameType && props.gameType.map(type => {
                                return (
                                    <option key={type.id} value={type.id}  selected={props.gameDetail && props.gameDetail.game_type === type.id ? 'selected' : ''}> {type.type}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                    <Col md={4} className={'position-relative'}>
                        <Form.Label className={'small mb-1'}>Campo</Form.Label>
                        <Form.Select name={'game_field'} className={'mb-2'} disabled={oldGame}>
                            <option value={''}>Seleziona</option>
                            {props.gameField && props.gameField.map(field => {

                                return (
                                    <option key={field.id} value={field.id}
                                            selected={props.gameDetail && props.gameDetail.game_field === field.id ? 'selected' : ''}> {field.field}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                    {startDate < new Date() && <Col md={4} className={'position-relative'}>
                        <Form.Label className={'small mb-1'}>Risultato</Form.Label>
                        <Form.Control defaultValue={props.gameDetail?.score_description ?? ''} className={'mb-2'}
                                      name={'score_description'} type={'text'}
                                      placeholder={'Aggiungi risultato'}></Form.Control>
                    </Col>}

                </Row>
                <Col md={1} className={'text-start mt-2'}>
                    <Form.Label className={'small mb-1'}>N. max di convocati</Form.Label>
                    <Form.Control disabled={oldGame} type={'number'} name={'players_limit'} defaultValue={props.gameDetail && props.gameDetail.players_limit ? props.gameDetail.players_limit : ''}></Form.Control>
                </Col>
                <Button className={'bkg-second border-0 mt-4'}
                        onClick={props.gameDetail ? handleUpdateGame : handleAddNewGame}>{props.gameDetail ? 'Modifica gara' : 'Aggiungi gara'}</Button>

            </Form>
        </>
    )
}

export default GameddForm