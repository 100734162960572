import {Button, Form, InputGroup, Nav} from "react-bootstrap";
import {useEffect} from "react";

const MembersFilters = (props) => {


    const handleFilter = (e) => {
        e.preventDefault();
        const name = e.currentTarget.getAttribute('data-value')
        props.setFilterType(name)
        props.setPageUrl('/profile?')
    }
    return (
        <>


            <Nav variant="pills" defaultActiveKey="link-0">
                <Nav.Item>
                    <Nav.Link eventKey="link-0" data-value={'filter_type=0'} onClick={handleFilter} className={'d-flex align-content-center'}>Tutti <span
                        className={'text-smaller d-inline-block rounded-5  bg-black text-white ms-2 py-1 px-2'}>{props.count && props.count.all}</span></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-1" data-value={'filter_type=1'}
                              onClick={handleFilter}>Soci <span
                        className={'text-smaller d-inline-block rounded-5 bg-black text-white ms-2 py-1 px-2'}>{props.count && props.count.verified}</span></Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-2" data-value={'filter_type=2'} onClick={handleFilter} className={'d-flex align-content-center'}>
                        In Attesa <span
                        className={'text-smaller d-inline-block rounded-5  bg-black text-white ms-2 py-1 px-2'}>{props.count && props.count.pending} </span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-3" data-value={'filter_type=3'} onClick={handleFilter}  className={'d-flex align-content-center'}>
                        Rimossi <span
                        className={'text-smaller d-inline-block rounded-5  bg-black text-white ms-2 py-1 px-2'}>{props.count && props.count.removed}</span>
                    </Nav.Link>
                </Nav.Item>
                <Nav.Item>
                    <Nav.Link eventKey="link-4" data-value={'filter_type=4'} onClick={handleFilter} className={'d-flex align-content-center'}>
                        Dirigenti <span
                        className={'text-smaller d-inline-block rounded-5  bg-black text-white ms-2 py-1 px-2'}>{props.count && props.count.roles} </span>
                    </Nav.Link>
                </Nav.Item>
            </Nav>


        </>
    )
}

export default MembersFilters