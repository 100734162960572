import AssociationSettingsForm from "../../Components/Settings/AssociationSettingsForm";
import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../Contexts/authContext";
import {changeSeason, retrieveAssociationInfos} from "../../Api/Settings/SettingsFetch";
import {Alert, Col, Row} from "react-bootstrap";
import AssociationsCode from "../../Components/Settings/AssociationQrCode";
import AssociationContacts from "../../Components/Settings/AssociationContacts";
import SeasonsSelect from "../../Components/Payments/Elements/SeasonsSelect";
import {ConfirmationModal} from "../../Components/Modals/ConfirmationModal";

const AssociationSettings = () => {
    const [associationInfo, setAssociationInfo] = useState()
    const [updatedAssociationInfo, setUpdatedAssociationInfo] = useState()
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const {profile, setUserProfile} = useContext(AuthContext)
    const [update, setUpdate] = useState(false)
    const [selectedSeason, setSelectedSeason] = useState(false)
    const [showConfirm, setShowConfirm] = useState(false)

    useEffect(() => {
        retrieveAssociationInfos({setAssociationInfo})
    }, [profile, update])


    useEffect(() => {
        console.log('info', associationInfo)
        setAssociationInfo(associationInfo)
    }, [associationInfo, profile])
    const handleConfirm = () => {
        changeSeason({seasonId: selectedSeason, setShowConfirm, setMessage, setErrors})
    }
    return (
        <>
            <Row>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}
                <Col md={12}>
                    <Row className={'card py-3 border-0 shadow my-2 text-start'}>
                        <Col>
                            <h2 className={'text-start'}>Stagione corrente</h2>
                            <SeasonsSelect showAll={false} selectedSeason={selectedSeason} hideLabel={true}
                                           setSelectedSeason={setSelectedSeason} action={() => {
                                setShowConfirm(true)
                            }}/>
                        </Col>
                    </Row>
                    <AssociationSettingsForm setUpdatedAssociationInfo={setUpdatedAssociationInfo}
                                             updatedAssociationInfo={updatedAssociationInfo} setMessage={setMessage}
                                             setErrors={setErrors} setUserProfile={setUserProfile} profile={profile}
                                             associationInfo={associationInfo} setAssociationInfo={setAssociationInfo}
                                             setUpdate={setUpdate} update={update}/>

                </Col>
            </Row>
            <Row className={'align-items-stretch'}>
                <Col md={4}>
                    <AssociationsCode associationInfo={associationInfo}/>
                </Col>
                <Col md={8} className={'h-100'}>
                    <AssociationContacts setUpdatedAssociationInfo={setUpdatedAssociationInfo}
                                         updatedAssociationInfo={updatedAssociationInfo} setMessage={setMessage}
                                         setErrors={setErrors} setUserProfile={setUserProfile} profile={profile}
                                         associationInfo={associationInfo} setAssociationInfo={setAssociationInfo}
                                         setUpdate={setUpdate} update={update}/>
                </Col>
            </Row>
            <ConfirmationModal show={showConfirm} handleClose={() => {
                setShowConfirm(false)
            }}
                               handleConfirm={handleConfirm}
                               actionMessage={`Vuoi cambiare la stagione per l'associazione?`}></ConfirmationModal>
        </>
    )
}

export default AssociationSettings