import {Button, Col, Form, Row} from "react-bootstrap";
import {GrClearOption} from "react-icons/gr";
import SeasonsSelect from "./Elements/SeasonsSelect";

const PaymentsSearch = (props) => {
    const handleSearch = (e) => {
        e.preventDefault()
        props.setSearch({})
        props.setPageUrl('/payments/report?')

        for (let i of document.querySelectorAll('form input, form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name');
                const value = i.value;
                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }
    }
    const handleClear = (e) => {
        e.preventDefault();
        props.setSearch({})
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }
        props.setPageUrl('/payments/report?')
    }
    return (
        <>
            <div className={'mb-3'}>
                <div className={'border-1 border-solid border-light'}>
                    <Row className={'align-content-center'}>
                        <Col md={8}><h5 className={'text-start fw-bold'}>Ricerca Pagamenti</h5></Col>
                        <Col md={4} className={'text-end'}>
                            <GrClearOption className={'me-2 fs-4'} title={'Pulisci Filtri'} onClick={handleClear}/>
                        </Col>
                    </Row>
                    <Form className={'row  align-items-end'}>
                        <Form.Group className={'col-md-3 d-inline-block text-start'}>
                            <Form.Label className={' small mb-0'}>Piano di pagamento</Form.Label>
                            <Form.Select className={'form-select-sm mb-1'} type={'date'} name="payment_plan_id"
                                         placeholder={'Data di nascita'}>

                                <option value={''}>Seleziona</option>
                                {props.paymentPlanName && props.paymentPlanName.map(i => {

                                    return <option key={i.id} value={i.id}>{i.name}</option>
                                })}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className={'col-md-3 d-inline-block text-start  '}>
                            <Form.Label className={'small mb-0'}>Nome socio</Form.Label>
                            <Form.Control className={'form-control-sm mb-1'} name="profile_name"
                                          placeholder={'Nome'}></Form.Control>
                        </Form.Group>

                        <Form.Group className={'col-md-2 d-inline-block text-start  '}>
                            <Form.Label className={'small mb-0'}>Causale</Form.Label>
                            <Form.Control className={'form-control-sm mb-1'} name="causal"
                                         ></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-2 d-inline-block text-start'}>
                            <Form.Label className={' small mb-0'}>Data dal</Form.Label>
                            <Form.Control className={'form-control-sm mb-1'} type={'date'} name="date_from"
                                          placeholder={'Data di nascita'}></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-2 d-inline-block text-start'}>
                            <Form.Label className={' small mb-0'}>Data al</Form.Label>
                            <Form.Control className={'form-control-sm mb-1'} type={'date'} name="date_to"
                                          placeholder={'Data di nascita'}></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-3 d-inline-block text-start'}>
                           <SeasonsSelect setErrors={props.setErrors} setIsLoading={props.setIsLoading}
                                          setSelectedSeason={props.setSelectedSeason} selectedSeason={props.selectedSeason} showAll={true} />
                        </Form.Group>
                        <Col md={2}>
                            <Button type={"submit"} onClick={handleSearch}
                                    className={'w-100 btn btn-sm bkg-second no-border border-0 mb-1 my-2'}>Cerca</Button>
                        </Col>


                    </Form>
                </div>

            </div>
        </>
    )
}

export default PaymentsSearch