import React, {useContext, useEffect, useState} from "react";
import {coachAutomaticLogin} from "../Api/Auth/LoginFetch";
import {Alert} from "react-bootstrap";
import Loading from "../Components/Layout/Elements/Loading";
import AuthContext from "../Contexts/authContext";

const Coach = () => {
    const [errors, setErrors] = useState()
    const [message, setMessage] = useState()
    const [isLoading, setIsLoading] = useState(false)
    const params = new URLSearchParams(window.location.search)
    let token = params.get('token')
    const {login} = useContext(AuthContext)
    /*const navigate = useNavigate()*/

    console.log(token)
    useEffect(()=>{
        if (token) {
            coachAutomaticLogin({token, setMessage,setErrors,setIsLoading, login})
        }
    }, [token])

    return (
        <>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
            {isLoading && <Loading/>}
        </>
    )
}

export default Coach