import React, {useEffect, useState} from "react";
import {
    addNewPayment, cancelPayment,
    deletePayedInstallment, editPayment,
    payInstallment,
    retrieveMemberPaymentsList, retrieveMemberPaymentsOverview, showPayment
} from "../../../Api/Payments/PaymentsFetch";
import {CiEdit} from "react-icons/ci";
import {Link} from "react-router-dom";
import {RiDeleteBinLine} from "react-icons/ri";
import {Badge, Button, Card, Col, Form, Row, Table} from "react-bootstrap";
import {formatData, formatDataIt} from "../../../Utils/DateFormat";
import {LiaCashRegisterSolid} from "react-icons/lia";
import IconTooltip from "../../Layout/Elements/IconsTooltip";
import {ConfirmationModal} from "../../Modals/ConfirmationModal";
import ModalAddNewPayment from "../../Modals/Payments/ModalAddNewPayment";
import OverviewButtons from "../../Payments/Elements/OverviewButtons";
import {
    deleteKit,
    deleteMembership,
    retrieveMemberEquipment,
    retrieveMemberMembership
} from "../../../Api/Members/MembersFetch";
import ModalAddNewKitOrMembership from "../../Modals/Members/ModalAddNewKitOrMembership";

const OtherInfoTab = ({id, setIsLoading, setMessage, setErrors, errors}) => {

    const [showConfirmDelete, setShowConfirmDelete] = useState(false)

    const [membershipId, setMembershipId] = useState()
    const [equipmentId, setEquipmentId] = useState()
    const [memberMemberships, setMemberMemberships] = useState()
    const [memberEquipment, setMemberEquipment] = useState()
    const [update, setUpdate] = useState(false)
    const [showAddKitOrMembership, setShowAddKitOrMembership] = useState(false)
    const [modalType, setModalType] = useState()

    useEffect(() => {
        console.log(showConfirmDelete)
    }, [showConfirmDelete])

    useEffect(() => {
        retrieveMemberMembership({id, setMemberMemberships, setIsLoading, setMessage, setErrors})
    }, [id, setErrors, setIsLoading, setMessage, update])

    useEffect(() => {

        retrieveMemberEquipment({id, setMemberEquipment, setIsLoading, setMessage, setErrors})
    }, [id, setErrors, setIsLoading, setMessage, update,])

    const handleDelete = () => {
        if (membershipId) {
            deleteMembership({
                id,
                membershipId,
                setIsLoading,
                setErrors,
                setMessage,
                setShowConfirmDelete,
                setMembershipId,
                setUpdate
            })
        } else if (equipmentId) {
            deleteKit({id, equipmentId, setIsLoading, setErrors, setMessage, setShowConfirmDelete, setEquipmentId, setUpdate})
        }

    }

    return (
        <>
            <Row>
                <Col>
                    <h2 className={'text-start'}>Tesseramenti</h2></Col>
                <Col className={'text-end'}>
                    <Button
                        className={'btn bkg-second btn-sm border-0'}
                        onClick={() => {
                            setModalType('tesseramento')
                            setShowAddKitOrMembership(true)
                        }}>Aggiungi Tesseramento</Button></Col> </Row>

            <Table>
                <thead>
                <tr>
                    <th className={'bkg-primary text-white'}>Federazione</th>
                    <th className={'bkg-primary text-white'}>Numero Tesseramento</th>
                    <th className={'bkg-primary text-white'}>Data Inizio</th>

                    <th className={'bkg-primary text-white'}>Data fine</th>

                    <th className={'bkg-primary text-white'}>Azioni</th>


                </tr>
                </thead>
                <tbody>
                {memberMemberships && memberMemberships.length > 0 ? memberMemberships.map((membership, index) => {


                    return (
                        <tr key={index}
                        >
                            <td>{membership.federation}
                            </td>
                            <td>{membership.membership_number}</td>
                            <td>{formatDataIt(new Date(membership?.membership_start_date))} </td>
                            <td> {formatDataIt(new Date(membership?.membership_end_date))}</td>

                            <td className={''}>
                                <IconTooltip title={'Elimina tesseramento'} action={() => {
                                    setShowConfirmDelete(true)
                                    setMembershipId(membership.id)
                                }}>
                                    <RiDeleteBinLine size={20} color={'black'}/>
                                </IconTooltip>
                            </td>
                        </tr>

                    )
                }) : (
                    <tr>
                        <td colSpan={7}><h4 className={'text-center'}>Nessun tesseramento trovato</h4></td>
                    </tr>
                )}
                </tbody>
            </Table>
            <Row>
                <Col>
                    <h2 className={'text-start'}>KIT</h2></Col>
                <Col className={'text-end'}>
                    <Button
                        className={'btn bkg-second btn-sm border-0'}
                        onClick={() => {
                            setModalType('kit')
                            setShowAddKitOrMembership(true)
                        }}>Aggiungi Kit</Button></Col> </Row>
            <Table>
                <thead>
                <tr>
                    <th className={'bkg-primary text-white'}>Tipo kit</th>
                    <th className={'bkg-primary text-white'}>Data consegna</th>
                    <th className={'bkg-primary text-white'}>Data restituzione</th>

                    <th className={'bkg-primary text-white'}>Azioni</th>


                </tr>
                </thead>
                <tbody>
                {memberEquipment && memberEquipment.length > 0 ? memberEquipment.map(equipment => {
                    console.log(equipment.id)
                    return (
                        <tr>

                            <td>{equipment.equipment_description} </td>
                            <td className={''}>{formatDataIt(new Date(equipment.equipment_delivery_date))}</td>
                            <td className={''}>{equipment.equipment_return_date && formatDataIt(new Date(equipment.equipment_return_date))}</td>

                            <td className={''}>

                                <IconTooltip title={'Elimina'} action={() => {
                                    setEquipmentId(equipment.id)
                                    setShowConfirmDelete(true)

                                }}>
                                    <RiDeleteBinLine size={20} color={'black'}/>
                                </IconTooltip>
                            </td>

                        </tr>

                    )
                }) : (
                    <tr>
                        <td colSpan={7}><h4 className={'text-center'}>Nessun kit trovato</h4></td>
                    </tr>
                )}
                </tbody>
            </Table>

            <ModalAddNewKitOrMembership show={showAddKitOrMembership} errors={errors} setShow={setShowAddKitOrMembership}
                                        handleClose={() => {
                                            setShowAddKitOrMembership(false)
                                        }}
                                        modalType={modalType}
                                        setErrors={setErrors}
                                        setIsLoading={setIsLoading} id={id} setUpdate={setUpdate} update={update}/>
            <ConfirmationModal show={showConfirmDelete} handleClose={() => {
                setShowConfirmDelete(false)
                setEquipmentId()
                setMembershipId()
            }}
                               handleConfirm={handleDelete}
                               actionMessage={`Vuoi eliminare ${membershipId !== undefined ? 'la membership' : equipmentId && 'il kit'} ?`}
            />


        </>
    )
}
export default OtherInfoTab