import {Alert, Card} from "react-bootstrap";

import Loading from "../../Components/Layout/Elements/Loading";

import React, {useEffect, useState} from "react";
import MemeberAddForm from "../../Components/Members/MemeberAddForm";
import {editMemberDetails, retrieveMemberDetail} from "../../Api/Members/MembersFetch";
import {useNavigate, useParams} from "react-router-dom";
import {emptyError, handleErrors} from "../../Components/Errors";

const EditMember = () => {
    const [isLoading, setIsLoading] = useState(false);
    const [editMember, setEditMember] = useState({})
    const [message, setMessage] = useState();
    const navigate = useNavigate()
    const [errors,setErrors]= useState()
    const [memberDetail, setMemberDetail] = useState()
    const {id} = useParams()


    useEffect(() => {
        if (errors) {
            handleErrors(errors)
        }
    }, [errors])

    useEffect(() => {
        retrieveMemberDetail({id, setMemberDetail, setErrors, setIsLoading})
    }, [id])

    useEffect(() => {
        if (Object.keys(editMember).length > 0) {
            emptyError()
            const emptyfields = document.querySelector('span.error_empty_field')

            if (!emptyfields) {
                setIsLoading(true)
                editMemberDetails({id, editMember, setIsLoading, navigate, setMessage, setErrors})
            }
        }
    }, [editMember])

    return (

        <>

            <Card className={'px-2 py-4 border-0 shadow'}>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
                <MemeberAddForm setEditMember={setEditMember} editMember={editMember} isEdit={true} memberDetail={memberDetail}/>
                {isLoading && <Loading/>}
            </ Card>


        </>

    )
}

export default EditMember