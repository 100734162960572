import React, {useContext, useEffect, useState} from "react";
import {retrieveTeamDetail, updateTeamFetch} from "../../Api/Teams/TeamsFetch";
import {Alert, Card} from "react-bootstrap";
import {useNavigate, useParams} from "react-router-dom";
import Loading from "../../Components/Layout/Elements/Loading";
import TeamAddForm from "../../Components/Teams/Addteam/TeamAddForm";
import authContext from "../../Contexts/authContext";

const TeamEdit = () => {
    const [newTeam, setNewTeam] = useState([]);
    const [updateTeam, setUpdateTeam] = useState([]);
    const [add, setAdd] = useState()
    const [pageUrl, setPageUrl] = useState('/team/update')
    const {profile} = useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [isLoading, setIsLoading] = useState(false)
    const [selectedMembers, setSelectedMembers] = useState([])

    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()
    const [teamDetail, setTeamDetail] = useState();
    const navigate = useNavigate()
    const {id} = useParams()
    const [color, setColor] = useState();
    useEffect(() => {
        retrieveTeamDetail({id, setTeamDetail, setIsLoading, setErrors, setMessage, setGeneralError});
        console.log(errors, generalError)
    }, [id,profile, setTeamDetail]);


    useEffect(() => {
        if (Object.values(updateTeam).length > 0) {
            setAdd(prevState => {
                let teamDatas = {...prevState, ...updateTeam };
                 updateTeamFetch({pageUrl, id, teamDatas, setIsLoading, updateTeam, navigate, setErrors, setMessage, setGeneralError});
                return prevState;  // Restituisci lo stato aggiornato
            });
        }
    }, [updateTeam])


    useEffect(() => {
        // Se c'è teamDetail e ci sono utenti associati, costruisco il selectedMembers per ripopolare la Multiselect
        if (teamDetail && teamDetail.members) {
            teamDetail.members.forEach((i, index) => {
                if (!selectedMembers.some((member) => member.value === i.id)) {
                    setSelectedMembers((prevValue) => [
                        ...prevValue,
                        {
                            label: i.full_name,
                            value: i.id,
                            key: i.id,
                            index: index,
                            name: 'members[' + index + '][' + i.id + ']',
                            note:i.note,
                            associated: i.associated
                        },
                    ]);
                }
            });
        }
        // Se c'è teamDetail e c'è il colore lo setto
        if (teamDetail && teamDetail.color && teamDetail.color !== null) {
            setColor(teamDetail.color)
        }
    }, [teamDetail]);



    return (

        <>
            <Card className={'p-4 shadow borde-0'}>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                <TeamAddForm color={color} setColor={setColor} id={id}
                              selectedMembers={selectedMembers} setSelectedMembers={setSelectedMembers}
                              setNewTeam={setNewTeam} teamDetail={teamDetail} setTeamDetail={setTeamDetail}
                              setUpdateTeam={setUpdateTeam} setAdd={setAdd} setErrors={setErrors} errors={errors} />
            </Card>
            {isLoading && <Loading/>}
        </>
    )
}
export default TeamEdit