import {Button, Col, Form, Modal} from "react-bootstrap";
import React from "react";
import {addContact, addPlaces} from "../../../Api/Settings/SettingsFetch";

const ModalAddNewContact = ({show, handleClose, placesTypes, contactsTypes, setIsLoading, setShow, setErrors, setUpdate, update, modalType}) => {

    const handleAdd = (e) => {
        e.preventDefault();


      if (modalType === 'places') {
          const formData = {};
          for (let i of document.querySelectorAll('#newPlace input, #newPlace select, #newPlace textarea')) {
              const name = i.getAttribute('name');
              const value = i.value;
              formData[name] = value;
          }
          addPlaces({newPlace:formData, setIsLoading, setShow, setErrors, setUpdate, update})
      } else {
          const formData = {};
          for (let i of document.querySelectorAll('#newContact input, #newContact select')) {
              const name = i.getAttribute('name');
              const value = i.value;
              formData[name] = value;
          }
          console.log(formData)
        addContact({newContact:formData, setIsLoading, setShow, setErrors, setUpdate, update})
      }

    }

    return (
        <Modal show={show} onHide={handleClose} className={'modal-lg'} centered={true}>
            <Modal.Header closeButton>
                <h2>
                    Aggiungi nuovo {modalType === 'places' ? 'indirizzo' : 'contatto' } per l'associazione
                </h2>

            </Modal.Header>
            <Modal.Body>


                {modalType === 'places' ?  <Form id={'newPlace'}
                                                 className={` mb-4 mx-0 align-items-center row`}>
                    <Col className={'text-start'} md={4}>

                        <Form.Label className={'text-start'}>Tipologia </Form.Label>
                        <Form.Select name={'place_type_id'}>
                            <option>Seleziona</option>
                            {placesTypes && placesTypes.length > 0 && placesTypes.map(i => {
                                return <option key={i.id} value={i.id}>{i.name}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col className={'text-start'} md={4}>

                        <Form.Label className={'text-start'}>Nome </Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'name'}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start'} md={4}>
                        <Form.Label className={'text-start'}>Indirizzo</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'address_line1'}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start'} md={4}>
                        <Form.Label className={'text-start'}>Città</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'city'}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start'} md={4}>
                        <Form.Label className={'text-start'}>Cap</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'email'} name={'posta_code'}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start'} md={4}>
                        <Form.Label className={'text-start'}>Descrizione</Form.Label>
                        <Form.Control className={'form-control-sm'} as={'textarea'} type={'text'}
                                      name={'description'}
                        ></Form.Control>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-start'}>
                        <Button type={"submit"}
                                className={'mt-3 btn btn-sm me-2 d-inline-block bkg-second no-border border-0'}
                                onClick={handleAdd}>Aggiungi luogo</Button>

                    </Col>

                </Form> :  <Form id={'newContact'}
                                 className={` mb-4 mx-0 align-items-center row`}>
                    <Col className={'text-start'} md={4}>

                        <Form.Label className={'text-start'}>Tipologia </Form.Label>
                        <Form.Select name={'contact_type_id'}>
                            <option>Seleziona</option>
                            {contactsTypes && contactsTypes.length > 0 && contactsTypes.map(i => {
                                return <option key={i.id} value={i.id}>{i.name}</option>
                            })}
                        </Form.Select>
                    </Col>
                    <Col className={'text-start'} md={4}>

                        <Form.Label className={'text-start'}>Nome </Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'name'}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start'} md={4}>
                        <Form.Label className={'text-start'}>Url</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'url'}
                        ></Form.Control>
                    </Col>

                    <Col className={'text-start'} md={4}>
                        <Form.Label className={'text-start'}>Descrizione</Form.Label>
                        <Form.Control className={'form-control-sm'} as={'textarea'} type={'text'}
                                      name={'description'}
                        ></Form.Control>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-start'}>
                        <Button type={"submit"}
                                className={'mt-3 btn btn-sm me-2 d-inline-block bkg-second no-border border-0'}
                                onClick={handleAdd}>Aggiungi Contatto</Button>

                    </Col>

                </Form>}

            </Modal.Body>
        </Modal>)
}
export default ModalAddNewContact