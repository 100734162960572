import {Button, Col, Form, Row} from "react-bootstrap";
import {GrClearOption} from "react-icons/gr";
import SeasonsSelect from "../Payments/Elements/SeasonsSelect";

const TeamsFilters = (props) => {
    const handleSearch = (e) => {
        e.preventDefault()

        props.setSearch({})
        // props.setPageUrl('/api/payers/v1?page=1')

        for (let i of document.querySelectorAll('form input, form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name')

                const value = i.value;

                props.setSearch(search => ({
                        ...search, [name]: value
                    })
                )
            }
        }

    }

    const handleClear = (e) => {
        e.preventDefault();
        props.setSearch({})
        for (let i of document.querySelectorAll('form input, form select')) {
            i.value = ''
        }
    }
    return (
        <>
            <Row className={'mb-3 mx-0'}>
                <div className={'border-1 border-solid border-light'}>
                    <Row className={'align-content-center'}>
                        <Col md={8}><h5 className={'text-start fw-bold'}>Ricerca Teams </h5></Col>
                        <Col md={4} className={'text-end'}>
                            <GrClearOption className={'me-2 fs-4'} title={'Pulisci Filtri'} onClick={handleClear}/>
                        </Col>
                    </Row>
                    <Form className={'row text-start align-items-end'}>

                        <Form.Group className={'col-md-4 d-inline-block'}>
                            <Form.Label className={'small mb-0'}>Nome</Form.Label>
                            <Form.Control className={'form-control-sm'} name={'name'}
                                          placeholder={'Nome'}></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-4 d-inline-block'}>
                            <Form.Label className={'small mb-0'}>Descrizione</Form.Label>
                            <Form.Control className={'form-control-sm'} name={'description'}
                                          placeholder={'Descrizione'}></Form.Control>
                        </Form.Group>
                        <Form.Group className={'col-md-4 d-inline-block'}>
                            <Form.Label className={'small mb-0'}>Coach</Form.Label>
                            <Form.Select className={'form-select-sm '} name={'coach_id'}>
                                <option value={''}>Seleziona il coach</option>
                                {props.coachesList && props.coachesList.length > 0 && props.coachesList.map(coach => {
                                    return (
                                        <option key={coach.id} value={coach.id}>{coach.full_name} </option>
                                    )
                                })}
                            </Form.Select>
                        </Form.Group>

                        <Form.Group className={'col-md-4 d-inline-block'}>
                            <Form.Label className={'small mb-0'}>Sport</Form.Label>
                            <Form.Select className={'form-select-sm '} name={'sport_id'}>
                                <option value={''}>Seleziona lo sport</option>
                                {props.sportsList && props.sportsList.length > 0 && props.sportsList.map(sport => {
                                    return (
                                        <option key={sport.id} value={sport.id}>{sport.name}</option>
                                    )
                                })}
                            </Form.Select>

                        </Form.Group>
                        <Form.Group className={'col-md-4 d-inline-block'}>
                            <SeasonsSelect showAll={true} setSelectedSeason={props.setSelectedSeason}
                                           selectedSeason={props.selectedSeason}/>
                        </Form.Group>
                        <Col md={2}>
                            <Button type={"submit"} onClick={handleSearch}
                                    className={'w-100 btn btn-sm bkg-second no-border border-0 mt-sm-0 mt-3'}>Cerca</Button>

                        </Col>

                    </Form>

                </div>
            </Row>
        </>
    )
}

export default TeamsFilters