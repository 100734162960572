import {Alert, Card} from "react-bootstrap";

import './../../assets/css/Members/Members.css'
import TeamsTable from "../../Components/Teams/TeamsTable";
import TeamsFilters from "../../Components/Teams/TeamsFilters";
import React, {useContext, useEffect, useState} from "react";
import {retrieveSeasons, retrieveTeamsList} from "../../Api/Teams/TeamsFetch";
import Loading from "../../Components/Layout/Elements/Loading";
import {retrieveCoachesList, retrieveSportsList} from "../../Api/Associations/AssociationsFetch";
import authContext from "../../Contexts/authContext";
import './../../assets/css/TeamTable.css'

const TeamsList = () => {
    const [teamsList, setTeamsList] = useState();
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/team')
    const {profile}= useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState([]);
    const [show, setShow] = useState(false)
    const [memberContact, setMemberContact] = useState([])
    const [order, setOrder] = useState()
    const [message, setMessage] = useState()
    const [sportsList, setSportsList] = useState([])
    const [coachesList, setCoachesList] = useState([])
    const [selectedSeason, setSelectedSeason] = useState([])

    const [filterSort, setFilterSort] = useState('')

    useEffect(() => {
        console.log(profile)
        retrieveTeamsList({
            setTeamsList,
            setPagination,
            pageUrl,
            perPage,
            setIsLoading,
            setErrors,
            search,
            filterSort
        })
    }, [pageUrl, perPage, search, profile, filterSort])

    useEffect(() => {
        retrieveSportsList({setSportsList})
        retrieveCoachesList({setCoachesList})

    }, [])

    return (

        <>
            <Card className={'px-2 py-4 border-0 shadow'}>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                <TeamsFilters setSearch={setSearch} sportsList={sportsList} coachesList={coachesList} selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} />
                <TeamsTable teamsList={teamsList} pagination={pagination} setPageUrl={setPageUrl}
                            setPerPage={setPerPage} setIsLoading={setIsLoading} setErrors={setErrors}
                            setMessage={setMessage} setFilterSort={setFilterSort}
                            filterSort={filterSort}/>
            </Card>
            {isLoading && <Loading/>}
        </>
    )
}

export default TeamsList