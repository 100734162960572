import {Col, Container, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import ImgPlaceholder from "../../assets/img/placeholder.png";

const Profiles = (props) => {

    useEffect(() => {
        console.log(props.profiles)
    }, [props.profiles])
    const handleProfileClick = (profile) => {
        props.onSelectProfile(profile);
        console.log(profile)
    };

    return (
        <Container className={'mw-100 mx-0 p-0 h-100 px-0 overflow-hidden bgLogin'}>
            <Row className={'justify-content-center text-center align-content-center h-100'}>
                <Col md={12} className={'mb-5 text-white'}><h2>Seleziona il profilo</h2></Col>
                {props.profiles && props.profiles.map(profile => {
                    console.log(profile)
                    return (
                        profile.associations.map(association => {
                            console.log(association)
                            return (
                                <Col md={3}  key={association.association_id}
                                     className={'bkg-primary m-2 text-white text-center cursor-pointer p-5 rounded-5 border border-4 border-white'}
                                     onClick={() => {

                                         localStorage.setItem('associationID', association.association_id)
                                         // localStorage.setItem('selectedProfile', association.association_id )
                                         handleProfileClick({
                                             profile_name: profile.first_name,
                                             profile_id: profile.profile_id,
                                             association: association.association_id,
                                             association_name: association.association_name,
                                             email: profile.email,
                                             season: association.current_season
                                         })
                                     }
                                     }>
                                   <p> <img src={ImgPlaceholder} width={'100px'} className={'img-fluid '}/></p>
                                    <p key={association.association_id}>
                                        Profilo: {profile.first_name} <br/>
                                        Associazione: {association.association_name}
                                    </p>
                                </Col>
                            )
                        })
                    )
                })}

            </Row>

        </Container>

    )
}

export default Profiles