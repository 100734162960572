import axiosInstance from "../AxiosInstance";
import {retrieveMemberDetail} from "../Members/MembersFetch";

export const retrieveTeamsList = (props) => {
    props.setIsLoading(true)
    console.log(props.pageUrl)
    let url = props.pageUrl
    if (props.search){
        const symbol = props.pageUrl.includes('?') ? '&' : '?';
        url = props.pageUrl + symbol + props.perPage;

        const query= props.search ? Object.keys(props.search)
            .map(key =>  encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
            .join('&') : null;


        const filter = props.filterSort ? Object.keys(props.filterSort)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.filterSort[key]))
            .join('&') : null;

        if(query !== null) url += '&' + query;
        if(filter !== null) url += '&' + filter;
    }

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setTeamsList(response.data.data.data)
        props.setPagination && props.setPagination(response.data.data.links)

        console.log('RetrieveTeamsList', response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const retrieveTeamDetail = (props) => {
    let url = 'team/' + props.id
    props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setTeamDetail(response.data.data)

        // props.setPagination(response.data.data.links)

        console.log('Dettaglio Team', response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}


export const addNewTeam = (props) => {

// Filter out null values from teamDatas
    const filteredTeamDatas = Object.fromEntries(
        Object.entries(props.teamDatas || {}).filter(([key, value]) => value !== null)
    );
    let url = props.pageUrl;

    props.setIsLoading(true)
    axiosInstance.post(url, filteredTeamDatas).then((response) => {
        // Handle successful response (status code 200)

        console.log('AddTeam', response)
        props.setMessage(response.data.message)
        props.navigate('/teams/dettaglio-team/' + response.data.data.id)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const updateTeamFetch = (props) => {

    const filteredTeamDatas = Object.fromEntries(
        Object.entries(props.teamDatas || {}).filter(([key, value]) => value !== null)
    );
    let url = props.pageUrl + '/' + props.id;

    props.setIsLoading(true)
    axiosInstance.put(url, filteredTeamDatas).then((response) => {
        // Handle successful response (status code 200)

        console.log('Edit Team', response)
        props.setMessage(response.data.message)
        if (props.setMemberDetail && props.setMemberTeamsDetail) {
            retrieveMemberDetail({
                setMemberDetail: props.setMemberDetail,
                id: props.id,
                setMemberTeamsDetail: props.setMemberTeamsDetail
            })
        }

        setTimeout(function () {
            props.navigate && props.navigate('/teams/dettaglio-team/' + props.id)
        }, 500)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.errors)
            props.setGeneralError && props.setGeneralError(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deleteTeam = (props) => {
    props.setIsLoading(true)

    let url = '/team/delete/' + props.id

    axiosInstance.delete(url).then((response) => {

        console.log('delete team', response)

        props.setMessage(response.data.message)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}


export const cloneTeam = (props) => {

// Filter out null values from teamDatas
    /*   const filteredTeamDatas = Object.fromEntries(
           Object.entries(props.teamDatas || {}).filter(([key, value]) => value !== null)
       );*/
    let url = '/team/' + props.id + '/clone';

    props.setIsLoading(true)
    axiosInstance.post(url, {withPlayers: props.withPlayers}).then((response) => {
        // Handle successful response (status code 200)
        props.setIsLoading(false)
        console.log('clone Team', response)
        // props.setMessage(response.data.message)
        props.navigate('/teams/dettaglio-team/' + response.data.data.id)

        // props.navigate('/teams/dettaglio-team/' + response.data.data.id)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors(error)
            props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const importPlayers = (props) => {

    let url = '/team/' + props.id + '/import';

    props.setIsLoading(true)
    axiosInstance.post(url, {team_id: props.teamToImport}).then((response) => {
        // Handle successful response (status code 200)
        props.setIsLoading(false)
        console.log('import Players Team', response)
        props.setMessage(response.data.message)
        retrieveTeamDetail({id:props.id, setTeamDetail:props.setTeamDetail, setIsLoading:props.setIsLoading})

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.error('Error:', error.response.data.message);
        }
    })
}

export const retrieveSeasons = (props) => {
    // props.setIsLoading(true)

    let url = '/seasons'

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setSeasons(response.data.data)

        console.log('retrieveSeason', response.data.data)

    }).catch((error) => {
        console.log(error)

        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)

            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)

            console.error('Error:', error);
        }
    })
}

export const addMemberstoStaff = (props) => {

    let url = '/team/' + props.id + '/staff';

    props.setIsLoading(true)
    axiosInstance.post(url, {...props.teamStaff}).then((response) => {
        // Handle successful response (status code 200)
        props.setIsLoading(false)
        console.log('import Players Team', response)
        props.setMessage(response.data.message)
        props.setUpdate(!props.update)
       // retrieveTeamDetail({id:props.id, setTeamDetail:props.setTeamDetail, setIsLoading:props.setIsLoading})

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors(error.response.data.message)
            props.setIsLoading(false)
            console.error('Error:', error.response.data.message);
        }
    })
}