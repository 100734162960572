import axiosInstance from "../AxiosInstance";

export const getNotificationList = ({ setErrors, setDataNotifications }) => {

        // setIsLoading && setIsLoading(true);
        axiosInstance.get('/notifications')
            .then((response) => {
                setDataNotifications(response.data.data.data)
                console.log(response, response.data.data);
                // setIsLoading && setIsLoading(false);

            })
            .catch((error) => {
                // setIsLoading && setIsLoading(false);
                if (error.response && error.response.status === 422) {
                    setErrors && setErrors(error.response.data.errors);
                } else {
                    setErrors && setErrors(error);
                }

            });

};
export const readNotification = ({ notificationID, setIsLoading, setErrors, setDataNotifications }) => {

        setIsLoading && setIsLoading(true);
        axiosInstance.post('/notifications/'+notificationID+ '/read')
            .then((response) => {
                console.log(response, response.data.data);
                setIsLoading && setIsLoading(false);
                getNotificationList({setErrors, setDataNotifications})

            })
            .catch((error) => {
                setIsLoading && setIsLoading(false);
                if (error.response && error.response.status === 422) {
                    setErrors && setErrors(error.response.data.errors);
                } else {
                    setErrors && setErrors(error);
                }

            });

};

export const readAllNotification = ({ setIsLoading, setErrors, setDataNotifications }) => {

    setIsLoading && setIsLoading(true);
    axiosInstance.post('/notifications/read')
        .then((response) => {
            console.log(response, response.data.data);
            setIsLoading && setIsLoading(false);
            getNotificationList({setErrors, setDataNotifications})

        })
        .catch((error) => {
            setIsLoading && setIsLoading(false);
            if (error.response && error.response.status === 422) {
                setErrors && setErrors(error.response.data.errors);
            } else {
                setErrors && setErrors(error);
            }

        });

};
