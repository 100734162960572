import PaymentsSearch from "../../Components/Payments/PaymentsSearch";
import PaymentsFilters from "../../Components/Payments/PaymentsFilter";
import React, {useEffect, useState} from "react";
import {retrievePaymentsList, searchPaymentReport} from "../../Api/Payments/PaymentsFetch";
import ReportsTable from "../../Components/Payments/Reports/ReportsTable";
import Loading from "../../Components/Layout/Elements/Loading";
import {Alert, Card, Col, Row} from "react-bootstrap";

const PaymentsReports = () => {
    const [perPage, setPerPage] = useState('per_page=10');
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/payments/report')
    const [payments, setPayments] = useState()
    const [search, setSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState();
    const [show, setShow] = useState(false)
    const [filterType, setFilterType] = useState('');
    const [update, setUpdate] = useState(false)
    const [message, setMessage] = useState()
    const [paymentsList, setPaymentsList] = useState()
    const [paymentPlanName, setPaymentPlanName] = useState([])
    const [filterSort, setFilterSort] = useState('')
    const [selectedSeason, setSelectedSeason] = useState()
    useEffect(() => {
        console.log('ok')
        retrievePaymentsList({setIsLoading, setPerPage, pageUrl: '/payments/plan', setPaymentsList})
    }, [filterSort, perPage])

    useEffect(() => {
        if (paymentsList) {
            paymentsList.map(payment => {
                console.log(payment)
                setPaymentPlanName(prevState => [...prevState, {
                        ['name']: payment.plan_name,
                        ['id']: payment.id
                    }]
                )

            })
        }
    }, [paymentsList])

    useEffect(() => {
        console.log(paymentPlanName)
    }, [paymentPlanName])

    useEffect(() => {
        if (filterType && filterType !== '') {
            setSearch(search => ({
                    ...search, ['paid']: filterType
                })
            )
        }
    }, [filterType])
    useEffect(() => {
        if (search) {
            searchPaymentReport({
                setPayments,
                setPagination,
                pageUrl,
                perPage,
                setIsLoading,
                setErrors,
                search,
                setUpdate, update,
                filterType,
                filterSort
            })
        }
    }, [search, pageUrl, filterSort])

    return (
        <>
            <>
                <Card className={'px-2 py-4 border-0 shadow mb-2'}>
                    <PaymentsSearch setSearch={setSearch} setPageUrl={setPageUrl} paymentPlanName={paymentPlanName}
                                    setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason}
                                    setErrors={setErrors}/>
                </Card>
                <Card className={'px-2 py-4 border-0 shadow'}>
                    <Row>
                        <Col md={8}>
                            <PaymentsFilters setFilterType={setFilterType} setPageUrl={setPageUrl}/>
                        </Col>

                    </Row>
                    {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                        setMessage(null)
                    }} dismissible> {message} </Alert>}
                    {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                        setErrors(null)
                    }} dismissible> {errors} </Alert>}
                    <ReportsTable payments={payments} pagination={pagination} setPageUrl={setPageUrl}
                                  setPerPage={setPerPage} setShow={setShow}
                                  setErrors={setErrors}
                                  rrors={errors} setFilterSort={setFilterSort}/>

                </Card>
                {isLoading && <Loading/>}


            </>


            {isLoading && <Loading/>}
        </>
    )
}

export default PaymentsReports