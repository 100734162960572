import {Button, Table} from "react-bootstrap";
import React, {useState} from "react";

import {Link} from "react-router-dom";
import {CiEdit} from "react-icons/ci";
import {RiDeleteBinLine} from "react-icons/ri";
import {formatDataIt} from "../../../Utils/DateFormat";
import {deleteGame, downloadConvocations} from "../../../Api/Teams/Games/TeamGamesFetch";
import {ConfirmationModal} from "../../Modals/ConfirmationModal";
import CustomPagination from "../../Layout/Elements/CustomPagination";
import {GoDownload} from "react-icons/go";

const GamesTable = (props) => {
    const [gameId, setGameId] = useState();
    const [showConfirm, setShowConfirm] = useState(false);
    const [actionMessage, setActionMessage] = useState()


    const handleClose = () => {
        setShowConfirm(false)
    }
    const handleConfirm = (e) => {
        deleteGame({
            gameId,
            setIsLoading: props.setIsLoading,
            setErrors: props.setErrors,
            setMessage: props.setMessage,
            id: props.id,
            setTeamDetail: props.setTeamDetail
        })
        setShowConfirm(false)
    }

    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(5);

    const handlePageChange = (page) => {
        setCurrentPage(page);
    };
    const startIndex = (currentPage - 1) * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    return (
        <>
            <Table className={'table-responsive teams'}>
                <thead className={'bkg-second text-white py-2'}>
                <tr>
                    <th className={`py-2 px-2 text-start ${props.isLastGame ? 'bg-grey' : 'bkg-primary'}  text-white`}>
                        Evento
                    </th>

                    <th className={`py-2 px-2 text-start ${props.isLastGame ? 'bg-grey' : 'bkg-primary'}  text-white`}>
                        Luogo
                    </th>
                    <th className={`py-2 px-2 text-start ${props.isLastGame ? 'bg-grey' : 'bkg-primary'}  text-white`}>
                        Data
                    </th>
                    {props.isLastGame && <th className={`py-2 px-2 text-start bg-grey text-white`}>Risultato</th>}
                    <th className={`py-2 px-2 text-start ${props.isLastGame ? 'bg-grey' : 'bkg-primary'}  text-white`}>Azioni</th>
                    {!props.isLastGame && <th className={`py-2 px-2 text-start bkg-primary text-white`}  style={{width: '20%'}}> </th> }
                </tr>
                </thead>
                <tbody className={'text-start'}>
                {props.stats && props.stats.length > 0 ? props.stats.slice(startIndex, endIndex).map((i) => {
                    // let date = new Date(i.birthday).toISOString()
                    let today = new Date().toISOString()
                    let dates = new Date(i.date_time).toISOString()

                    return (
                        <tr className={'border-bottom-1'} key={i.id}>
                            <td data-label={'title'}>
                                {i.title}
                            </td>

                            <td data-label={'location'}>{i.location}</td>

                            <td data-label={'data'}>{formatDataIt(new Date(i.date_time))}</td>
                            {props.isLastGame && <td data-label={'risultato'}>
                                <Link to={'/teams/team/' + props.id + '/modifica-partita/' + i.id}>
                                    {i.score_description ? i.score_description : 'Aggiungi risultato'}</Link>
                            </td>}
                            <td >

                                {dates >= today &&
                                    <><Link to={'/teams/team/' + props.id + '/modifica-partita/' + i.id}><CiEdit
                                        className={'fs-4 mx-2 '}></CiEdit></Link>
                                        <Link to={''} data-id={i.id} onClick={(e) => {
                                            setShowConfirm(true)
                                            setGameId(e.currentTarget.getAttribute('data-id'))
                                            setActionMessage('Sei sicuro di voler eliminare la partita?')
                                        }}><RiDeleteBinLine className={'fs-4 mx-2 text-danger'}/></Link>
                                        <Link to={''} data-id={i.id} title={'Lista convocati'} onClick={(e) => {
                                            const gameId = i.id
                                            downloadConvocations(gameId, {
                                                setMessage: props.setMessage,
                                                setIsLoading: props.setIsLoading,
                                                setErrors: props.setErrors
                                            })
                                        }}><GoDownload className={'fs-4 mx-2'}/></Link>

                                    </>
                                }
                            </td>
                            {dates >= today && <td className={'text-end'}>

                                <Link className={'ms-auto'}
                                      to={'/teams/team/' + props.id + '/dettaglio-partita/' + i.id}><Button
                                    className={'btn bkg-second btn-sm border-0'}
                                    title={'Mostra'}> Gestisci convocazioni </Button></Link>
                            </td> }

                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5 className={'italic fw-normal text-grey'}><i>Nessuna gara trovata</i></h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            {props.stats && props.stats.length > itemsPerPage && (

                <CustomPagination
                    totalItems={props.stats.length}
                    currentPage={currentPage}
                    itemsPerPage={itemsPerPage}
                    onPageChange={handlePageChange}
                    maxPagesToShow={8}
                />

            )}
            <ConfirmationModal show={showConfirm} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>


        </>
    )

}

export default GamesTable