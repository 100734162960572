import {Alert, Button, Col, Form, Row} from "react-bootstrap";
import CustomColorPicker from "../../Layout/Elements/ColorPicker";
import React, {useEffect, useState} from "react";
import {retrieveSeasons} from "../../../Api/Teams/TeamsFetch";
import {retrieveCoachesList, retrieveSportsList} from "../../../Api/Associations/AssociationsFetch";
import {emptyError, handleErrors} from "../../Errors";

const TeamAddForm = (props) => {
    const [color, setColor] = useState();
    const [showColorPicker, setShowColorpicker] = useState(false)
    const [sportsList, setSportsList] = useState([])
    const [coachesList, setCoachesList] = useState([])
    const [seasons, setSeasons] = useState([])

    useEffect(() => {
        if (props.errors) {
            handleErrors(props.errors)
        }
        console.log('errors',props.errors, 'general', props.errors)
    }, [props.errors])


    useEffect(() => {
        console.log(props.seasons)
    }, [props.seasons])

    useEffect(() => {
        retrieveSeasons({setSeasons, setErrors:props.setErrors})
            retrieveSportsList({setSportsList})
            retrieveCoachesList({setCoachesList})


    }, [])
    useEffect(() => {
        console.log(seasons)
    }, [seasons])
    const handleSend = (e) => {
        e.preventDefault();
        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')
        const formData = {};
        for (let i of document.querySelectorAll('input, select, textarea')) {
            const name = i.getAttribute('name');
            const value = i.value;
            formData[name] = value;
        }
        if (!emptyfields) {
            props.setNewTeam(formData);
        }
    };
    const handleUpdate = (e) => {
        e.preventDefault()
        props.setUpdateTeam((prevValue) => ({
                ...prevValue, 'color': color, 'members': [props.selectedMembers]
            })
        );

        for (let i of document.querySelectorAll('input, select, textarea')) {
            const name = i.getAttribute('name');
            const value = i.value
            props.setUpdateTeam((prevValue) =>
                ({
                    ...prevValue, [name]: value
                })
            )
        }

        const members = props.selectedMembers.map(i => {
            return {
                'profile_id': i.value
            }
        })
        props.setUpdateTeam(prevState => ({
            ...prevState,
            'members': members
        }))


    }
    const handleChoose = () => {
        setShowColorpicker(true)
    }
    const handleSelectColor = () => {
        document.querySelector('.choosen-color').style.backgroundColor = color;
        props.setAdd((prevValue) => ({
                ...prevValue, color: color
            })
        );
        setShowColorpicker(false)
    }

    return (
        <>
            <Form>
                {props.message && <Alert variant={'success'} onClick={() => {props.setMessage(null) }} dismissible> {props.message} </Alert>}
                {props.errors && typeof props.errors === 'string' && <Alert variant={'danger'} onClick={() => {props.setErrors(null) }} dismissible> {props.errors} </Alert>}
                {!props.errors && props.generalError && typeof props.generalError === 'string' && <Alert variant={'danger'} onClick={() => {props.setGeneralError(null) }} dismissible> {props.generalError} </Alert>}
                <Row className={'text-start align-items-end'}>
                    <Col md={4} className={'d-flex align-items-center mt-4 mb-2 align-self-center position-relative'}>
                        <span onClick={handleChoose}
                              className={'d-inline-block border-solid border-1 choosen-color border-dark me-2'}
                              style={{
                                  width: '30px',
                                  height: '30px',
                                  borderRadius: '100%',
                                  border: '1px solid black ',
                                  backgroundColor: props.teamDetail?.color ?? ''
                              }}></span>
                        <span className={'d-inline-block'}>Scegli il colore per il team</span>
                        {showColorPicker && <div className={'position-absolute '} style={{left: '100px', zIndex: 9999}}>
                            <CustomColorPicker color={color} setColor={setColor}/>
                            <Button className={'mt-2 bkg-second border-0 py-2'}  onClick={handleSelectColor}> <p className={'mb-0 text-decoration-none d-block w-100 text-white'}>
                                Seleziona
                            </p></Button>
                        </div>
                        }

                    </Col>
                </Row>
                <Row className={'text-start align-items-end'}>
                    <Col md={4}>
                        <Form.Group className={'position-relative'}>
                            <Form.Label className={'small'}>Nome Team</Form.Label>
                            <Form.Control className={'mb-2'} name={'name'} type={'text'}
                                          placeholder={'Nome Team'} required={true}
                                          defaultValue={props.teamDetail?.name ?? ''}></Form.Control>
                        </Form.Group>
                    </Col>
                    <Col md={4}>
                        <Form.Label htmlFor={'coach_id'} className={'text-start small'}>Coach</Form.Label>
                        <Form.Select className={'mb-2'} name={'coach_id'}
                                     placeholder={'Coach'}>
                            <option value={''}>Seleziona</option>
                            {coachesList && coachesList.map(coach => {
                                return (
                                    <option
                                        selected={props.teamDetail && props.teamDetail.coach_id === coach.id ? 'selected' : ''}
                                        key={coach.id} value={coach.id}>{coach.full_name}</option>
                                )
                            })}
                        </Form.Select>
                    </Col>
                    <Col md={4}>
                       <Form.Group className={'position-relative'}>
                           <Form.Label htmlFor={'sport_id'} className={'text-start small'}>Sport</Form.Label>
                           <Form.Select
                               className={'mb-2'}
                               name={'sport_id'}
                               required={true}
                               onChange={(e) => {
                                   const newSportId = e.target.value;
                                   // Update the state accordingly
                                   if (props.setTeamDetail) {
                                       props.setTeamDetail((prevTeamDetail) => ({
                                           ...prevTeamDetail,
                                           sport_id: newSportId,
                                       }));
                                   }
                               }}
                               placeholder={'Sport'}
                           >
                               <option value={''}>Seleziona</option>
                               {sportsList && sportsList.map(sport => (
                                   <option
                                       selected={props.teamDetail && props.teamDetail.sport_id === sport.id ? 'selected' : ''}
                                       key={sport.id} value={sport.id}>{sport.name}</option>
                               ))}
                           </Form.Select>
                       </Form.Group>

                    </Col>
                    <Col>
                        <Form.Label htmlFor={'season_id'} className={'text-start small'}>Stagione</Form.Label>
                        <Form.Select
                        className={'mb-2'}
                        name={'season_id'}

                        placeholder={'Stagione'}
                    >
                        <option value={''}>Seleziona</option>
                        {seasons && seasons.map(season => {
                            // console.log(season.name,  props.teamDetail.season_name)
                            return (
                                <option
                                    selected={props.teamDetail && props.teamDetail.season_name == season.name ? 'selected' : ''}
                                    key={season.id} value={season.id}>{season.name}</option>
                            )

                        })}
                    </Form.Select></Col>
                    <Col md={12}>
                        <Form.Label className={'small mb-0'}>Note</Form.Label>
                        <Form.Control className={'mb-2'} name={'description'} as="textarea"
                                      placeholder={'Descrizione'}
                                      defaultValue={props.teamDetail?.description ?? ''}></Form.Control>
                    </Col>
                </Row>
               {/* <Row>
                    <Form.Label className={'small text-start mb-0'}>Soci</Form.Label>
                    <AddMembersForm selectedMembers={props.selectedMembers} teamDetail={props.teamDetail}
                                    setSelectedMembers={props.setSelectedMembers}/></Row>
*/}
                <Button className={'bkg-second border-0 mt-4'}
                        onClick={props.teamDetail ? handleUpdate : handleSend}>{props.teamDetail ? 'Aggiorna team' : 'Aggiungi Team'}</Button>
            </Form>
        </>
    )
}

export default TeamAddForm