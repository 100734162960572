import {Button, Col, Row, Tab, Tabs} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {
    deleteContact,
    deletePlace,
    editPlaces,
    retrieveContactsType,
    retrievePlacesType
} from "../../Api/Settings/SettingsFetch";
import {ConfirmationModal} from "../Modals/ConfirmationModal";
import ModalAddNewContact from "../Modals/Settings/ModalAddNewContact";
import ContactsTable from "./ContactsTable";
import PlacesTable from "./PlacesTable";


const AssociationContacts = (props) => {


    const [updatedPlaces, setUpdatedPlaces] = useState()
    const [placeId, setPlaceId] = useState()
    const [contactId, setContactId] = useState()
    const [placeToEdit, setPlaceToEdit] = useState()
    const [showConfirm, setShowConfirm] = useState()
    const [update, setUpdate] = useState(false)
    const [showAddContact, setShowAddContact] = useState(false)
    const [placesTypes, setPlacesTypes] = useState()
    const [contactsTypes, setContactsTypes] = useState()
    const [modalType, setModalType] = useState()
    useEffect(() => {
        retrievePlacesType({setIsLoading: props.setIsLoading, setErrors: props.setErrors, setPlacesTypes})
        retrieveContactsType({setIsLoading: props.setIsLoading, setErrors: props.setErrors, setContactsTypes})

    }, [])
    console.log(placesTypes, contactsTypes)
    /*const handleEdit = (e) => {
        console.log('Mannaggia Al clero')
        e.preventDefault()
        const id = e.currentTarget.getAttribute('data-id')
        console.log(id)
        setPlaceToEdit(id)
       for (let i of document.querySelectorAll('.row-'+id +' input, .row-'+id +'  select')){
           const name = i.getAttribute('name')

           const value = i.value;
           console.log(name, value)
           const updatedValue = {[name]: value}
           console.log(updatedValue)
           setUpdatedPlaces(info => ({
                   ...info, ...updatedValue
               })
           )
        }

    }*/
    const handleAdd = (e) => {
        console.log('Mannaggia Al clero')
        e.preventDefault()
        const id = e.currentTarget.getAttribute('data-id')
        console.log(id)
        setPlaceToEdit(id)
        for (let i of document.querySelectorAll('.row-' + id + ' input, .row-' + id + '  select')) {
            const name = i.getAttribute('name')

            const value = i.value;
            console.log(name, value)
            const updatedValue = {[name]: value}
            console.log(updatedValue)
            setUpdatedPlaces(info => ({
                    ...info, ...updatedValue
                })
            )
        }
    }
    const handleConfirm = () => {
        console.log(placeId)
        if (placeId !== undefined) {
            deletePlace({
                placeId, setMessage: props.setMessage,
                setErrors: props.setErrors,
                update,
                setUpdate,
                setIsLoading: props.setIsLoading,
                setShowConfirm
            })
        }
        if (contactId !== undefined) {
            deleteContact({
                contactId, setMessage: props.setMessage,
                setErrors: props.setErrors,
                update,
                setUpdate,
                setIsLoading: props.setIsLoading,
                setShowConfirm
            })
        }
    }
    useEffect(() => {

    }, [placeId])


    useEffect(() => {
        if (updatedPlaces && placeToEdit) {
            editPlaces({
                placeId: placeToEdit,
                updatedPlaces,
                setMessage: props.setMessage,
                setErrors: props.setErrors,
                update: props.update,
                setUpdate: props.setUpdate
            })
        }
    }, [updatedPlaces])
    console.log()
    return (<>
        <Row className={'ms-0 ps-0 mx-0'}>
            <Col className={'card py-3 border-0 shadow mt-3 text-start'}>
                <Row className={'mx-0'}>
                    <Col><h2 className={'text-start'}>Contatti e indirizzi associazione</h2></Col>
                </Row>
                <Row className={'row mx-0'} readonly={true}>
                    <Tabs
                        defaultActiveKey="contatti"
                        id="uncontrolled-tab-example"
                        className="mb-3"
                    >
                        <Tab eventKey="contatti" title="Contatti">
                            <Row>

                                <Col className={'text-end'}> <Button className={'btn bkg-second border-0 btn-sm align-self-end'}
                                              onClick={() => {
                                                  setModalType('contact')
                                                  setShowAddContact(true)
                                              }}> Nuovo contatto </Button></Col>
                                <Col className={'col-md-12'}>
                                    <ContactsTable setErrors={props.setErrors} setIsLoading={props.setIsLoading}
                                                   setUpdate={setUpdate} update={update} setShowConfirm={setShowConfirm}
                                                   setContactId={setContactId}/>

                                </Col>
                            </Row>


                        </Tab>

                        <Tab eventKey="indirizzi" title="Indirizzi">
                            <Row>
                                <Col className={'text-end'}>  <Button className={'btn bkg-second border-0 btn-sm align-self-end'} onClick={() => {
                                    setModalType('places')
                                    setShowAddContact(true)
                                }}> Nuovo Indirizzo </Button></Col>

                            <Col md={12}>
                                <PlacesTable setErrors={props.setErrors} setIsLoading={props.setIsLoading}
                                             setUpdate={setUpdate} update={update} setShowConfirm={setShowConfirm}
                                             setPlaceId={setPlaceId}/>

                            </Col>
                            </Row>
                        </Tab>
                    </Tabs>


                </Row>
            </Col>
            <ConfirmationModal show={showConfirm} handleClose={() => {
                setShowConfirm(false)
            }}
                               handleConfirm={handleConfirm}
                               actionMessage={`Vuoi procedere con l'eliminazione?`}></ConfirmationModal>
            <ModalAddNewContact show={showAddContact} setShow={setShowAddContact} placesTypes={placesTypes}
                                contactsTypes={contactsTypes}
                                handleClose={() => {
                                    setShowAddContact(false)
                                }} setErrors={props.setErrors} setIsLoading={props.setIsLoading} setUpdate={setUpdate}
                                update={update} modalType={modalType}/>
        </Row>

    </>)
}

export default AssociationContacts