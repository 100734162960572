import {Form, Row, Table, Alert, Badge, Col} from "react-bootstrap";
import {AiOutlineSortAscending, AiOutlineSortDescending, AiOutlineUser} from 'react-icons/ai'
import React, {useEffect, useState} from "react";
import TablePagination from "../Layout/Elements/TablePagination";
import {Link} from "react-router-dom";
import {MdOutlineMerge, MdOutlineSafetyDivider, MdOutlineTableRows, MdViewCompact} from "react-icons/md";
import {BiShow} from "react-icons/bi";

import {RiContactsLine, RiUserSettingsLine} from "react-icons/ri";
import {MdOutlinePersonAddAlt, MdOutlinePersonAddDisabled} from "react-icons/md";
import {formatDataIt} from "../../Utils/DateFormat";
import {
    associateMember,
    retrieveMemberContacts,
    dissociateMember, addMemberRoles
} from "../../Api/Members/MembersFetch";
import {ConfirmationModal} from "../Modals/ConfirmationModal";
import ImgPlaceholder from "../../assets/img/placeholder.png";
import RolesModal from "../Modals/Members/RolesModal";
import './../../assets/css/MembersTable.css'
import IconTooltip from "../Layout/Elements/IconsTooltip";
import {FaSortAmountDownAlt} from "react-icons/fa";
import {PiTShirt} from "react-icons/pi";
import {SlBadge} from "react-icons/sl";

const MembersTable = (props) => {
    const [showConfirm, setShowConfirm] = useState(false);
    const [contact, setContact] = useState()
    const setMemberContact = props.setMembersContact
    const [functionTodo, setFunctionTodo] = useState()
    const [actionMessage, setActionMessage] = useState()

    const [selectedRoles, setSelectedRoles] = useState([]);
    const [show, setShow] = useState()
    const [action, setAction] = useState()
    const [userId, setUserId] = useState()
    const [visualization, setVisualization] = useState(localStorage.getItem('visualization') || 'default')
    const [sort, setSort] = useState('desc');
    useEffect(() => {

        setVisualization(localStorage.getItem('visualization') || 'default')
    }, [])

    useEffect(() => {
        if (visualization === 'compatta') {
            document.querySelector('.members-table').classList.add('compact')
            props.setPerPage('per_page=30');
            localStorage.setItem('visualization', 'compatta')
        } else if (visualization === 'default') {
            document.querySelector('.members-table').classList.remove('compact')
            props.setPerPage('per_page=10');
            localStorage.setItem('visualization', 'default')
        }
    }, [visualization])
    const handleSort = (e) => {

        /*creo una variabile per evitare problemi nell'aggiornamento dello stato */
        const singleSort = sort
        const key = e.currentTarget.getAttribute('data-name');

        if (sort === 'asc') {
            setSort('desc')
        } else {
            setSort('asc')
        }
        console.log(sort)
        props.setFilterSort({[key]: singleSort})

    }
    const handleOnClick = (e) => {
        const contactID = e.currentTarget.getAttribute('id')

        let pageUrl = 'profile/contact/' + contactID
        if (contactID) {
            retrieveMemberContacts({pageUrl, setMemberContact})
            props.setShow(true)
        }
    }
    console.log(props.perPage)
    const handleAssociation = (contact) => {
        let contactID = contact

        const setMessage = props.setMessage
        const setErrors = props.setErrors
        console.log(contact)
        associateMember({
            contactID,
            setShowConfirm,
            setMessage,
            setErrors: props.setErrors,
            setUpdateRetrieve: props.setUpdateRetrieve,
            setIsLoading: props.setIsLoading
        })
        setContact()
        setShowConfirm(false)
    }
    const handleDissociation = (contact) => {
        let contactID = contact

        const setMessage = props.setMessage
        const setErrors = props.setErrors

        dissociateMember({
            contactID,
            setShowConfirm,
            setMessage,
            setErrors: props.setErrors,
            setUpdateRetrieve: props.setUpdateRetrieve
        })
        setContact()
        setShowConfirm(false)
    }

    const handleClose = () => {
        setShowConfirm(false)
    }
    const handleConfirm = (e) => {
        if (functionTodo === 'associate') {
            handleAssociation(contact)
        } else {
            handleDissociation(contact)
        }

    }

    const handleSetRolesModal = (e) => {
        const user = e.currentTarget.getAttribute('data-id');
        const dataAction = e.currentTarget.getAttribute('data-action');
        setShow(true);
        setAction(dataAction);
        setUserId(user);
    }

    return (
        <>
            <Row className={'mt-2'}>
                <Col className={'text-end'}>
                    <p className={'mb-0'} style={{fontSize: '12px'}}>

                        <Link to={''} className={' text-decoration-none'} onClick={() => {
                            if (visualization === 'default') {
                                setVisualization('compatta')
                            } else {
                                setVisualization('default')
                            }
                        }
                        }
                              title={`${visualization === 'compatta' ? 'Visualizzazione normale' : 'Visualizzazione compatta'}`}> {visualization === 'compatta' ?
                            <> Attiva modalità standard <MdOutlineTableRows size={20}/></> : <> Attiva modalità
                                compatta <MdViewCompact size={20}/> </>}</Link>
                    </p></Col>
            </Row>

            <Table className={'table-responsive members-table'}>
                <thead className={'bkg-primary text-white py-2'}>
                <tr>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort}
                        data-name={'sorts[last_name]'}>
                        Nome {
                        props.filterSort && props.filterSort['sorts[last_name]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>Teams</th>
                    <th className='py-2 px-2 text-start bkg-primary text-white' onClick={handleSort}
                        data-name={'sorts[birthday]'}>
                        Data di Nascita {
                        props.filterSort && props.filterSort['sorts[birthday]'] === 'asc' ? (
                            <AiOutlineSortAscending size={20}
                                                    className={'text-white fs-3'}></AiOutlineSortAscending>) : (
                            <AiOutlineSortDescending size={20}
                                                     className={'text-white'}></AiOutlineSortDescending>)}
                    </th>

                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Stato socio
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'>
                        Ruolo
                    </th>
                    <th className='py-2 px-2 text-start bkg-primary text-white'></th>
                    <th className='py-2 px-2 text-end bkg-primary text-white '>
                        Azioni
                    </th>
                </tr>
                </thead>
                <tbody className={'text-start'}>
                {props.membersList && props.membersList.length > 0 ? props.membersList.map((i) => {
                    // let date = new Date(i.birthday).toISOString()
                    return (
                        <tr className={'border-bottom-1'} key={i.id}>
                            <td>
                                <div className={'d-flex align-items-center'}>
                                    <p className={'mb-0 d-inline-block'} style={
                                        {
                                            width: visualization === 'compatta' ? '20px' : '40px',
                                            height: visualization === 'compatta' ? '20px' : '40px',
                                            borderRadius: '100%',
                                            backgroundImage: `url(${i.image || ImgPlaceholder})`,
                                            backgroundSize: 'cover',
                                            backgroundPosition: 'center',
                                            backgroundRepeat: 'no-repeat'
                                        }}></p>
                                    {/*<img src={i.image || ImgPlaceholder} className={'img-fluid rounded-5'}
                                     style={{width: '40px'}}/>*/}&nbsp;&nbsp; <Link to={`/soci/dettaglio-socio/${i.id}`}
                                                                                    className={'text-decoration-none fw-bold'}>{i.first_name} {i.last_name}</Link>
                                </div>
                            </td>
                            <td className={'px-2'}
                                style={{maxWidth: '80px'}}>{i.teams && i.teams.length > 0 && i.teams.map((team, index) => {
                                return <span><IconTooltip title={team.name} className={'m-0 p-0'}>
                                    <span key={team.id}
                                          className={`text-smaller d-inline-block my-2 my-md-0  px-1 text-white rounded-5 px-2 py-1 shadow`}
                                          style={{
                                              marginRight: '-10px',
                                              background: team.color ? team.color : "var(--secondary)"
                                          }}> {team.name.slice(0, 2)}</span>
                                              </IconTooltip>
                                              </span>
                            })}</td>
                            <td>{i.birthday ? formatDataIt(new Date(i.birthday)) : ''}</td>

                            <td>{i.is_partner === false ?
                                (
                                    <Badge key={'badge' + i.id} bg="warning">Non socio</Badge>
                                ) :
                                (<Badge key={'badge' + i.id} bg="success">Socio</Badge>
                                )}
                            < /td>
                            <td>{i.roles && i.roles.length > 0 && i.roles.map(role => {
                                return (
                                    <span><IconTooltip title={role.name} className={'m-0  p-0'}>   <span
                                        key={'span-' + role.id}
                                        className={'d-inline-block mx-1 my-2 my-md-0 bkg-second px-1 text-white rounded-5 px-1 py-1 shadow text-smaller'}>{role.name.slice(0, 2)}</span>
                                    </IconTooltip> </span>
                                )
                            })}</td>
                            <td><IconTooltip title={`Kit - ${i.has_equipment === true ? 'Si' : 'No'}`} className={'m-0  p-0 cursor-default'}> <PiTShirt
                                className={`${i.has_equipment === true ? 'text-success' : 'text-danger'}`}
                                size={20}/></IconTooltip>
                                <IconTooltip title={`Membership - ${i.has_membship === true ? 'Si' : 'No'}`} className={'m-0 me-2 p-0'}> <SlBadge size={20}
                                                                                                       className={`${i.has_membship === true ? 'text-success' : 'text-danger'}`}/>
                                </IconTooltip></td>
                            <td className={'azioni text-end'}>
                                {i.is_partner === false ?
                                    <MdOutlineMerge className={'fs-4 mx-2 text-success'} title={'Associa'}
                                                    data-id={i.id} onClick={(e) => {
                                        setShowConfirm(true)
                                        setContact(e.currentTarget.getAttribute('data-id'))
                                        setActionMessage('Sei sicuro di voler associare il profilo?')
                                        setFunctionTodo('associate')
                                    }}/>
                                    :
                                    <MdOutlineSafetyDivider data-id={i.id} onClick={(e) => {
                                        setShowConfirm(true)
                                        setContact(e.currentTarget.getAttribute('data-id'))
                                        setActionMessage('Sei sicuro di voler dissociare il profilo?')
                                        setFunctionTodo('dissociate')
                                    }} className={'fs-4 mx-2 text-danger'} title={'Dissocia'}/>
                                }

                                <Link
                                    to={`/soci/dettaglio-socio/${i.id}`}><BiShow className={'fs-5 mx-2'}
                                                                                 title={'Mostra'}/>
                                </Link>
                                <RiContactsLine className={'fs-5 mx-2'} onClick={handleOnClick} id={i.id}
                                                title={'Contatti'}/>
                                <MdOutlinePersonAddAlt className={'fs-5 mx-2'} data-id={i.id} data-action={'add-roles'}
                                                       title={'Aggiungi Ruolo'} onClick={(e) => {
                                    setSelectedRoles((prevValue) => {
                                        if (i.roles && i.roles.length > 0) {
                                            return [
                                                ...prevValue,
                                                ...i.roles.map((role) => ({
                                                    label: role.name,
                                                    value: role.id,
                                                    key: role.id,
                                                    index: prevValue.length,
                                                })),
                                            ];
                                        }
                                        return prevValue;
                                    });
                                    handleSetRolesModal(e)
                                }}
                                />
                                {i.roles && i.roles.length > 0 &&
                                    <MdOutlinePersonAddDisabled className={'fs-5 mx-2'} data-id={i.id}
                                                                data-action={'remove-roles'}
                                                                onClick={(e) => {
                                                                    setSelectedRoles((prevValue) => {
                                                                        if (i.roles && i.roles.length > 0) {
                                                                            return [
                                                                                ...prevValue,
                                                                                ...i.roles.map((role) => ({
                                                                                    label: role.name,
                                                                                    value: role.id,
                                                                                    key: role.id,
                                                                                    index: prevValue.length,
                                                                                })),
                                                                            ];
                                                                        }
                                                                        return prevValue;
                                                                    });
                                                                    handleSetRolesModal(e)
                                                                }}
                                                                title={'Rimuovi Ruolo'}
                                    />}

                            </td>
                        </tr>)
                }) : (
                    <tr className={'border-bottom-1 text-center'}>
                        <td colSpan={12}>

                            <h5>Nessun socio trovato</h5>

                        </td>
                    </tr>
                )
                }


                </tbody>
            </Table>
            <TablePagination pagination={props.pagination} setPerPage={props.setPerPage}
                             setPageUrl={props.setPageUrl} records={props.records}
                             handleExportTable={props.handleExportTable}
                             perPage={props.perPage}></TablePagination>

            <ConfirmationModal show={showConfirm} handleClose={handleClose}
                               handleConfirm={handleConfirm} actionMessage={actionMessage}></ConfirmationModal>
            <RolesModal show={show} setShow={setShow} action={action} userId={userId}
                        selectedRoles={selectedRoles} setSelectedRoles={setSelectedRoles} setAction={setAction}
                        setUserId={setUserId} setUpdateMembersList={props.setUpdateMembersList}
                        setIsLoading={props.setIsLoading}/>


        </>
    )

}

export default MembersTable