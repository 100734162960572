import {BsBell} from "react-icons/bs";
import React, {useEffect, useRef, useState} from "react";
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
import {Link} from "react-router-dom";
import {MdMarkUnreadChatAlt} from "react-icons/md";
import {getNotificationList, readAllNotification, readNotification} from "../../Api/Notifications/NotificationFetch";

import {formatDataIt} from "../../Utils/DateFormat";
import {Button} from "react-bootstrap";

const Notifications = () => {

    const [removingNotifications, setRemovingNotifications] = useState([]);
    const [dataNotifications, setDataNotifications] = useState([]);
    const popoverRef = useRef()
    const triggerRef = useRef()
    const [showPopover, setShowPopover] = useState(false);
    useEffect(() => {

    }, [dataNotifications])
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (
                popoverRef.current &&
                !popoverRef.current.contains(event.target) &&
                triggerRef.current &&
                !triggerRef.current.contains(event.target)
            ) {
                setShowPopover(false); // Chiudi il popover
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const togglePopover = () => {
        setShowPopover((prev) => !prev); // Alterna visibilità popover
    };
    const [errors, setErrors] = useState([]);
    const popover = <Popover id="popover-basic">

        <Popover.Body ref={popoverRef} style={{maxHeight: '250px',borderWidth: 0, borderRadius: '10px', boxShadow: 0}}
                      className={`p-0 bg-white overflow-x-hidden  ${dataNotifications && dataNotifications.length > 6 && 'h-[300px] '}`}>
            {dataNotifications &&
                <div

                    className={` py-1 px-3 `}
                    style={{transition: 'transform 0.5s, opacity 0.5s'}}>
                    <Link to={'#'} onClick={() => handleMarkAllRead()}
                          className="w-100">
                        <p className="d-flex flex-wrap justify-content-between align-items-center mb-0">

                            <div className={'small'}> Segna tutte  come lette</div>
                           <div className={'text-end'}> <MdMarkUnreadChatAlt
                               className="text-xl"
                               title="Segna come letta"

                           /></div>

                        </p></Link>

                </div>}


            {dataNotifications && dataNotifications.length > 0 ? dataNotifications.map(i => {
                    const isRemoving = removingNotifications.includes(i.id);

                    return (
                        <div
                            key={i.id}
                            className={` d-flex justify-content-between align-items-center rounded-lg py-2 px-3 transition  border-2 border-bottom border-solid
                                `}
                            style={{backgroundColor: i.read_at === null ? '#f1e7e7' : '' }}>
                            <div className={`col-10`}>
                                <p className="flex flex-wrap items-center mb-0 text-smaller">
                                    {i.created_at &&
                                        <span className="me-2">{formatDataIt(new Date(i.created_at))}</span>}
                                    {i.data?.info?.hours && <span>{i.data?.info?.hours}H</span>}

                                </p>
                                <p className={`${i.read_at === null ? 'fw-bold' : ''} mb-0`}>{i.data?.title}</p>
                                <p className="mb-0 small">{i.data?.text}</p>

                            </div>
                            <div className={'text-end'}>
                                {i.read_at === null &&  <MdMarkUnreadChatAlt
                                    className="text-xl"
                                    title="Segna come letta"
                                    onClick={() => handleMarkAsRead(i.id)}
                                /> }
                            </div>
                        </div>
                    );
                })

                : 'Non sono presenti notifiche'}

        </Popover.Body>

    </Popover>

    useEffect(() => {
        getNotificationList({setErrors, setDataNotifications})
    }, []);

    const handleMarkAsRead = (id) => {
        setRemovingNotifications((prev) => [...prev, id]);
        console.log(id)
        readNotification({notificationID: id, setErrors, setDataNotifications})
    };
    const handleMarkAllRead = () => {

        // Effettua la chiamata per segnare la notifica come letta
        readAllNotification({setErrors})
    };

    return (
        <>
            <OverlayTrigger trigger="click" placement="bottom" overlay={popover} className={'position-relative'}  show={showPopover}>
                <Button className="w-full h-auto inline bg-transparent p-0 border-0"  ref={triggerRef} onClick={togglePopover} >
                    <div className=" rounded-5 px-2 py-1 position-relative mx-border border-solid border-2 ">
                        <BsBell className="fs-5 text-white" aria-hidden="true"/>
                        <span style={{
                            bottom: '-8px',
                            right: '-10px'
                        }}
                              className={`position-absolute 
                              ${dataNotifications && dataNotifications.length > 0  ? 'bg-warning' : 'bg-gray'} rounded-5 px-2 text-white text-smaller fw-bold d-inline-block`}>
                        {dataNotifications
                            ? dataNotifications.filter(i => i.read_at === null).length
                            : 0}
                    </span>
                    </div>
                </Button>
            </OverlayTrigger>

        </>
    );
};

export default Notifications;
