import {useEffect, useState} from "react";
import {Col, Row} from "react-bootstrap";

const AssociationsCode = (props) => {
    const [image, setImage ] = useState();
    console.log(props.associationInfo && props.associationInfo.qr_url)
    useEffect(() => {
        setImage(props.associationInfo && props.associationInfo.qr_url)
    }, [ props.associationInfo])


    return (
        <Row className={'card py-3 border-0 shadow mt-3 text-start'}>
            <h2 className={'text-start'}>Codici associazione</h2>
            <Col md={12} className={'text-center border-1 border-bottom pb-3'}>
               <b>Codice Invito:</b>  <h5 className={'text-white fs-2 bkg-second py-2'}>{props.associationInfo && props.associationInfo.invite_code}</h5>
            </Col>
              <Col md={12} className={'text-center mt-2'}>
                  <p><b>Qr Code</b></p>
                  <img src={image} className={'img-fluid '}/>
              </Col>
          </Row>

    )
}

export default AssociationsCode