import axiosInstance from "../AxiosInstance";

export const retrievePaymentsList = (props) => {
    props.setIsLoading(true)
    let url = props.pageUrl + ((props.selectedSeason !== undefined && props.selectedSeason !== null) ? 'season_id=' + props.selectedSeason : '');
    console.log('url', url)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Payments Plan', response)

        props.setPaymentsList(response.data.data)


        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const showPaymentPlan = (props) => {
    props.setIsLoading(true)
    const symbol = props.pageUrl.includes('?') ? '&' : '?';
    /*Se uso la chiamata dalla modal per avere i profili associati, allora uso selectedPlan */
    const id = props.selectedPayment ? props.selectedPayment?.id : props.selectedPlan
    console.log(id)
    let url = `${'/payments/plan/' + id}`

    const query = props.search ? Object.keys(props.search)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
        .join('&') : null;

    /* const filter = filterSort ? Object.keys(filterSort)
         .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
         .join('&') : null;*/

    if (query != null) url += '&' + query;
    // if(filter !== null) url += '&' + filter;

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Pagamenti', response)

        props.setPaymentDetail(response.data.data)
        console.log(response.data.data)

        props.setIsLoading(false)
        props?.setShowProfiles(true)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const addInstallment = (props) => {
    props.setIsLoading(true)
    const symbol = props.pageUrl.includes('?') ? '&' : '?';
    let url = `${'/payments/plan/' + props.selectedPayment?.id + '/installment'}`

    const query = props.search ? Object.keys(props.search)
        .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
        .join('&') : null;

    /* const filter = filterSort ? Object.keys(filterSort)
         .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(filterSort[key]))
         .join('&') : null;*/

    if (query != null) url += '&' + query;
    // if(filter !== null) url += '&' + filter;

    axiosInstance.post(url, props.newInstallment).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta aggiungi rata', response)
        props.setShowInstallmentModal(false)
        props.setUpdate(!props.update)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const removeInstallment = (props) => {
    props.setIsLoading(true)
    let url = `${'/payments/plan/' + props.selectedPlan + '/installment/' + props.selectedInstallment}`

    axiosInstance.delete(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta elimina rata', response)
        props.setShowConfirm(false)
        props.setUpdate(!props.update)
        props.setMessage && props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setSelectedPlan()
        props.setSelectedInstallment()
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            props.setIsLoading(false)
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const createPlan = (props) => {
    console.log(props.newPlan)
    props.setIsLoading(true)
    let url = `${'/payments/plan/'}`

    axiosInstance.post(url, props.newPlan
    ).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta crea piano', response)
        props.setShowPlanModal(false)
        props.setUpdate(!props.update)
        props.setMessage && props.setMessage(response.data.message)
        props.setIsLoading(false)
        props.setNewPlan()
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            props.setIsLoading(false)
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deletePlan = (props) => {
    props.setIsLoading(true)
    let url = `${'/payments/plan/' + props.selectedPlan}`

    axiosInstance.delete(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta elimina piano', response)
        props.setShowConfirm(false)
        props.setUpdate(!props.update)
        props.setMessage && props.setMessage(response.data.message)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            props.setIsLoading(false)
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const listProfiles = (props) => {
    props.setIsLoading(true)

    let url = `${'/payments/plan/' + props.planId+'/listProfiles'}`

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Profili', response)

        props.setPlanMembers(response.data.data)


        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const syncPlanProfiles = (props) => {
    props.setIsLoading(true)

    let url = `${'/payments/plan/' + props.planId+'/profiles'}`
    axiosInstance.post(url, props.selectedProfile).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta syncProfiles', response)

        props.setUpdate(!props.update)
        props.setSelectedProfile()
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

/*Lista pagamenti per socio*/

export const retrieveMemberPaymentsList = (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/'+props.id+'/payments' + ((props.selectedSeason !== undefined && props.selectedSeason !== null) ? '?season_id=' + props.selectedSeason : '')}`



    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Pagamenti Socio', response)

        props.setPaymentsList(response.data.data)


        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            // setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

/*Overview socio pagamenti e pending*/
export const retrieveMemberPaymentsOverview= (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/'+props.id+'/balance'}`

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Payments Overview dettaglio socio', response)

        props.setPaymentsOverview(response.data.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props?.setErrors(error.response.data.message)
            props?.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

/*Incassa rata socio */

export const payInstallment = (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/' + props.id+'/installment/' + props.installmentId}`
    axiosInstance.post(url, {payment_date: props.paymentDate}).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta PAY iNSTALLMENT', response)

        props.setUpdate(!props.update)
        props.setShowConfirm()
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

/*Aggiungi pagamento singolo - per socio */

export const addNewPayment = (props) => {
    props.setIsLoading(true)
    console.log(props.addPayment)
    let url = `${'/profile/' + props.id+'/payment/'}`
    axiosInstance.post(url, props.addPayment).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Add new payment', response)

        props.setUpdate(!props.update)
        props.setShowAddPaymentModal()
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

/*Elimina incasso rata per singolo socio */
export const deletePayedInstallment = (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/' + props.id+'/payments/' + props.installmentId}`
    axiosInstance.delete(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta delete Payed iNSTALLMENT', response)

        props.setUpdate(!props.update)
        props.setShowConfirmDelete()
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const cancelPayment = (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/' + props.id+'/payments/' + props.installmentId+'/cancel'}`
    axiosInstance.get(url ).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Cancel Payment ', response)

        props.setUpdate(!props.update)
        props.setShowConfirmCancel()
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const editPayment = (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/' + props.id+'/payments/' + props.installmentId}`
    axiosInstance.put(url, props.editPaymentDetails ).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta edit Payment ', response)
        props.setIsLoading(false)
        props.setUpdate(!props.update)

        setTimeout(function () {
            props.setShowAddPaymentModal()
            props.setPaymentDetail()
            props.setInstallmentId()
            props.setIsEditPayment(false)
        }, 500)


    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const showPayment = (props) => {
    props.setIsLoading(true)

    let url = `${'/profile/' + props.id+'/payments/' + props.installmentId}`
    axiosInstance.get(url ).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Show Payment ', response)
        props.setUpdate(!props.update)
        props.setPaymentDetail(response.data.data)
        props.setIsLoading(false)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const searchPaymentReport = (props) => {

    let url = props.pageUrl
    console.log(url);
    const symbol = props.pageUrl.includes('?') ? '&' : '?';

    url = props.pageUrl + symbol + props.perPage

    if (props.search) {
        const query = props.search ? Object.keys(props.search)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
            .join('&') : null;

        if (query !== null) url += '&' + query;
    }
    if (props.filterSort) {
        const filter = props.filterSort ? Object.keys(props.filterSort)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.filterSort[key]))
            .join('&') : null;
        if (filter !== null) url += '&' + filter;
    }


    axiosInstance.post(url, props.search, props.filterType).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta searchPaymentReport', response)
        props.setPayments(response.data.data.data)
        props.setUpdate(!props.update)
        props.setPagination && props.setPagination(response.data.data.links)
        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors &&  props.setErrors(error.response.data.message)
            // setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors &&  props.setErrors(error)
            // setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrievePaymentsOverview = (props) => {
    props.setIsLoading(true)
    const symbol = props.pageUrl.includes('?') ? '&' : '?';
    let url = props.pageUrl
    url = props.pageUrl + symbol + props.perPage + ((props.selectedSeason !== undefined && props.selectedSeason !== null) ? '&season_id=' + props.selectedSeason : '')

    if (props.search) {
        const query = props.search ? Object.keys(props.search)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.search[key]))
            .join('&') : null;

        if (query !== null) url += '&' + query;
    }
    if (props.filterSort) {
        const filter = props.filterSort ? Object.keys(props.filterSort)
            .map(key => encodeURIComponent(key) + '=' + encodeURIComponent(props.filterSort[key]))
            .join('&') : null;
        if (filter !== null) url += '&' + filter;
    }

    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Pagamenti Overview', response)

        props.setPaymentsList(response.data.data.data)
        props.setPagination && props.setPagination(response.data.data.links)
        console.log(response.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrievePaymentsAssociationOverview = (props) => {
    props.setIsLoading(true)

    axiosInstance.get('/payments/report/overview/association').then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Pagamenti Overview Association', response.data.data)

        props.setOverviewAssocation(response.data.data)

        console.log(response.data)

        props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
           props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}