import {Col, Row} from "react-bootstrap";
import React from "react";
import {formatDataIt} from "../../../Utils/DateFormat";

const Challenges = (props) => {

    return (
        <Col className={'card border-0 shadow pb-2 py-2 mb-3 bgGradient text-white'} md={12}>
            <Row className={'align-items-center'}>
                <h5 key={'1'}
                    className={'fw-bold pt-3'}>Prossima gara in programma</h5>


                {props.teamDetail && props.teamDetail.next_game !== null ? (
                        <Row  className={'mx-0 align-items-center'}>

                                <>
                                    <Col md={12}>
                                        <p className={'ps-2 text-center mt-1 mb-0 fs-4'}>{props.teamDetail.next_game.title}</p>
                                        <p className={'ps-2 text-center mb-0'}>Data: { formatDataIt(new Date(props.teamDetail.next_game.date_time))}</p>
                                        <p className={'ps-2 text-center mb-0'}>Location: {props.teamDetail.next_game.location}</p>
                                    </Col>
                                </>

                        </Row>
                    )
                 : <Row className={'mx-0 align-items-center text-center'}><Col md={12}>Nessuna gara</Col></Row>}


                {/*<h6 key={'2'}
                    className={'fw-bold pt-3'}>Ultima gara giocata</h6>
                <Row key={'a'} className={'mx-0 align-items-center'}>
                    {props.teamDetail && props.teamDetail.stats.latest_played_games && props.teamDetail.stats.latest_played_games.length > 0 ? props.teamDetail.stats.latest_played_games.map((i, index) => {
                        return (

                            index === 0 &&
                            <Fragment key={i.id}>
                                <Col md={6} className={'text-start'}>


                                    <h2 style={{backgroundColor: generateRandomColors(1)}}
                                        className={'rounded-5 fs-4 d-inline py-1 px-3 text-white'}>
                                        {i.opponent && i.opponent?.charAt(0)}
                                    </h2>
                                    <p className={'d-inline-block ps-2'}>{index === 0 && i.opponent}</p>


                                </Col>
                                <Col md={6}>
                                    {index === 0 && formatDataIt(new Date(i.date_time))}
                                </Col>
                            </Fragment>


                        )
                    }) : <Row className={'mx-0 align-items-center text-center'}><Col md={12}>Nessuna gara</Col></Row>}
                </Row>*/}
            </Row>
        </Col>

    )
}

export default Challenges