import React, {useContext, useEffect, useLayoutEffect, useState} from "react";
import {Link, Outlet, useLocation, useNavigate} from "react-router-dom";
import {Col, Container, Row} from "react-bootstrap";
import Sidebar from "./Sidebar";
import Header from "./Header";
import {TbArrowBackUp} from "react-icons/tb";
import '../../assets/css/Icon.css'
import '../../assets/css/DateInput.css'

import UserSubmenuHolder from "./Elements/UserSubmenuHolder";
import Login from "../../Pages/Auth/Login";
import AuthContext from "../../Contexts/authContext";
import Profiles from "../../Pages/Profiles/Profiles";
import ResetPassword from "../../Pages/Auth/ResetPassword";
import Coach from "../../Pages/Coach";


const Layout = () => {
    const navigate = useNavigate();
    const {isAuthenticated, user, setUser, profile, userProfile, setUserProfile} = useContext(AuthContext)
    const location = useLocation();
    const [show, setShow] = useState(
        window.innerWidth < 768 ? "closed" : "open"
    );

    useEffect(() => {
        if (user) {
            console.log('user', user)
            console.log('selectedProfile', userProfile)
        }
    }, [user, userProfile])

    console.log(profile)
    useEffect(() => {
        if (user && user.profiles && user.profiles.length === 1) {
            const profile = user.profiles[0];
            if (profile.associations && profile.associations.length === 1) {
                const association = profile.associations[0];
                const userProfile = {
                    profile_name: profile.first_name,
                    profile_id: profile.profile_id,
                    association: association.association_id,
                    association_name: association.association_name,
                    email: profile.email,
                    season: association.current_season
                };
                localStorage.setItem('associationID', association.association_id);
                setUserProfile(userProfile);
            }
        }
    }, [user]);

    useEffect(() => {
        if (userProfile) {
            localStorage.setItem('selectedProfile', JSON.stringify(userProfile))
        }

        console.log(userProfile)
    }, [userProfile])

    useLayoutEffect(() => {
        setUser(JSON.parse(localStorage.getItem('userData')))
        console.log(user)
        if (userProfile === null && localStorage.hasOwnProperty('selectedProfile') != 'null') {
            setUserProfile(JSON.parse(localStorage.getItem('selectedProfile')))
        }
    }, [])
    const handleProfileSelection = (selectedProfile) => {
        // Aggiorna lo stato dell'utente con il profilo selezionato, se necessario
        setUserProfile(selectedProfile);

        console.log(selectedProfile)

    };


    return (
        <>
            {location.pathname === '/coach' && <Coach/>}
            {location.pathname === '/reset-password' ? (
                <ResetPassword/>
            ) : isAuthenticated && user ? (
                userProfile ? (
                    <Container className={'layoutContainer mw-100 mx-0 d-flex p-0 h-100'}>
                        <Header  show={show} setShow={setShow} />
                        <Sidebar user={user} show={show} setShow={setShow}/>
                        <div className="container-fluid mainContent px-0 me-0">

                            <UserSubmenuHolder userProfile={userProfile} user={user} setSelectedUser={setUserProfile}
                                               setUser={setUser} />
                            <Row className={'px-5 text-start mb-2 mx-0'}>
                                <Col>
                                    <Link className={'px-0 btn-link text-second'} onClick={() => navigate(-1)}>
                                        <TbArrowBackUp/>Indietro
                                    </Link>
                                </Col>
                            </Row>
                            <div className={'px-md-5 px-3 me-auto mw-100 mx-0 w-100'}>
                                <Outlet/>
                            </div>
                        </div>
                    </Container>
                ) : (
                    <Profiles profiles={user && user.profiles} onSelectProfile={handleProfileSelection}/>
                )
            ) : (
                <Login setUser={setUser}/>
            )}
        </>
    )
}
export default Layout