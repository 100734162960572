import {Alert, Card, Col, Row} from "react-bootstrap";
import './../../assets/css/Members/Members.css'
import React, {useContext, useEffect, useState} from "react";
import {AiOutlineUserAdd} from "react-icons/ai";
import Loading from "../../Components/Layout/Elements/Loading";
import MemberContact from "../../Components/Modals/Members/MemberContact";
import authContext from "../../Contexts/authContext";
import {Link} from "react-router-dom";
import {retrieveAssociationsList} from "../../Api/Admin/AdminFetch";
import AssociationsTable from "../../Components/Admin/AssociationsTable";


const AssociationsList = () => {
    const [associationsList, setAssociationsList] = useState([])
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/admin/associations?filter_type=0?')
    const {profile} = useContext(authContext)
    const [perPage, setPerPage] = useState('per_page=10');
    const [search, setSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState();
    const [show, setShow] = useState(false)
    const [memberContact, setMemberContact] = useState([])
    const [filterType, setFilterType] = useState('filter_type=0');
    const [message, setMessage] = useState()
    const [updateMembersList, setUpdateMembersList] = useState(false)
    const [updateRetrieve, setUpdateRetrieve] = useState(false)


    useEffect(() => {


        retrieveAssociationsList({
            setAssociationsList,
            setPagination,
            pageUrl,
            perPage,
            setIsLoading,
            setErrors,
            search,
            filterType
        })
        console.log(associationsList)

    }, [pageUrl, perPage, search, filterType, updateRetrieve, profile])


    return (

        <>
            {/*<Card className={'px-2 py-4 border-0 shadow mb-2'}>
                <MembersSearch setSearch={setSearch}/>
            </Card>*/}
            <Card className={'px-2 py-4 border-0 shadow'}>
                <Row>
                    <Col md={8}>
                        {/*<MembersFilters setFilterType={setFilterType}/>*/}
                    </Col>
                    <Col md={4} className={'text-end'}>
                        <Link to={'/admin/nuova-associazione'} className={'btn bkg-second text-white'}>
                            <AiOutlineUserAdd className={'fs-4'}/> Aggiungi associazione
                        </Link></Col>
                </Row>
                {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                    setMessage(null)
                }} dismissible> {message} </Alert>}
                {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                    setErrors(null)
                }} dismissible> {errors} </Alert>}

                <AssociationsTable associationsList={associationsList} pagination={pagination} setPageUrl={setPageUrl}
                              setPerPage={setPerPage} setShow={setShow} setMembersContact={setMemberContact}
                              profile={profile}
                              setMessage={setMessage} setErrors={setErrors}
                              setUpdateRetrieve={setUpdateRetrieve} setIsLoading={setIsLoading}/>
            </Card>
            {isLoading && <Loading/>}


            <MemberContact show={show} setShow={setShow} memberContact={memberContact}></MemberContact>

        </>
    )
}

export default AssociationsList