import {Outlet} from "react-router-dom";
import {useState} from "react";

const Admin = () => {
    const [associationDetail, setAssociationDetail]=useState(false)

    return (
        <Outlet context={[associationDetail, setAssociationDetail]} />
    )
}

export default Admin