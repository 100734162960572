import React, {useContext, useEffect, useState} from "react";
import AuthContext from "../../Contexts/authContext";
import {retrieveAssociationInfos} from "../../Api/Settings/SettingsFetch";
import {Col, Row} from "react-bootstrap";
import {retrieveDashboard} from "../../Api/Dashboard/DashboardFetch";
import NextPayments from "../../Components/Dashboard/NextPayments";
import LastPayments from "../../Components/Dashboard/LastPayments";
import {formatDataIt} from "../../Utils/DateFormat";

const Home = () => {
    const [associationInfo, setAssociationInfo] = useState()
    const [updatedAssociationInfo, setUpdatedAssociationInfo] = useState()
    const [nextPayments, setNextPayments] = useState([])
    const [lastPayments, setLastPayments] = useState([])
    const [dashboardDatas, setDashboardDatas] = useState()
    const [message, setMessage] = useState()
    const [errors, setErrors] = useState()
    const {profile, setUserProfile} = useContext(AuthContext)

    useEffect(() => {
        retrieveAssociationInfos({setAssociationInfo})

    }, [profile])

    useEffect(() => {
        retrieveDashboard({setDashboardDatas})
    }, [])

    useEffect(() => {
        if (dashboardDatas) {
            setNextPayments(dashboardDatas.next_payments)
            setLastPayments(dashboardDatas.last_payments)
            console.log('lastPayments', lastPayments)
        }
    }, [dashboardDatas])


    useEffect(() => {
        console.log('info', associationInfo)
        setAssociationInfo(associationInfo)
    }, [associationInfo, profile])
    const [image, setImage] = useState();

    useEffect(() => {
        setImage(associationInfo && associationInfo.qr_url)
    }, [associationInfo])

    return (

        <Row>

            <Col>
                <Row className={' justify-content-stretch align-items-stretch flex flex-row'}>
                    <Col md={12} lg={9} className={'justify-content-stretch align-items-stretch '}>
                        <Row className={'card bkg-primary text-white p-4 mb-4 '}>
                            <h2 className={'text-start'}>Benvenuto, {associationInfo?.name}</h2>

                        </Row>
                        <Row
                            className={'card border-0 shadow flex flex-row align-items-stretch justify-content-stretch align-items-center py-4 '}>
                            <Col md={8} className={''}>
                                <h2 className={'text-start'}>Il tuo video Tutorial</h2>
                                <video width="100%" height="auto" controls>
                                    <source src={process.env.REACT_APP_VIDEO_URL} type="video/mp4"/>
                                    Your browser does not support the video tag.
                                </video>


                            </Col>

                            <Col>
                                <h2 className={'text-start'}>Codici associazione</h2>
                                <Col className={`text-center border-1 'border-bottom' : '' pb-3 `}>

                                    <b>Codice Invito:</b>  <h5
                                    className={'text-white fs-2 bkg-second py-2'}>{associationInfo && associationInfo.invite_code}</h5>
                                </Col>
                                <Col className={'text-center mt-2 flex-1'}>
                                    <p><b>Qr Code</b></p>
                                    <img src={image} width={'200px'} className={'img-fluid '}/>
                                </Col>
                            </Col>

                        </Row>

                    </Col>
                    <Col className={'mx-2 mt-0 pt-0 card border-0 shadow  bkg-primary text-white'}>
                        <h2 className={'text-center mt-3'}>Il tuo piano</h2>
                        <h5
                            className={'text-white fs-2 bkg-second py-2 mt-4'}> {dashboardDatas && dashboardDatas?.subscription?.name} </h5>
                        <p className={'fw-bold'}>Data di
                            scadenza: {formatDataIt(new Date(dashboardDatas && dashboardDatas?.subscription?.expiry))} </p>

                        <h2 className={'text-center mt-3 border-top border-secondary pt-5'}>Cosa è incluso</h2>
                        <div className={'mx-auto mt-3'}>
                            <div className={'text-start gap-2'}>
                                {dashboardDatas && dashboardDatas?.subscription?.features && dashboardDatas?.subscription?.features.length > 0 && dashboardDatas?.subscription?.features.map(i => {
                                    return <p key={i.name}
                                              className={'mb-2 d-flex justify-content-between align-items-center'}>
                                        <span className={'fs-6 col-md-5'} style={{fontSize: '14px'}}>{i.description}</span>
                                        <span
                                            className={'text-second h2 flex-1'} style={{fontSize: '30px'}}>{i.limit}</span></p>
                                })}

                            </div>

                        </div>
                    </Col>
                </Row>

                <Row className="mb-5 justify-content-stretch align-items-stretch flex flex-row row">
                    <Col md={6} className="px-0 ms-0 d-flex   align-content-stretch">
                        <Row
                            className="mt-2 shadow w-100 card ms-sm-0 ms-md-0 mx-md-0 mx-2 m-auto  rounded-4 p-4">
                            <Col className="overflow-auto px-4" style={{maxHeight: '700px'}}>
                                <h2 className="text-start text-black mb-4" style={{color: 'black'}}>Prossimi pagamenti</h2>
                                <NextPayments nextPayments={nextPayments}/>
                            </Col>
                        </Row>

                    </Col>
                    <Col md={6} className="px-0 me-0 d-flex align-content-stretch">
                        <Row
                            className="mt-2 shadow text-white w-100 bkg-second card ms-md-2 ms-sm-0 mx-2 me-sm-0 me-md-2 rounded-4 p-4">
                            <Col className="overflow-auto w-100" style={{maxHeight: '700px'}}>
                                <h2 className="text-start mb-2 text-white">Ultimi incassi</h2>
                                <LastPayments lastPayments={lastPayments}/>
                            </Col>
                        </Row>
                    </Col>
                </Row>


                <Row>


                </Row>
            </Col>


        </Row>
    )
}
export default Home