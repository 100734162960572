import {Button, Col, Form, Modal} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {addContact, addPlaces} from "../../../Api/Settings/SettingsFetch";
import {addNewKit, addNewMembership} from "../../../Api/Members/MembersFetch";
import {emptyError, handleErrors} from "../../Errors";

const ModalAddNewKitOrMembership = ({
                                        show,
                                        handleClose,
                                        setIsLoading,
                                        setShow,

                                        setUpdate,
                                        update,

                                        modalType,
                                        id
                                    }) => {
    console.log('modaltype', modalType)
    const [errors, setErrors] = useState()
    useEffect(() => {

        if (errors !== undefined) {
            handleErrors(errors)
            console.log(errors)
        }
        console.log('errors', errors, 'general', errors)
    }, [errors])
    const handleAdd = (e) => {
        e.preventDefault();

        emptyError()
        const emptyfields = document.querySelector('span.error_empty_field')
        if (modalType === 'kit') {
            const formData = {};
            for (let i of document.querySelectorAll('#newEquipment input, #newEquipment select, #newEquipment textarea')) {
                const name = i.getAttribute('name');
                const value = i.value;
                formData[name] = value;
            }
            if (!emptyfields) {

                addNewKit({newEquipment: formData, id, setIsLoading, setShow, setErrors, setUpdate, update})
            }
        } else {
            const formData = {};
            for (let i of document.querySelectorAll('#newMembership input, #newMembership select')) {
                const name = i.getAttribute('name');
                const value = i.value;
                formData[name] = value;
            }

            if (!emptyfields) {
                addNewMembership({newMembership: formData, id, setIsLoading, setShow, setErrors, setUpdate, update})
            }
        }

    }

    return (
        <Modal show={show} onHide={handleClose} className={'modal-lg'} centered={true}>
            <Modal.Header closeButton>
                <h2>
                    Aggiungi nuovo {modalType === 'kit' ? 'Kit' : 'Tesseramento'} per l'associazione
                </h2>

            </Modal.Header>
            <Modal.Body>


                {modalType === 'tesseramento' ? <Form id={'newMembership'}
                                                      className={` mb-4 mx-0 align-items-center row`}>

                    <Col className={'text-start position-relative'} md={4}>

                        <Form.Label className={'text-start'}>Federazione </Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'federation'} required={true}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start position-relative'} md={4}>
                        <Form.Label className={'text-start'}>Numero tesseramento</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'membership_number'}
                                      required={true}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start position-relative'} md={4}>
                        <Form.Label className={'text-start'}>Data inizio</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'date'} name={'membership_start_date'}
                                      required={true}
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start position-relative'} md={4}>
                        <Form.Label className={'text-start'}>Data Fine</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'date'} name={'membership_end_date'}
                                      required={true}
                        ></Form.Control>
                    </Col>

                    <Col md={4} className={'d-flex justify-content-start'}>
                        <Button type={"submit"}
                                className={'mt-3 btn btn-sm me-2 d-inline-block bkg-second no-border border-0'}
                                onClick={handleAdd}>Aggiungi Tesseramento</Button>

                    </Col>

                </Form> : <Form id={'newEquipment'}
                                className={` mb-4 mx-0 align-items-center row`}>

                    <Col className={'text-start position-relative'} md={4}>

                        <Form.Label className={'text-start small mb-0'}>Descrizione kit </Form.Label>
                        <Form.Control className={'form-control-sm'} type={'text'} name={'equipment_description'}
                                      required
                        ></Form.Control>
                    </Col>
                    <Col className={'text-start position-relative small mb-0'} md={4}>
                        <Form.Label className={'text-start'}>Data consegna</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'date'} name={'equipment_delivery_date'}
                        ></Form.Control>
                    </Col>

                    <Col className={'text-start position-relative small mb-0'} md={4}>
                        <Form.Label className={'text-start'}>Data restituzione</Form.Label>
                        <Form.Control className={'form-control-sm'} type={'date'}
                                      name={'equipment_return_date'}
                        ></Form.Control>
                    </Col>
                    <Col md={4} className={'d-flex justify-content-start'}>
                        <Button type={"submit"}
                                className={'mt-3 btn btn-sm me-2 d-inline-block bkg-second no-border border-0'}
                                onClick={handleAdd}>Aggiungi Kit</Button>

                    </Col>

                </Form>}

            </Modal.Body>
        </Modal>)
}
export default ModalAddNewKitOrMembership