import React, {useEffect, useState} from "react";
import NewTrainingPlanForm from "../../Components/Teams/Trainings/NewTrainingPlanForm";
import {addTrainingPlans, retrieveWeekDays} from "../../Api/Teams/Trainings/TrainingsFetch";
import {useNavigate, useParams} from "react-router-dom";
import {Alert} from "react-bootstrap";
import Loading from "../../Components/Layout/Elements/Loading";

const NewTrainingPlan = (props) => {
    const [weekDays, setWeekDays]= useState([]);
    const [newTrainingPlan, setNewTrainingPlan] = useState()
    const [plan, setPlan] = useState([])
    const [errors, setErrors] = useState()
    const [generalError, setGeneralError] = useState()
    const [message, setMessage]= useState()
    const [isLoading, setIsLoading]= useState(false)
    const {id} = useParams();
    const navigate = useNavigate()
    console.log(errors)
    useEffect(() => {
        retrieveWeekDays({setWeekDays})
    }, [])

    useEffect(() => {
        console.log(newTrainingPlan)
        if (newTrainingPlan ) {
            console.log(newTrainingPlan)
              addTrainingPlans({id, newTrainingPlan, setMessage, setErrors,setGeneralError, navigate, setIsLoading})
        }

    }, [newTrainingPlan])
   return (
       <> {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
           setMessage(null)
       }} dismissible> {message} </Alert>}
           {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
           <NewTrainingPlanForm weekDays={weekDays} setNewTrainingPlan={setNewTrainingPlan} newTrainingPlan={newTrainingPlan} setPlan={setPlan} plan={plan} errors={errors} setErrors={setErrors}/>

           {isLoading && <Loading/>}
       </>

   )
}
export default NewTrainingPlan