import {Button, Col, Form, Row} from "react-bootstrap";
import React, {useEffect} from "react";
import {editAssociationsSettings} from "../../Api/Settings/SettingsFetch";


const AssociationSettingsForm = (props) => {

    const handleEdit = (e) => {
        e.preventDefault()


        for (let i of document.querySelectorAll('form input, form select')) {
            if (i.value != '') {
                const name = i.getAttribute('name')

                const value = i.value;
                console.log(i.value)
                const updatedValue = {[name]: value}
                props.setUpdatedAssociationInfo(info => ({
                        ...info, ...updatedValue
                    })
                )
            }
            console.log(props.updatedAssociationInfo)
        }

    }

    useEffect(() => {
        if (props.updatedAssociationInfo !== undefined) {
            editAssociationsSettings({
                updatedAssociationInfo: props.updatedAssociationInfo,
                setMessage: props.setMessage,
                setErrors: props.setErrors,
                setUpdatedAssociationInfo: props.setUpdatedAssociationInfo,
                setUserProfile: props.setUserProfile,
                update: props.update,
                setUpdate: props.setUpdate
            })
        }
    }, [props.updatedAssociationInfo])
    return (<>
        <Row className={'card py-3 border-0 shadow'}>
            <h2 className={'text-start'}>Cambia dati associazione</h2>
            <Form className={'row'}>

                <Col className={'text-start'} md={6}>
                    <Form.Label className={'text-start'}>Nome associazione</Form.Label>
                    <Form.Control type={'text'} name={'association_name'}
                                  defaultValue={props.associationInfo && props.associationInfo.name}></Form.Control> </Col>
                <Col className={'text-start'} md={6}>
                    <Form.Label className={'text-start'}>Presidente</Form.Label>
                    <Form.Control type={'text'} name={'president'}
                                  defaultValue={props.associationInfo && props.associationInfo.president}></Form.Control> </Col>
                <Col className={'text-start'} md={6}>
                    <Form.Label className={'text-start'}>Email</Form.Label>
                    <Form.Control type={'email'} name={'contact_email'}
                                  defaultValue={props.associationInfo && props.associationInfo.contact_email}></Form.Control> </Col>

                <Col className={'text-start'} md={6}>
                    <Form.Label className={'text-start'}>Telefono</Form.Label>
                    <Form.Control type={'text'} name={'contact_number'}
                                  defaultValue={props.associationInfo && props.associationInfo.contact_number}></Form.Control> </Col>
                <Col className={'text-start'} md={4}>
                    <Form.Label className={'text-start'}>Indirizzo</Form.Label>
                    <Form.Control type={'text'} name={'address_line1'}
                                  defaultValue={props.associationInfo && props.associationInfo.address_line1}></Form.Control> </Col>
                <Col className={'text-start'} md={4}>
                    <Form.Label className={'text-start'}>Città</Form.Label>
                    <Form.Control type={'text'} name={'city'}
                                  defaultValue={props.associationInfo && props.associationInfo.city}></Form.Control> </Col>
                <Col className={'text-start'} md={4}>
                    <Form.Label className={'text-start'}>Cap</Form.Label>
                    <Form.Control type={'text'} name={'postal_code'}
                                  defaultValue={props.associationInfo && props.associationInfo.postal_code}></Form.Control> </Col>
                <Col className={'text-start'} md={12}> <Form.Label className={'text-start'}>Descrizione</Form.Label>
                        <Form.Control type={'text'} as={'textarea'}  multiple={true} name={'description'}
                                      defaultValue={props.associationInfo && props.associationInfo.description}></Form.Control> </Col>

                <Col md={12}>
                    <Button type={"submit"} onClick={() => {

                    }}
                            className={'col-md-2 mt-3 btn btn-sm d-block bkg-second no-border border-0'}
                            onClick={handleEdit}>Modifica</Button>
                </Col>

            </Form>

        </Row>

    </>)
}

export default AssociationSettingsForm