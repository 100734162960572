import {Button, Col, Form, Modal} from "react-bootstrap";
import React, {useState} from "react";
import SeasonsSelect from "../../Payments/Elements/SeasonsSelect";

const ModalAddNewPayment = (props) => {
    const [selectedSeason, setSelectedSeason] = useState("");
    const handleClose = () => {
        props.setShow(false)
        props.setIsEditPayment(false)
        props.setPaymentDetail()
        props.setInstallmentId()
    }

    return ( <Modal show={props.show} onHide={handleClose} className={'modal-md'} centered={true}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
            <form id={'addPayment'}>
                <Form.Label className={'small mb-1'}>Causale</Form.Label>
                <Form.Control className={'mb-2 form-control-sm'}
                              name={'causal'} type={'text'} defaultValue={(props.isEditPayment && props.paymentDetail) ? props.paymentDetail?.causal :''}
                ></Form.Control>
                <Form.Label className={'small mb-1'}>Importo</Form.Label>
                <Form.Control className={'mb-2 form-control-sm'}
                              name={'amount'} type={'number'} defaultValue={props.isEditPayment && props.paymentDetail && props.paymentDetail?.amount}
                ></Form.Control>
                <Form.Label className={'small mb-1'}>Data</Form.Label>
                <Form.Control className={'mb-2 form-control-sm'}
                              name={'payment_date'} type={'date'} defaultValue={props.isEditPayment && props.paymentDetail && props.paymentDetail?.payment_date}
                ></Form.Control>
                <Form.Label className={'small mb-1'}>Metodo di pagamento</Form.Label>
                <Form.Control className={'mb-2 form-control-sm'}
                              name={'payment_method'} type={'text'} defaultValue={(props.isEditPayment && props.paymentDetail ) ?  props.paymentDetail?.payment_method : ''}
                ></Form.Control>
                <SeasonsSelect showAll={false} setSelectedSeason={setSelectedSeason} selectedSeason={selectedSeason} />
                <Form.Label className={'small mb-1'}>Note</Form.Label>
                <Form.Control className={'mb-2 form-control-sm'}
                              name={'notes'} type={'text'} as={'textarea'} defaultValue={(props.isEditPayment && props.paymentDetail) ? props.paymentDetail?.notes : ''}
                ></Form.Control>
                <Form.Label className={'small mb-1 me-4'}>Pagato</Form.Label>
                <Form.Check className={'mb-2 form-control-sm'}
                              name={'paid'} type={'radio'} label={'Si'} inline value={1}  defaultChecked={props.isEditPayment && props.paymentDetail && props.paymentDetail?.paid === 1}
                ></Form.Check>
                <Form.Check className={'mb-2 form-control-sm'}
                            name={'paid'} type={'radio'} label={'No'} inline value={0} defaultChecked={props.isEditPayment && props.paymentDetail && props.paymentDetail?.paid === 0}
                ></Form.Check>


            </form>
            <Col className={'text-end'}>
                <Button className={'btn btn-sm bkg-primary text-white border-0 mt-2'} onClick={props.action}>{props.isEditPayment ? 'Modifica' : 'Aggiungi'}</Button>
            </Col>

        </Modal.Body>
    </Modal>)
}

export default ModalAddNewPayment