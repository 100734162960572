import {Button, Card, Col, Form, Row} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {CiCircleRemove} from "react-icons/ci";
import {it} from "date-fns/locale/it";
import DatePicker from "react-datepicker";
import {emptyError, handleErrors} from "../../Errors";
import {TiPlus} from "react-icons/ti";

const NewTrainingPlanForm = (props) => {

    const [startDate, setStartDate] = useState(new Date());
    const [endDate, setEndDate] = useState(new Date());
    const [planError, setPlanError]= useState()

    useEffect(() => {

        if (props.errors) {

            handleErrors(props.errors)
        }
    }, [props.errors])
    const handleAddNewGame = (e) => {
        e.preventDefault()
        emptyError();
        const emptyfields = document.querySelector('span.error_empty_field')
        const inputs = document.querySelectorAll('.trainingPlan input, .trainingPlan select, .trainingPlan textarea');
        const newDataObject = {};
        inputs.forEach(input => {
            const value = input.value;
            const name = input.name;

            // Only add to the object if both value and name are present
            if (value && name) {
                newDataObject[name] = value;
            }
        });
        newDataObject.plan = props.plan
        // Update the state with the new object
        if (!emptyfields) {
            props.setNewTrainingPlan(newDataObject);
        }
        console.log(props.newTrainingPlan)

    };
    const handleAddWeekPlan = (e) => {
        const values = document.querySelectorAll('.trainings_weekly input, .trainings_weekly select');
        let newTrainingObject = {}; // Oggetto esterno per ogni iterazione

        for (let i of values) {
            if (i.value.trim() !== '') {
                setPlanError()
                newTrainingObject[i.getAttribute('name')] = i.value; // Aggiungi coppie name: value all'oggetto esterno
                i.value = '';
            } else {
                i.value = ''; // Svuota l'input se il valore è vuoto o composto solo da spazi
            }
        }

        // Controlla se tutti i campi sono stati compilati
        const allFieldsCompleted = Object.keys(newTrainingObject).length === values.length;

        // Se tutti i campi sono stati compilati, aggiungi l'oggetto esterno all'array prevState
        if (allFieldsCompleted) {
            props.setPlan(prevState => {
                return [...prevState, newTrainingObject]; // Aggiungi l'oggetto esterno all'array prevState
            });
        } else {
            setPlanError("Per favore, compila tutti i campi.");
        }

        console.log(props.plan); // Controlla il nuovo stato dopo l'aggiunta
    };

    const handleRemovePlan = (indexToRemove) => {
        props.setPlan(prevPlan => {
            const filteredPlan = prevPlan.filter((_, index) => index !== indexToRemove);
            return filteredPlan
        })

    }
    return (
        <>
            <Form className={''}>
                <Card className={'p-3 border-0 shadow mb-5'}>
                    <Row className={'text-start align-items-end trainingPlan'}>
                        <h5 className={'fw-bold'}>Informazioni Piano allenamento </h5>

                        <Col md={4} className={'position-relative errorField_up'}>
                            <Form.Label className={'small mb-1'}>Data inizio stagione</Form.Label>
                            <Form.Control
                                className={'mb-2'} name={'start_date'} type={'date'}
                                placeholder={'Location'} required={true}></Form.Control>

                        </Col>
                        <Col md={4} className={'position-relative errorField_up'}>
                            <Form.Label className={'small mb-1'}>Data fine stagione</Form.Label>
                            <Form.Control
                                className={'mb-2'} name={'end_date'} type={'date'}
                                placeholder={'Location'} required={true}></Form.Control>
                        </Col>
                        <Col md={4} className={'position-relative errorField_up'}>
                            <Form.Label className={'small mb-1'}>Location</Form.Label>
                            <Form.Control
                                className={'mb-2'} name={'location'} type={'text'}
                                placeholder={'Location'} required={true}></Form.Control>
                        </Col>
                        <Col md={12}>
                            <Form.Label className={'small mb-1'}>Descrizione piano allenamento</Form.Label>
                            <Form.Control className={'mb-2'} name={'description'} as="textarea"
                                          placeholder={'Descrizione'}></Form.Control>
                        </Col>
                    </Row>
                    <Row className={'text-start align-items-end mt-2 trainings_weekly'}>

                        <Col md={12}><h5 className={'fw-bold'}>Imposta piano allenamenti</h5></Col>
                        <Col md={3}>
                            <Form.Label className={'small mb-1'}>Giorno </Form.Label>
                            <Form.Select className={'form-select form-select-sm'} name={"day"}>
                                <option value={''}>Seleziona</option>
                                {props.weekDays && props.weekDays.map(day => {
                                    return <option key={day.id} value={day.id}>{day.value}</option>
                                })}
                            </Form.Select>
                        </Col>
                        <Col md={3}>
                            <Form.Label className={'small mb-1'}>Ora inizio</Form.Label>

                            <DatePicker
                                wrapperClassName={'w-100'}
                                className={'form-control-sm form-control d-block datePicker'}
                                selected={startDate}
                                onChange={(date) => setStartDate(date)}
                                showTimeSelect
                                locale={it}
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="HH:mm"
                                timeCaption="Orario"
                                name={'start_time'}
                            />
                        </Col>
                        <Col md={3}>
                            <Form.Label className={'small mb-1'}>Ora fine</Form.Label>
                            <DatePicker
                                wrapperClassName={'w-100'}
                                className={'form-control-sm form-control d-block datePicker'}
                                selected={endDate}
                                onChange={(date) => setEndDate(date)}
                                showTimeSelect
                                locale={it}
                                showTimeSelectOnly
                                timeIntervals={15}
                                dateFormat="HH:mm"
                                timeCaption="Orario"
                                name={'end_time'}
                                data-error={'end_time'}
                            />


                        </Col>
                        <Col md={3} className={'text-end align-self-end d-flex'}><Link
                            className={'btn btn-link '} onClick={handleAddWeekPlan}><TiPlus className={'fs-5 me-2 '}/>Aggiungi
                        </Link></Col>

                        {planError && <p className={'text-danger'}>{planError}</p>}
                    </Row>
                    {props.plan && props.plan.length > 0 && props.plan.map((i, index) => {
                        return (
                            <Row key={index} className={'text-start my-2 pb-2 border-bottom position-relative border-1'}>

                                <Col>{props.weekDays && props.weekDays.map(day => {
                                    return day.id == i.day && day.value
                                })}</Col>
                                <Col className={'position-relative errorField_up'} ><span className={'pb-1'}>{i.start_time}</span> <div data-error={'plan.'+index+'.start_time'}></div></Col>
                                <Col className={'position-relative errorField_up'} ><span className={'pb-1'}>{i.end_time}</span> <div  data-error={'plan.'+index+'.end_time'} ></div></Col>
                                <Col  className={'text-end'}><CiCircleRemove className={'fs-4'} onClick={() => {
                                    handleRemovePlan(index)
                                }}/></Col>
                            </Row>
                        )
                    })}
                    <Button className={`bkg-second border-0 mt-4 `} disabled={props.plan.length === 0 && 'disabled'}
                            onClick={props.gameDetail ? '' : handleAddNewGame}>{props.gameDetail ? 'Modifica gara' : 'Crea piano di allenamenti'}</Button>
                </Card>


            </Form>
        </>
    )
}

export default NewTrainingPlanForm