import React, {useEffect, useState} from "react";
import {retrievePaymentsAssociationOverview, retrievePaymentsOverview,} from "../../Api/Payments/PaymentsFetch";

import Loading from "../../Components/Layout/Elements/Loading";
import {Alert, Card, Col, Row} from "react-bootstrap";

import ReportsOverviewTable from "../../Components/Payments/Reports/ReportsOverviewTable";
import OverviewButtons from "../../Components/Payments/Elements/OverviewButtons";
import SeasonsSelect from "../../Components/Payments/Elements/SeasonsSelect";

const PaymentsOverview = () => {
    const [perPage, setPerPage] = useState('per_page=15');
    const [pagination, setPagination] = useState({});
    const [pageUrl, setPageUrl] = useState('/payments/report/overview')

    const [search, setSearch] = useState({})
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState();
    const [show, setShow] = useState(false)
    const [filterType, setFilterType] = useState('');
    const [update, setUpdate] = useState(false)
    const [message, setMessage] = useState()
    const [paymentsList, setPaymentsList] = useState()
    const [overviewAssociation, setOverviewAssocation] = useState([])
    const [paymentPlanName, setPaymentPlanName] = useState([])
    const [filterSort, setFilterSort] = useState('')
    const [selectedSeason, setSelectedSeason] = useState()
    useEffect(() => {
        console.log('ok')
        retrievePaymentsOverview({setIsLoading, setErrors, setPerPage, perPage, setPagination, setPageUrl, pageUrl, setPaymentsList, filterSort, selectedSeason})
    }, [perPage, pageUrl, filterSort, selectedSeason])

   useEffect(() => {
       console.log('PaymentsList', paymentsList)
   }, [paymentsList])
    useEffect(() => {
        console.log('pagination', pagination)
    }, [pagination])

 useEffect(() => {
     retrievePaymentsAssociationOverview({setIsLoading, setErrors, setOverviewAssocation})
 }, [])
    useEffect(() => {
        console.log('OverviewAssociation', overviewAssociation)
    }, [overviewAssociation])
    return (
        <>
            <>
                {overviewAssociation && (
                   <OverviewButtons overview={overviewAssociation} />
                )}

                <Card className={'px-2 py-4 border-0 shadow'}>

                    {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                        setMessage(null)
                    }} dismissible> {message} </Alert>}
                    {errors && <Alert variant={'danger'} className={'mt-2'} onClick={() => {
                        setErrors(null)
                    }} dismissible> {errors} </Alert>}
                   <Row>
                       <Col className={'text-start'} md={3}>
                           <SeasonsSelect selectedSeason={selectedSeason} setSelectedSeason={setSelectedSeason} showAll={true}/>
                       </Col>
                   </Row>
                    <ReportsOverviewTable payments={paymentsList} pagination={pagination} setPageUrl={setPageUrl}
                                  setPerPage={setPerPage} setShow={setShow}
                                  setErrors={setErrors}
                                  errors={errors} setFilterSort={setFilterSort}/>

                </Card>
                {isLoading && <Loading/>}




            </>



            {isLoading && <Loading/>}
        </>
    )
}

export default PaymentsOverview