import {Row} from "react-bootstrap";
import PieChart from "../../Charts/PieChart";
import BarsChart from "../../Charts/BarsChart";

const ChallengesArchive = (props) => {

    return (
        <>

            <h5
                className={'fw-bold pt-3'}>{props.name}</h5>

            <Row className={'mx-0 justify-content-center'}>
                {props.dataConv && props.chartType === 'pie'  ? <PieChart data={props.dataConv}/> : props.dataConv && props.chartType === 'bar' ? '' : 'Nessun dato'}
                {props.dataConv && props.chartType === 'bar' ? <BarsChart data={props.dataConv}/> : props.dataConv && props.chartType === 'pie'  ? '' : 'Nessun dato'}


            </Row>


        </>
    )
}

export default ChallengesArchive