import {Alert, Button, Col, Offcanvas, Row, Tab, Tabs} from "react-bootstrap";
import React, {useState} from "react";
import InstallmentTable from "./Installments/InstallmentTable";
import PlanMembers from "./PlanMembers";

const PlanDetail = ({
                        show,
                        setShow,
                        selectedPayment,
                        message,
                        errors,
                        setIsLoading,
                        setErrors,
                        setMessage,
                        setShowInstallmentModal,
                        paymentDetail,
                        setShowConfirm,
                        setSelectedPlan,
                        setSelectedInstallment,
    update, setUpdate
                    }) => {
    const [planMembers, setPlanMembers] = useState([])

    return (

        <Offcanvas show={show} className={'w-50 z-2'} onHide={() => {
            setShow(false)
            setPlanMembers()
        }} placement={'end'} backdrop={true} scroll={true}>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Imposta pagamenti per il piano <b>{selectedPayment?.name}</b></Offcanvas.Title>
            </Offcanvas.Header>
            <Row>
                <Col className={'mx-3'}>
                    {message && <Alert variant={'success'} className={'mt-2'} onClick={() => {
                        setMessage(null)
                    }} dismissible> {message} </Alert>}
                    {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {
                        setErrors(null)
                    }} dismissible> {errors} </Alert>}
                </Col>
            </Row>
            <Offcanvas.Body>

                <Row className={'text-end'}>
                    <Col>
                        <Button
                            className={'btn btn-sm bkg-primary text-white border-0 me-2'} onClick={() => {
                            setShowInstallmentModal(true)
                        }}>
                            Aggiungi rata
                        </Button>
                    </Col>
                </Row>
                <Row>
                    <Col md={12}>
                        <Tabs
                            defaultActiveKey="lista"
                            id="uncontrolled-tab-example"
                            className="mb-3"
                        >
                            <Tab eventKey="lista" title="Lista">
                                <InstallmentTable setSelectedInstallment={setSelectedInstallment}
                                                  setSelectedPlan={setSelectedPlan} paymentDetail={paymentDetail}
                                                  setShowConfirm={setShowConfirm}/>

                            </Tab>

                            <Tab eventKey="soci" title="Associa soci">
                                <PlanMembers id={selectedPayment?.id} planMembers={planMembers} setPlanMembers={setPlanMembers} paymentDetail={paymentDetail} setErrors={setErrors} setMessage={setMessage} setIsLoading={setIsLoading} update={update} setUpdate={setUpdate}/>
                            </Tab>
                        </Tabs>


                    </Col>
                </Row>

            </Offcanvas.Body>
        </Offcanvas>
    )
}
export default PlanDetail