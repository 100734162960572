import MyEditor from "../../Components/Communications/MyEditor";
import {Alert, Button, Card, Col, Form} from "react-bootstrap";
import '../../assets/css/Communications/Communications.css'
import React, {useContext, useEffect, useState} from "react";
import {
    addCommunicationFetch,
    retrieveMembersNames,
    retrieveTeamsNames
} from "../../Api/Communications/CommunicationsFetch";
import Loading from "../../Components/Layout/Elements/Loading";
import CommunicationSendOptions from "../../Components/Communications/CommunicationSendOptions";
import {useNavigate, useParams} from "react-router-dom";
import authContext from "../../Contexts/authContext";
import useDoubleClickPrevention from "../../Hooks/useDoubleClickPrevention";
import {emptyError, handleErrors} from "../../Components/Errors";

const NewCommunication = () => {
    const [editorHtml, setEditorHtml] = useState('');
    const [teamsList, setTeamsList] = useState();
    const [selectedSender, setSelectedSender] = useState()
    const [membersList, setMembersList] = useState();
    const [pageUrl, setPageUrl] = useState('/team?')
    const {profile} = useContext(authContext);
    const [isLoading, setIsLoading] = useState(false)
    const [errors, setErrors] = useState()
    const [message, setMessage] = useState()
    const [membersOptions, setMembersOptions] = useState([])
    const [teamOptions, setTeamOptions] = useState([])
    const [selectedMemberOption, setSelectedMemberOption] = useState([]);
    const [selectedTeamOption, setSelectedTeamOption] = useState();
    const [addCommunication, setAddCommunication] = useState()
    const {id} = useParams()
    const navigate = useNavigate()
    const [selectedFile, setSelectedFile] = useState(null);
    const [isButtonDisabled, preventDoubleClick] = useDoubleClickPrevention();
    const [generalError, setGeneralError] = useState()
    const handleFileChange = (event) => {
        setSelectedFile(event.target.files);
        console.log(event.target.files)
        console.log(selectedFile)
    };
    useEffect(() => {
        console.log(selectedFile)
    }, [selectedFile])

    useEffect(() => {
        retrieveTeamsNames({
            setTeamsList,
            pageUrl,
            setIsLoading,
            setErrors,
            setTeamOptions
        })
        retrieveMembersNames({
            setMembersList,
            pageUrl,
            setIsLoading,
            setErrors,
            setMembersOptions
        })


    }, [pageUrl, profile])

    useEffect(() => {
        const formData = new FormData();
        if (selectedFile && selectedFile.length > 0) {

            const filesArray = [];

            for (let i = 0; i < selectedFile.length; i++) {
                filesArray.push(selectedFile[i].name); // Aggiungi solo il nome del file all'array
                formData.append('file[]', selectedFile[i]); // Aggiungi il file al formData
            }

            // Stampa l'array dei nomi dei file
            console.log(filesArray);

        }

        if (addCommunication) {
            preventDoubleClick()
            addCommunicationFetch({addCommunication, formData, setErrors, setMessage, navigate, setIsLoading, setGeneralError})
        }
    }, [addCommunication])

    console.log(membersOptions)
    useEffect(() => {
        console.log("id:", id);
        console.log("membersOptions:", membersOptions);

        if (id && membersOptions) {
            const selectedMember = membersOptions.find(member => member.value == id);
            console.log("selectedMember:", selectedMember); // Add this line
            if (selectedMember) {
                setSelectedMemberOption([{
                    value: selectedMember.value,
                    label: selectedMember.label
                }]);
                setSelectedSender({send_option: 'Profile'});
            }
        }
    }, [id, membersOptions]);

    const handleSend = (e) => {
        e.preventDefault();
        emptyError();
        const emptyfields = document.querySelector('span.error_empty_field')
        let title = document.querySelector('input[name=title]').value
        if (!emptyfields) {
            setAddCommunication(prevState => {
                return {
                    ...prevState,
                    ...selectedSender,
                    ['profile_ids']: selectedMemberOption.map(i => i.value), ...selectedTeamOption,
                    ['content']: editorHtml,
                    ['title']: title
                }

            })
        }
    }
    useEffect(() => {
        console.log(errors)
        if (errors) {
            handleErrors(errors)
        }
        console.log(errors, generalError)
    }, [errors])

    return (
        <>
            {message && <Alert variant={'success'} onClick={() => {setMessage(null) }} dismissible> {message} </Alert>}
            {errors && typeof errors === 'string' && <Alert variant={'danger'} onClick={() => {setErrors(null) }} dismissible> {errors} </Alert>}
            {generalError && typeof generalError === 'string' && <Alert variant={'danger'} onClick={() => {setGeneralError(null) }} dismissible> {generalError} </Alert>}
            <Form className={'row'}>
                <Col md={8}>
                    <Card className={'p-3 border-0 shadow h-100'}>
                        <h3 className={'py-3 fw-bold'}>Nuova comunicazione</h3>
                       <Form.Group className={'position-relative'}>
                           <Form.Control type={'text'} placeholder={'Titolo comunicazione'} name={'title'}
                                         className={'mb-3'} required={'required'}></Form.Control>
                       </Form.Group>
                      <Form.Group className={'position-relative'}>
                          <Form.Control type={'hidden'} name={'content'}></Form.Control>
                          <MyEditor setEditorHtml={setEditorHtml} editorHtml={editorHtml}/>

                      </Form.Group>
                        <Form.Group controlId="formFile" className="mb-3 mt-3">
                            <Form.Label>Seleziona un file</Form.Label>
                            <Form.Control type="file" multiple={true} onChange={handleFileChange}/>
                        </Form.Group>
                        <Button onClick={handleSend} disabled={isButtonDisabled} className={'mt-3'}>Invia</Button>
                    </Card>
                </Col>

                <Col md={4} className={'align-content-start d-inline-flex'}>
                    <CommunicationSendOptions membersOptions={membersOptions}
                                              selectedMembersOption={selectedMemberOption} teamOptions={teamOptions}
                                              selectedTeamOptions={selectedTeamOption}
                                              setSelectedTeamOption={setSelectedTeamOption} teamsList={teamsList}
                                              membersList={membersList}
                                              setSelectedMemberOption={setSelectedMemberOption}
                                              setSelectedSender={setSelectedSender} id={id}/>
                </Col>
            </Form>

            {isLoading && <Loading/>}

        </>

    )
}

export default NewCommunication