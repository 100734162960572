import {Button, Col, Form, Row} from "react-bootstrap";
import React from "react";
import memberDetail from "../../Pages/Members/MemberDetail";

const MemeberAddForm = (props) => {
    const isEdit = props.isEdit
    const editMembers = props.editMember
    const handleAddNewMember = (e) => {
        e.preventDefault();
        const formData = {};
        for (let i of document.querySelectorAll('#newMemberAdd input, #newMemberAdd select')) {
            const name = i.getAttribute('name');
            const value = i.value;
            formData[name] = value;
        }
        if (!isEdit ) {
            props.setNewMember(formData);
        } else {
            props.setEditMember(formData)
        }
        // props.setNewMember()
    }

    return (
        <Form id={'newMemberAdd'}>
            <Row className={'text-start align-items-end'}>

            </Row>
            <Row className={'text-start align-items-end'}>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Nome</Form.Label>
                    <Form.Control type={'text'} name={"first_name"} placeholder={'nome'} className={'mb-2'} required={true} defaultValue={props.memberDetail && props.memberDetail.first_name}></Form.Control>
                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Cognome</Form.Label>
                    <Form.Control type={'text'} name={"last_name"} placeholder={'cognome'} className={'mb-2'} required={true} defaultValue={props.memberDetail && props.memberDetail.last_name}></Form.Control>
                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small mb-0'}>Email</Form.Label>
                    <Form.Control type={'email'} name={"email"} placeholder={'email'} className={'mb-2'} required={true} defaultValue={props.memberDetail && props.memberDetail.email}></Form.Control>

                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Telefono</Form.Label>
                    <Form.Control type={'number'} name={"telephone_number"} placeholder={'email'} className={'mb-2'} defaultValue={props.memberDetail && props.memberDetail.telephone_number}></Form.Control>

                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Data di nascita</Form.Label>
                    <Form.Control type={'date'} name={"birthday"} placeholder={'data di nascita'} className={'mb-2'} defaultValue={props.memberDetail && props.memberDetail.birthday}></Form.Control>

                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Indirizzo</Form.Label>
                    <Form.Control type={'text'} name={"address_line1"} placeholder={'Indirizzo'} className={'mb-2'} defaultValue={props.memberDetail && props.memberDetail.address_line1}></Form.Control>

                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Città</Form.Label>
                    <Form.Control type={'text'} name={"city"} placeholder={'città'} className={'mb-2'} defaultValue={props.memberDetail && props.memberDetail.city}></Form.Control>

                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label className={'small'}>Stato</Form.Label>
                    <Form.Control type={'text'} name={"state"} placeholder={'Stato'} className={'mb-2'} defaultValue={props.memberDetail && props.memberDetail.state}></Form.Control>

                </Col>
                <Col md={4} className={'position-relative'}>
                    <Form.Label > Cap</Form.Label>
                    <Form.Control type={'text'} name={"postal_code"} placeholder={'Cap'} className={'mb-2'} defaultValue={props.memberDetail && props.memberDetail.postal_code}></Form.Control>

                </Col>
            </Row>

            <Button className={'bkg-second border-0 mt-4'} onClick={handleAddNewMember}>
                {!props.memberDetail ? 'Crea utente' : 'Modifica utente'}
            </Button>
        </Form>

    )
}
export default MemeberAddForm