import {RiDeleteBinLine} from "react-icons/ri";
import {Table} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {getAssociationPlaces} from "../../Api/Settings/SettingsFetch";

const PlacesTable = (props) => {
    const [associationPlaces, setAssociationPlaces] = useState()
    useEffect(() => {
        getAssociationPlaces({
            setAssociationPlaces,
            setMessage: props.setMessage,
            setErrors: props.setErrors,

            setIsLoading: props.setIsLoading
        })


    }, [props.update])



    return (
    <Table>
        <thead>
        <tr>
            <th>
                Tipo
            </th>
            <th>
                Nome
            </th>
            <th>
                Indirizzo
            </th>
            <td>Descrizione</td>
            <th></th>
        </tr>
        </thead>
        <tbody>
        {associationPlaces && associationPlaces.length > 0 ? associationPlaces.map(i => {
            return <tr key={i.id}
                       className={`border-bottom border-black pb-4 mb-4 mx-0 align-items-center justify-content-between ${'row-' + i.id}`}>
                <td className={'text-start'} md={4}>
                                                <span
                                                    className={'badge bkg-second text-white'}>{i.place_type_name}</span>


                </td>
                <td className={'text-start'} md={4}>

                                    <span className={''}>
                                      {i.name}
                                   </span>
                </td>
                <td className={'text-start'} md={4}>
                    {i.address_line1} {i.city} {i.postal_code}

                </td>
                <td>{i.description}</td>
                <td md={4} className={'d-flex justify-content-end'}>
                    {/* <Button type={"submit"}
                                        className={'mt-3 btn btn-sm me-2 d-inline-block bkg-second no-border border-0'}
                                        onClick={handleEdit} data-id={i.id}>Modifica</Button>*/}
                    <RiDeleteBinLine size={20} className={'text-danger'}
                                     onClick={(e) => {
                                         e.preventDefault();
                                         props.setShowConfirm(true)
                                         props.setPlaceId(i.id)
                                     }}/>


                </td>

            </tr>
        }) : <h3>
            Nessun contatto per l'associazione
        </h3>
        }
        </tbody>
    </Table>

    )
}
export default PlacesTable