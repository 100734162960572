import {Col, Row} from "react-bootstrap";
import React from "react";
import TeamCard from "./TeamCard";
import TeamMembers from "./TeamMembers";
import Challenges from "./Challenges";
import ChallengesArchive from "./ChallengesArchive";
import {generateRandomColors} from "../../../Utils/GenerateRandomColors";
import GamesTable from "./GamesTable";
import {Link} from "react-router-dom";
import Trainings from "./Trainings";
import TrainingPlans from "../Trainings/TrainingPlans";
import './../../../assets/css/TeamTable.css'
import TeamStaff from "./TeamStaff";


const TeamDetailsInfos = (props) => {
    const gamesStats = {
        labels: ['Giocate', 'Totali'],
        datasets: [
            {
                label: '',
                data: [props.teamDetail && props.teamDetail.stats.games_played_this_year, props.teamDetail && props.teamDetail.stats.total_games],
                backgroundColor: generateRandomColors(2),
                borderWidth: 0
            },
        ],
    };

    const trainingStats = {
        labels: ['Allenamenti ', 'Partecipazione'],
        datasets: [
            {
                label: '',
                data: [props.teamDetail && props.teamDetail.stats.games_played_this_year, props.teamDetail && props.teamDetail.stats.total_games],
                backgroundColor: generateRandomColors(2),
                borderWidth: 0
            },
        ],
    };

    return (
        <>
            <Row className={'mx-0 '}>
                <Col md={12}>
                    <Row className={'d-flex align-items-stretch '}>
                        <Col className={'card border-0 shadow py-3 mb-3'} md={props.width}>
                            <TeamCard description={props.teamDetail && props.teamDetail.description}
                                      coach={props.teamDetail && props.teamDetail.coach_full_name}
                                      color={props.teamDetail && props.teamDetail.color}
                                      name={props.teamDetail && props.teamDetail.name} dataConv={gamesStats} width={7}
                                      id={props.id}
                                      setIsLoading={props.setIsLoading} setMessage={props.setMessage}
                                      setErrors={props.setErrors}
                            />
                        </Col>

                        <Col md={4} className={'justify-content-stretch d-flex '}>

                            <Challenges name={'Ultima gara giocata'}
                                        teamDetail={props.teamDetail && props.teamDetail}/></Col>

                    </Row>

                </Col>
                <Col className={'col-md-4 mb-4 card shadow border-0 py-3 mx-0 mt-2'}>
                    <Row className={'d-flex flex-row'}>
                        <Col className={'col-md-12'}>
                            <TeamStaff teamDetail={props.teamDetail} setIsLoading={props.setIsLoading} id={props.id}  setTeamDetail={props.setTeamDetail} update={props.update} setUpdate={props.setUpdate}/>
                        </Col>
                    </Row>
                </Col>
                <Col md={8} className={'px-1 mb-4 ps-0 ps-md-4 '}>
                    <Row className={'card flex-row shadow border-0 py-3 mx-0 mt-2'}>
                        <TeamMembers teamDetail={props.teamDetail} setIsLoading={props.setIsLoading} id={props.id}
                                     setTeamDetail={props.setTeamDetail}/>
                    </Row>

                </Col>
                <Col md={12} className={'ps-0'}>
                    <Row className={'mx-0'}>
                        <Col md={12} className={'card border-0 shadow py-3 mb-3 col'}>

                            <Row className={'d-flex align-items-stretch '}>
                                <Col><h5 className={'fw-bold text-start mb-0'}>Prossime gare</h5></Col>
                                <Col className={'text-end'}>
                                    <Link to={'/teams/team/' + props.id + '/nuova-partita'}
                                          className={'bkg-second text-white btn btn-sm mx-0 border-0'}>Nuova gara</Link></Col>
                            </Row>

                            <GamesTable setIsLoading={props.setIsLoading} setErrors={props.setErrors}
                                        setMessage={props.setMessage} id={props.id} setTeamDetail={props.setTeamDetail}
                                        stats={props.stats ? props.stats.upcoming_games : ''}/>
                            <Row className={'justify-content-between align-items-center'}>
                                <Col><h5 className={'fw-bold text-start mb-0'}>Ultime gare</h5></Col>

                            </Row>
                            <GamesTable setIsLoading={props.setIsLoading} setErrors={props.setErrors}
                                        setMessage={props.setMessage} id={props.id} setTeamDetail={props.setTeamDetail}
                                        stats={props.stats ? props.stats.latest_played_games : ''} isLastGame={true}/>
                        </Col>


                    </Row>
                </Col>

                <Col md={12} className={'ps-0 col-md-12'}> <Row className={'mx-0'}>
                    <Col md={12} className={'card border-0 shadow py-3 mb-3 col'}>
                        <h5 className={'text-start fw-bold'}>Calendario allenamenti</h5>

                        <Trainings teamDetails={props.teamDetail && props.teamDetail} setIsLoading={props.setIsLoading}
                                   setErrors={props.setErrors} id={props.id} setTeamDetail={props.setTeamDetail}/>
                    </Col>
                </Row>
                </Col>

                <Row className={'mx-0'}>
                    <Col className={'card border-0 text-center shadow pb-2 py-2 mb-3 mx-0 mt-3'} md={12}>
                        <h5>Piani allenamento</h5>
                        <Col md={12} className={'my-2 text-center text-sm-end'}>

                            <Link className={'btn bkg-second text-white'}
                                  to={'/teams/team/' + props.id + '/nuovo-piano-allenamento'}> Nuovo
                                piano allenamento</Link>


                        </Col>

                        <TrainingPlans id={props.id} setIsLoading={props.setIsLoading} setErrors={props.setErrors}
                                       setMessage={props.setMessage}/>
                    </Col>

                </Row>

                {/*<Col className={'card border-0 text-center shadow pb-2 py-2 mb-3 mx-0'} md={4}>
                    <Row>
                        <Col md={12}>
                            <Tabs
                                defaultActiveKey="gare"
                                id="uncontrolled-tab-example"
                                className="mb-3"
                            >
                                <Tab eventKey="gare" title="Gare">
                                    <ChallengesArchive chartType={'pie'} dataConv={gamesStats} name={'Gare'}
                                                       data={'20/01/2024'}/>
                                </Tab>

                                <Tab eventKey="allenamenti" title="Allenamenti">
                                    <ChallengesArchive chartType={'pie'} dataConv={trainingStats} name={'Allenamenti'}
                                                       data={'20/01/2024'}/>
                                </Tab>
                            </Tabs>


                        </Col>
                    </Row>
                </Col>*/}
                <Row className={'mx-0'}>
                    <Col className={'card border-0 text-center shadow pb-2 py-2 mb-3 mx-0'} md={12}>
                        <Row>

                            <Col md={4} className={'offset-md-2'}> <ChallengesArchive chartType={'pie'}
                                                                                      dataConv={gamesStats}
                                                                                      name={'Gare'}
                                                                                      data={'20/01/2024'}/>
                            </Col>
                            <Col md={4}>
                                <ChallengesArchive chartType={'pie'} dataConv={trainingStats} name={'Allenamenti'}
                                                   data={'20/01/2024'}/>
                            </Col>


                        </Row>
                    </Col>


                </Row>

                {/* <Col md={8}>

                    <TeamCard data={data_2} name={'Team3'} dataConv={dataConv}/>
                    <TeamCard data={data} name={'Team6'} dataConv={dataConv}/>

                </Col>*/}
            </Row>

        </>
    )

}

export default TeamDetailsInfos