import axiosInstance from "../AxiosInstance";

export const retrieveAssociationInfos = (props) => {

    let url = '/settings'


    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        props.setAssociationInfo(response.data.data)
        console.log('Association Infos', response)

        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const retrievePlacesType = (props) => {

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get('/settings/places/types').then((response) => {
        // Handle successful response (status code 200)
        console.log('PlacesTypes', response)
        props.setPlacesTypes(response.data.data)
        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const retrieveContactsType = (props) => {

    props.setIsLoading &&  props.setIsLoading(true)
    axiosInstance.get('/settings/contacts/types').then((response) => {
        // Handle successful response (status code 200)
        console.log('ContactsType', response)
        props.setContactsTypes(response.data.data)
        props.setIsLoading &&  props.setIsLoading(false)
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            // props.setErrors && props.setErrors(error)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const editAssociationsSettings = (props) => {
    let url = '/settings'
    axiosInstance.post(url, props.updatedAssociationInfo).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Modifica Impostazioni', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setUpdatedAssociationInfo()
        const test= JSON.parse(localStorage.getItem('selectedProfile'))
        console.log(test)

        test.association_name = props.updatedAssociationInfo.association_name
        localStorage.setItem('selectedProfile', JSON.stringify(test))
        props.setUserProfile(JSON.parse(localStorage.getItem('selectedProfile')))
        props.setUpdate(!props.update)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const getAssociationPlaces = (props) => {
    let url = '/settings/places'
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Association places', response.data)
        props.setIsLoading && props.setIsLoading(false)

        props.setAssociationPlaces(response.data.data)

    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const getAssociationContacts = (props) => {
    let url = '/settings/contacts'
    axiosInstance.get(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Association contacts', response.data)
        props.setIsLoading && props.setIsLoading(false)

        props.setAssociationContacts(response.data.data)

        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const editPlaces = (props) => {
    let url = '/settings/places/'+props.placeId
    axiosInstance.post(url, props.updatedPlaces).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Modifica Places', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setUpdatedPlaces()
        props.setUpdate(!props.update)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}
export const addPlaces = (props) => {
    let url = '/settings/place'
    axiosInstance.post(url, props.newPlace).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Add Places', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setShow && props.setShow(false)
        props.setUpdate(!props.update)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deletePlace = (props) => {
    let url = '/settings/place/'+props.placeId
    axiosInstance.delete(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Elimina Places', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setShowConfirm(false)
        props.setUpdate(!props.update)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const addContact = (props) => {
    let url = '/settings/contact'
    axiosInstance.post(url, props.newContact).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Add Contact', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setShow && props.setShow(false)
        props.setUpdate(!props.update)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const deleteContact = (props) => {
    let url = '/settings/contact/'+props.contactId
    axiosInstance.delete(url).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Elimina Contacts', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setShowConfirm(false)
        props.setUpdate(!props.update)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}

export const changeSeason = (props) => {
    let url = 'settings/season/'
    axiosInstance.post(url, {
        season_id: props.seasonId
    }).then((response) => {
        // Handle successful response (status code 200)
        console.log('Risposta Cambia stagione', response.data)
        props.setIsLoading && props.setIsLoading(false)
        props.setMessage && props.setMessage(response.data.message)
        props.setShowConfirm(false)
        /*setIsLoading(false)*/
    }).catch((error) => {
        console.log(error)
        props.setIsLoading && props.setIsLoading(false)
        if (error.response && error.response.status === 422) {
            // Handle the 422 response (validation error)
            props.setErrors && props.setErrors(error.response.data.message)
            props.setIsLoading && props.setIsLoading(false)
            console.log('Validation Errors:', error.response.data.message);
        } else {
            // Handle other errors (e.g., network issues)
            props.setErrors && props.setErrors(error.message)
            props.setIsLoading && props.setIsLoading(false)
            console.error('Error:', error);
        }
    })
}